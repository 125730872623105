import { BaseResource } from './BaseResource';

export interface Locale extends BaseResource {
    code: string;
    readonly name?: string | null;
}

export enum Locales {
    nl = 'nl_NL',
    en = 'en_US'
}
