import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TopNavbarInterface } from '../../../entities/@blocks/TopNavbar/TopNavbar';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type TopNavbarState = AsyncReduxState<{
    topNavbarItems?: TopNavbarInterface;
}>;

const initialState: TopNavbarState = {
    ...initialAsyncReduxState,
    topNavbarItems: undefined,
};

const topNavbarSlice = createSlice({
    name: 'topNavbarSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): TopNavbarState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): TopNavbarState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): TopNavbarState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): TopNavbarState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setTopNavbarItems(state, action: PayloadAction<TopNavbarInterface| undefined>): TopNavbarState {
            return {
                ...state,
                topNavbarItems: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setTopNavbarItems,
} = topNavbarSlice.actions;

export default topNavbarSlice;
