import { FC, ReactElement, useEffect } from 'react';

import { AccountData } from '../../containers';
import { CustomerAddress } from '../../entities/Address/Address';
import { Customer } from '../../entities/Customer/Customer';
import { areObjectsEqual } from '../../helpers/object';
import {
    addAddress,
    fetchCustomer,
    updateAddress,
    updateCustomer,
    updateDefaultAddress,
} from '../../redux/customer/customerActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

export const ConnectedAccountData: FC = (): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const customer = useTypedSelector(state => state.customerSlice.customer);

    useEffect((): void => {
        dispatch(fetchCustomer());
    }, []);

    if (!customer) {
        return null;
    }

    const handleUpdateCustomer = (newCustomerData: Customer): void => {
        const newCustomer = {
            ...customer,
            ...newCustomerData,
        };

        dispatch(updateCustomer(newCustomer));
    };

    const handleUpdateAddresses = (newAddress: CustomerAddress): void => {
        if (!newAddress.id) {
            dispatch(addAddress(newAddress));
            return;
        }

        const matchingAddress = customer.addresses?.find(address => address.id === newAddress.id);

        if (matchingAddress) {
            const addressHasActualChanges = !areObjectsEqual(matchingAddress, newAddress);

            if (addressHasActualChanges) {
                dispatch(updateAddress(newAddress));
            }
        }
    };

    const setDefaultAddress = (id: string): void => {
        dispatch(updateDefaultAddress(id));
    };

    return (
        <AccountData
            customer={customer}
            onUpdateCustomer={handleUpdateCustomer}
            onUpdateAddresses={handleUpdateAddresses}
            onChangeShippingAddress={setDefaultAddress}
        />
    );
};
