import { FC, ReactElement } from 'react';

import { Picture } from '../../../../components';
import { VideoTheatre } from '../../../../compositions';
import { MediaItem } from '../../../../entities/Media/Media';
import { useDeviceWidth } from '../../../../hooks';
import { ProductMediaSlider } from '../ProductMediaSlider/ProductMediaSlider';

import './ProductMediaPresentation.scss';

interface ProductMediaPresentationProps {
    mediaItems: MediaItem[];
    className?: string;
}

export const ProductMediaPresentation: FC<ProductMediaPresentationProps> = ({
    mediaItems,
    className = '',
}): ReactElement => {
    const { isMobile } = useDeviceWidth();

    return (
        <section className={`product-media-presentation ${className}`}>
            {isMobile && (
                <ProductMediaSlider
                    mediaItems={mediaItems}
                    className="product-media-presentation__slider"
                />
            )}

            {!isMobile && (
                <ul className="product-media-presentation__grid">
                    {mediaItems.map(mediaItem => (
                        <li key={mediaItem.id} className="product-media-presentation__item">
                            {mediaItem.image && !mediaItem.video && (
                                <Picture
                                    {...mediaItem.image}
                                    className="product-media-presentation__image"
                                />
                            )}

                            {mediaItem.video && (
                                <VideoTheatre
                                    {...mediaItem.video}
                                    playsInline={mediaItem.videoPlaysInline}
                                    poster={mediaItem.image}
                                    className="product-media-presentation__video"
                                />
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </section>
    );
};
