import { FC, ReactElement } from 'react';

import { Product } from '../../entities/Product/Product';
import { ProductDetailSkeletons } from './skeletons';
import { ProductMediaPresentation, ProductSidebar } from './subcomponents';

import './ProductDetail.scss';

interface ProductDetailProps {
    isLoading: boolean;
    product?: Product;
    className?: string;
}

export const ProductDetail: FC<ProductDetailProps> = ({
    isLoading,
    product,
    className = '',
}): ReactElement => {
    if (isLoading || !product) {
        return (
            <ProductDetailSkeletons />
        );
    }

    return (
        <section className={`product-detail ${className}`}>
            <div className="product-detail__main">
                <ProductMediaPresentation
                    mediaItems={product.mediaItems}
                    className="product-detail__media-presentation"
                />
            </div>

            <ProductSidebar
                product={product}
                className="product-detail__sidebar"
            />
        </section>
    );
};
