import { FC, ReactElement, useRef } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Wrapper } from '../../../../components';
import { Navigation } from '../../../../entities/Navigation/Navigation';
import { useElementSize, useTrans } from '../../../../hooks';
import { useDocumentCssVariable } from '../../../../hooks/useDocumentCssVariable';
import { NavLinkClassName } from '../../../../types';

import './DesktopSubNavigation.scss';

interface DesktopSubNavigationProps {
    subNavigation: Navigation;
    className?: string;
}

export const DesktopSubNavigation: FC<DesktopSubNavigationProps> = ({
    subNavigation,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const subNavigationRef = useRef<HTMLDivElement>(null);

    const { height } = useElementSize(subNavigationRef);

    useDocumentCssVariable('--submenu-height', `${height}px`);

    return (
        <nav
            ref={subNavigationRef}
            aria-label={trans('containers.menu.subNavigation')}
            className={`desktop-sub-navigation ${className}`}
        >
            <Wrapper className="desktop-sub-navigation__wrapper">
                <ul className="desktop-sub-navigation__group-list">
                    {subNavigation.groups.map(group => (
                        <li key={group.id}>
                            <ul className="desktop-sub-navigation__item-list">
                                {group.items.map(item => {
                                    const navLinkClassNames: NavLinkClassName = ({ isActive }): string => classNames('desktop-sub-navigation__link', {
                                        'desktop-sub-navigation__link--is-active': isActive,
                                    });

                                    return (
                                        <li key={item.id} className="desktop-sub-navigation__item">
                                            <NavLink end to={item.to} className={navLinkClassNames}>
                                                {item.label}
                                            </NavLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    ))}
                </ul>
            </Wrapper>
        </nav>
    );
};
