import { UrgentBannerResponse } from '../../../entities/@blocks/UrgentBanner/UrgentBanner';
import { generateUrgentBannerQuery } from '../../../entities/@blocks/UrgentBanner/UrgentBannerRequest';
import { transformToUrgentBanner } from '../../../entities/@blocks/UrgentBanner/UrgentBannerTransformers';
import { isFetchResultSuccessful } from '../../../entities/FetchResult/FetchResult';
import { strapiFetch } from '../../../entities/Strapi/StrapiService';
import { TypedDispatch } from '../../store';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setUrgentBanner,
} from './urgentBannerSlice';

export const fetchUrgentBanner = () => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setUrgentBanner(undefined));
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const queryString = generateUrgentBannerQuery();

        const urgentBannerResponse = await strapiFetch<UrgentBannerResponse>(
            `/urgent-banner?${queryString}`,
        );

        if (!isFetchResultSuccessful(urgentBannerResponse)) {
            dispatch(setError(urgentBannerResponse.error));
            return;
        }

        const { data } = urgentBannerResponse.data;

        const urgentBanner = data
            ? transformToUrgentBanner(data)
            : undefined;

        dispatch(setUrgentBanner(urgentBanner));
    } catch (error) {
        console.error('[fetchUrgentBanner]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
