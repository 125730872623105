import {
    CSSProperties,
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    useRef,
} from 'react';

import classNames from 'classnames';

import { Icon, IconName } from '../../../components';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { useElementSize } from '../../../hooks';
import { Button, ButtonProps } from '../..';

import './IconButton.scss';

export interface IconButtonProps extends ButtonProps {
    icon: IconName;
    iconPos?: HorizontalAlignment;
    hideLabel?: boolean;
    labelClassName?: string;
    iconClassName?: string;
    iconPathClassName?: string;
}

export const IconButton: ForwardRefExoticComponent<IconButtonProps> = forwardRef(({
    hasAnimation,
    icon,
    iconPos = HorizontalAlignment.left,
    text,
    hideLabel,
    className = '',
    labelClassName = '',
    iconClassName = '',
    iconPathClassName = '',
    ...iconButtonProps
}, ref: Ref<HTMLButtonElement>): ReactElement => {
    const iconRef = useRef<SVGSVGElement>(null);
    const { width: iconWidth } = useElementSize(iconRef);

    const buttonClassNames = classNames('icon-button', {
        'icon-button--has-reveal-animation': hasAnimation && !hideLabel,
        'icon-button--has-push-animation': hasAnimation && hideLabel,
        [`icon-button--align-${iconPos}`]: iconPos,
    }, className);

    const cssVariables = {
        '--icon-button-icon-width': `${iconWidth}px`,
    } as CSSProperties;

    return (
        <Button
            {...iconButtonProps}
            ref={ref}
            text={text}
            style={cssVariables}
            className={buttonClassNames}
            labelClassName={`icon-button__label ${labelClassName}`}
        >
            <div className="icon-button__icon-wrapper">
                <Icon
                    ref={iconRef}
                    name={icon}
                    className={`icon-button__icon ${iconClassName}`}
                    pathClassName={`icon-button__icon-path ${iconPathClassName}`}
                />

                {hasAnimation && hideLabel && (
                    <Icon
                        name={icon}
                        className={`icon-button__icon icon-button__icon-copy ${iconClassName}`}
                    />
                )}
            </div>

            {!hideLabel && text}
        </Button>
    );
});
