import { FC, ReactElement } from 'react';

import { ProductDetailHelmet } from '../../../containers';
import { useTypedSelector } from '../../../redux/store';

export const ConnectedProductDetailHelmet: FC = (): ReactElement | null => {
    const product = useTypedSelector(state => state.productSlice.product);

    if (!product) {
        return null;
    }

    return (
        <ProductDetailHelmet product={product} />
    );
};
