import { RegistrationFormData } from '../../entities/@forms/RegistrationForm/RegistrationForm';
import { AddressType, CustomerAddress } from '../../entities/Address/Address';
import { TokenResource } from '../../entities/Api/Webshop';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { syliusFetch } from '../../entities/Sylius/SyliusService';
import trans from '../../helpers/trans';
import { authenticateUser } from '../authentication/authenticationActions';
import { addAddress, fetchCustomer } from '../customer/customerActions';
import { ReduxFetchAction } from '../defaults';
import { addPositiveToast } from '../toast/toastActions';
import { setError, setIsLoading, setIsRegistered } from './registrationSlice';

export const registerUser: ReduxFetchAction<RegistrationFormData> = formData => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsRegistered(false));

    try {
        const registerResponse = await syliusFetch<TokenResource>('/shop/customers', {
            method: 'POST',
            body: JSON.stringify(formData),
        });

        if (!isFetchResultSuccessful(registerResponse)) {
            dispatch(setError(registerResponse.error));
            return;
        }

        await dispatch(authenticateUser({
            username: formData.email,
            password: formData.password,
            rememberMe: true,
        }));

        await dispatch(fetchCustomer());

        const address: CustomerAddress = {
            id: '',
            type: AddressType.shipping,
            street: formData.street,
            postalCode: formData.postcode,
            houseNumber: formData.number,
            addition: formData.addition,
            city: formData.city,
            country: formData.countryCode,
        };

        await dispatch(addAddress(address));

        dispatch(addPositiveToast({
            title: trans('redux.registration.successMessageTitle'),
            description: trans('redux.registration.successMessageDescription'),
        }));

        dispatch(setIsRegistered(true));
    } catch (error) {
        console.error('[registerUser]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
