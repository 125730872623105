import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { OpenStreetMapResource } from '../../entities/OpenStreetMap/OpenStreetMap';
import { getOpenStreetMapApiCall } from '../../entities/OpenStreetMap/OpenStreetMapService';
import { transformOpenStreetMapFeatureToSearchableOption } from '../../entities/OpenStreetMap/OpenStreetMapTransformers';
import { StoreResponse } from '../../entities/Store/Store';
import { generateStoreQuery, generateStoresQuery } from '../../entities/Store/StoreRequest';
import { transformToStore } from '../../entities/Store/StoreTransformers';
import { strapiFetch } from '../../entities/Strapi/StrapiService';
import { retrieveUniqueValues } from '../../helpers/array';
import { ReduxFetchAction } from '../defaults';
import { TypedDispatch } from '../store';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setSearchResults,
    setStore,
    setStores,
} from './storeSlice';

export const getStoreLocationSearchResults = (query: string, limit: number = 5) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));

    try {
        const openStreetMapResponse = await getOpenStreetMapApiCall<OpenStreetMapResource>(query, limit);

        if (!isFetchResultSuccessful(openStreetMapResponse)) {
            dispatch(setError(openStreetMapResponse.error));
            return;
        }

        const { features } = openStreetMapResponse.data;

        const results = features.length > 0
            ? features.map(transformOpenStreetMapFeatureToSearchableOption)
            : [];

        const uniqueResults = retrieveUniqueValues(results);

        dispatch(setSearchResults(uniqueResults));
    } catch (error) {
        console.error('[getStoreLocationSearchResults]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const fetchStores: ReduxFetchAction = () => async dispatch => {
    dispatch(setStores([]));
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const queryString = generateStoresQuery();
        const storesResponse = await strapiFetch<StoreResponse>(`/shops?${queryString}`);

        if (!isFetchResultSuccessful(storesResponse)) {
            dispatch(setError(storesResponse.error));
            return;
        }

        const { data } = storesResponse.data;

        const stores = data
            ? data.map(transformToStore)
            : [];

        dispatch(setStores(stores));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchStores]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};

export const fetchStoreDetail: ReduxFetchAction<string> = slug => async dispatch => {
    dispatch(setStore(undefined));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const queryString = generateStoreQuery(slug);
        const storeResponse = await strapiFetch<StoreResponse>(`/shops?${queryString}`);

        if (!isFetchResultSuccessful(storeResponse)) {
            dispatch(setError(storeResponse.error));
            return;
        }

        const { data: storesData } = storeResponse.data;

        const storeResource = storesData
            ? storesData[0]
            : undefined;

        const store = storeResource
            ? transformToStore(storeResource)
            : undefined;

        dispatch(setStore(store));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchStoreDetail]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
