import { FC, ReactElement, useState } from 'react';

import { useDebounce } from 'react-use';

import { NewsletterCallToAction } from '../../../containers';
import { NewsletterCallToActionInterface } from '../../../entities/@blocks/NewsletterCallToAction/NewsletterCallToAction';
import { NewsletterFormData } from '../../../entities/@forms/NewsletterForm/NewsletterForm';
import { signUpForNewsletter } from '../../../redux/@forms/newsletterForm/newsletterFormActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';
import { getStoreLocationSearchResults } from '../../../redux/store/storeActions';

export interface ConnectedNewsletterCallToActionProps extends NewsletterCallToActionInterface {
    className?: string;
}

export const ConnectedNewsletterCallToAction: FC<ConnectedNewsletterCallToActionProps> = ({
    className = '',
    ...newsletterCallToActionProps
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const storeLocations = useTypedSelector(state => state.storeSlice.storeLocations);
    const searchResults = useTypedSelector(state => state.storeSlice.searchResults);

    const isLoadingForm = useTypedSelector(state => state.newsletterFormSlice.isLoading);
    const formError = useTypedSelector(state => state.newsletterFormSlice.error);

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [hasSelectedResult, setHasSelectedResult] = useState<boolean>(false);

    useDebounce((): void => {
        if (searchQuery && !hasSelectedResult) {
            dispatch(getStoreLocationSearchResults(searchQuery));
        }

        setHasSelectedResult(false);
    }, 250, [searchQuery]);

    const handleResultClick = (): void => {
        setHasSelectedResult(true);
    };

    const handleFormSubmit = (formData: NewsletterFormData): void => {
        dispatch(signUpForNewsletter(formData));
    };

    return (
        <NewsletterCallToAction
            {...newsletterCallToActionProps}
            isLoadingForm={isLoadingForm}
            searchQuery={searchQuery}
            storeLocations={storeLocations}
            searchResults={searchResults}
            formError={formError}
            onSearchChange={setSearchQuery}
            onResultClick={handleResultClick}
            onFormSubmit={handleFormSubmit}
            className={className}
        />
    );
};
