import { ParsedQs } from 'qs';

import { Block, BlockResource } from '../Block/Block';
import { DateResource } from '../Date/Date';
import { StrapiPaginationMetaResource } from '../Pagination/Pagination';
import { AppRoute } from '../Routing/Routing';
import { Seo, SeoResource } from '../Seo/Seo';
import { StrapiEntity, StrapiEntityData, StrapiMeta } from '../Strapi/Strapi';

export enum PageType {
    checkout = 'Checkout',
    companyInspiration = 'Inspiratie',
    companyJoinUs = 'Deelnemen bij Loods 5',
    companyMedia = 'Media & PR',
    companyStory = 'Ons verhaal',
    companySustainability = 'Duurzaamheid',
    customerService = 'Contact',
    customerServiceAccount = 'Account',
    customerServiceDelivery = 'Bezorging levertijden',
    customerServiceFaq = 'Veelgestelde vragen',
    customerServiceOrdering = 'Bestellingen betalen',
    customerServiceReturns = 'Ruilen retouren',
    customerServiceTerms = 'Winkelvoorwaarden',
    home = 'Home',
    kitchens = 'Keukens',
    legalCookies = 'Cookiebeleid',
    legalDisclaimer = 'Disclaimer',
    legalPrivacy = 'Privacy',
    newsletter = 'Nieuwsbrief',
    ownCollection = 'Eigen collectie',
    sale = 'Sale',
    storesOverview = 'Winkels',
    storesDepartments = 'Afdelingen',
    storesRestaurant = 'Restaurant',
}

export const appRoutePageTypes: Partial<Record<AppRoute, PageType>> = {
    [AppRoute.blogOverview]: PageType.companyInspiration,
    [AppRoute.checkout]: PageType.checkout,
    [AppRoute.companyJoinUs]: PageType.companyJoinUs,
    [AppRoute.companyMedia]: PageType.companyMedia,
    [AppRoute.companyStory]: PageType.companyStory,
    [AppRoute.companySustainability]: PageType.companySustainability,
    [AppRoute.customerService]: PageType.customerService,
    [AppRoute.customerServiceAccount]: PageType.customerServiceAccount,
    [AppRoute.customerServiceDelivery]: PageType.customerServiceDelivery,
    [AppRoute.customerServiceFaq]: PageType.customerServiceFaq,
    [AppRoute.customerServiceOrdering]: PageType.customerServiceOrdering,
    [AppRoute.customerServiceReturns]: PageType.customerServiceReturns,
    [AppRoute.customerServiceTerms]: PageType.customerServiceTerms,
    [AppRoute.home]: PageType.home,
    [AppRoute.legalCookies]: PageType.legalCookies,
    [AppRoute.legalDisclaimer]: PageType.legalDisclaimer,
    [AppRoute.legalPrivacy]: PageType.legalPrivacy,
    [AppRoute.newsletter]: PageType.newsletter,
    [AppRoute.ownCollection]: PageType.ownCollection,
    [AppRoute.sale]: PageType.sale,
    [AppRoute.storesDepartments]: PageType.storesDepartments,
    [AppRoute.storesOverview]: PageType.storesOverview,
    [AppRoute.storesRestaurant]: PageType.storesRestaurant,
};

export interface PageResource extends StrapiEntityData {
    title: string;
    slug: string;
    description: string;
    elements: BlockResource[];
    seo?: SeoResource;
    createdAt: DateResource;
    publishedAt: DateResource;
    updatedAt: DateResource;
}

export type PageResponse = StrapiEntity<PageResource[]> & StrapiMeta<StrapiPaginationMetaResource>;

export interface PageInterface {
    id: string;
    title: string;
    description: string;
    blocks: Block[];
    seo?: Seo;
}

export interface PageConfig {
    appRoute: AppRoute,
    queryParams?: ParsedQs;
}

export interface CustomPageConfig {
    slug: string;
    queryParams?: ParsedQs;
}

export interface PageDraftConfig {
    status?: string;
    previewKey?: string;
}
