import { FC, ReactElement, useMemo } from 'react';

import { RangeRenderState } from 'instantsearch.js/es/connectors/range/connectRange';
import { SortByItem } from 'instantsearch.js/es/connectors/sort-by/connectSortBy';

import { Wrapper } from '../../components';
import { Button } from '../../compositions';
import { ConnectedProductCard } from '../../connectors';
import { resultsPerPage } from '../../entities/Meilisearch/Meilisearch';
import { Product } from '../../entities/Product/Product';
import { ProductFilter } from '../../entities/ProductFilters/ProductFilters';
import { ProductListCallToActionInterface } from '../../entities/ProductListCallToAction/ProductListCallToAction';
import { ProductSlider } from '../../entities/SliderSection/SliderSection';
import { zipArray } from '../../helpers/array';
import { useTrans } from '../../hooks';
import { InstantSearchPagination } from '../@instantsearch/InstantSearchPagination/InstantSearchPagination';
import { InstantSearchProductFilterBar } from '../@instantsearch/InstantSearchProductFilterBar/InstantSearchProductFilterBar';
import { ProductCardListSkeletons } from '../ProductCard/skeletons';
import { SliderSection } from '../SliderSection/SliderSection';
import { ProductListCallToAction } from './subcomponents';

import './ProductList.scss';

interface ProductListProps {
    isLoading?: boolean;
    showFilterBar?: boolean;
    showProductsCountForSearchTerm?: boolean;
    title?: string;
    query?: string;
    resultsCount: number;
    sortItems: SortByItem[];
    pages: number;
    currentPage: number;
    priceRangeConnector: RangeRenderState;
    filters: ProductFilter[];
    noProductsAvailableSliders: ProductSlider[];
    products: Product[];
    callToActions: ProductListCallToActionInterface[];
    onChangeSorting: (option: string) => void;
    onChangePage: (page: number) => void;
    onResetFilters: () => void;
    className?: string;
}

export const ProductList: FC<ProductListProps> = ({
    isLoading,
    showFilterBar,
    showProductsCountForSearchTerm,
    title,
    resultsCount,
    sortItems,
    pages,
    currentPage,
    priceRangeConnector,
    filters,
    noProductsAvailableSliders,
    products,
    callToActions,
    onChangeSorting,
    onChangePage,
    onResetFilters,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const productListContent = useMemo((): (Product[] | ProductListCallToActionInterface)[] => {
        const breakpoints = [0, 10, 18];
        const productLists = breakpoints.map((breakpoint, index) => {
            const nextBreakpoint = breakpoints[index + 1] || resultsPerPage;

            return [...products].slice(breakpoint, nextBreakpoint);
        });

        const filteredCallToActions = callToActions.filter((cta, index) => {
            const nextBreakpoint = breakpoints[index + 1] || resultsPerPage;

            return productLists[index].length === nextBreakpoint - breakpoints[index];
        });

        return zipArray<Product[], ProductListCallToActionInterface>(productLists, filteredCallToActions);
    }, [products, callToActions]);

    return (
        <div className={`product-list ${className}`}>
            <Wrapper className="product-list__wrapper">
                {title && (
                    <h2 className="product-list__heading">
                        {title}
                    </h2>
                )}

                {showFilterBar && resultsCount !== 0 && (
                    <InstantSearchProductFilterBar
                        resultsCount={resultsCount}
                        sortItems={sortItems}
                        filters={filters}
                        priceRangeConnector={priceRangeConnector}
                        onChangeSorting={onChangeSorting}
                    />
                )}

                {isLoading && (
                    <ProductCardListSkeletons numberOfSkeletons={20} />
                )}

                <div className="product-list__grid">
                    {!isLoading && productListContent.map(item => {
                        if (!Array.isArray(item)) {
                            return (
                                <ProductListCallToAction
                                    {...item}
                                    key={item.id}
                                    className="product-list__call-to-action"
                                />
                            );
                        }

                        return item.map(product => (
                            <ConnectedProductCard
                                key={product.id}
                                product={product}
                                className="product-list__product-card"
                            />
                        ));
                    })}
                </div>

                {!isLoading && products.length === 0 && (
                    <div className="product-list__no-results-wrapper">
                        <p className="product-list__no-results">
                            {trans('containers.productList.noProductsFound')}
                        </p>

                        <Button
                            hasAnimation
                            text={trans('containers.productList.resetFilters')}
                            onClick={onResetFilters}
                        />
                    </div>
                )}
            </Wrapper>

            {showProductsCountForSearchTerm && resultsCount === 0 && (
                noProductsAvailableSliders.map(productSlider => (
                    <SliderSection
                        {...productSlider}
                        key={productSlider.id}
                        items={productSlider.products.map(product => (
                            <ConnectedProductCard
                                key={product.id}
                                product={product}
                            />
                        ))}
                        className="product-list__slider"
                    />
                ))
            )}

            {pages > 1 && (
                <InstantSearchPagination
                    totalPages={pages}
                    currentPage={currentPage}
                    onChange={onChangePage}
                    className="product-list__pagination"
                />
            )}
        </div>
    );
};
