import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Picture } from '../../components';
import { BlogPost } from '../../entities/@blog/BlogPost/BlogPost';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { WayfindingType } from '../../entities/Wayfinding/Wayfinding';
import { trimToMaxLength } from '../../helpers/string';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import { useFormatDate, useTrans } from '../../hooks';
import { LinkIconButton, WayfindingWrapper } from '..';

import './BlogPostCard.scss';

export interface BlogPostCardProps {
    blogPost: BlogPost;
    className?: string;
}

export const BlogPostCard: FC<BlogPostCardProps> = ({
    blogPost,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const formatDate = useFormatDate();

    const formattedPublishedDate = formatDate(blogPost.publishDate, {
        day: '2-digit',
        month: 'short',
        year: '2-digit',
    });

    const blogPostPath = trans(appRoutes[AppRoute.blogPost].path);
    const blogPostUrl = replaceUrlParamKeysWithValues(blogPostPath, {
        category: blogPost.category.slug,
        slug: blogPost.slug,
    });

    const truncatedIntro = trimToMaxLength(blogPost.intro);

    return (
        <div className={`blog-post-card ${className}`}>
            <WayfindingWrapper
                id={`${blogPost.id}-card`}
                type={WayfindingType.enter}
                className="blog-post-card__wayfinding-wrapper"
                iconPathClassName="blog-post-card__wayfinding-icon-path"
            >
                <Link to={blogPostUrl} tabIndex={-1}>
                    <div className="blog-post-card__image-wrapper">
                        <Picture
                            {...blogPost.image}
                            className="blog-post-card__picture"
                            imageClassName="blog-post-card__image"
                        />
                    </div>
                </Link>
            </WayfindingWrapper>

            <div className="blog-post-card__text-wrapper">
                <p className="blog-post-card__details-wrapper">
                    <span className="blog-post-card__category">
                        {blogPost.category ? (
                            blogPost.category.title
                        ) : (
                            trans('common.defaultBlogCategory')
                        )}
                    </span>

                    <span className="blog-post-card__published-date">
                        {formattedPublishedDate}
                    </span>
                </p>

                <h3 className="blog-post-card__title">
                    {blogPost.title}
                </h3>

                <p className="blog-post-card__intro">
                    {truncatedIntro}
                </p>

                <LinkIconButton
                    hasAnimation
                    to={blogPostUrl}
                    iconPos={HorizontalAlignment.right}
                    icon="arrow-right"
                    text={trans('compositions.blogPostCard.readMore')}
                    className="blog-post-card__link-button"
                    labelClassName="blog-post-card__link-label"
                    iconClassName="blog-post-card__link-icon"
                />
            </div>
        </div>
    );
};
