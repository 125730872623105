import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import {
    ConnectedProductBreadcrumbs,
    ConnectedProductDetail,
    ConnectedProductDetailHelmet,
    ConnectedProductDetailWrapper,
    ConnectedProductInformation,
} from '../../connectors';

import './ProductDetailPage.scss';

export const ProductDetailPage: FC = (): ReactElement => (
    <ConnectedProductDetailWrapper>
        <Page className="product-detail-page">
            <ConnectedProductDetailHelmet />

            <Wrapper>
                <ConnectedProductBreadcrumbs />
            </Wrapper>

            <Wrapper className="product-detail-page__detail-wrapper">
                <ConnectedProductDetail className="product-detail-page__block" />
            </Wrapper>

            <Wrapper>
                <ConnectedProductInformation className="product-detail-page__block" />
            </Wrapper>
        </Page>
    </ConnectedProductDetailWrapper>
);
