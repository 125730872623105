import {
    FC,
    FormEvent,
    ReactElement,
    useState,
} from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { ErrorLabel } from '../../../../components';
import { Button, RecaptchaDisclaimer, TextInput } from '../../../../compositions';
import { useTrans } from '../../../../hooks';

import './Giftcard.scss';

interface GiftcardProps {
    className?: string;
}

export const Giftcard: FC<GiftcardProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const [cardNumber, setCardNumber] = useState<string>('');
    const [balance, setBalance] = useState<number | null>();
    const [botDetected, setBotDetected] = useState<boolean>(false);

    const handleOnChange = (number: string) => {
        if (number.length >= 20) {
            setCardNumber(number);
        }
    };

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        console.log('handleSubmit', cardNumber);

        if (!executeRecaptcha) {
            setBotDetected(true);
            return;
        }
        // TODO: Make GET request to check the status of the giftcard

        const token = await executeRecaptcha();
        setBotDetected(!token);

        // TODO: Removes this when GET request is implemented
        setTimeout(() => {
            setBalance(37.88);
        }, 500);
    };

    // TODO: Combine the 5 TextInput fields into one "value element"

    return (
        <div className={`giftcard ${className}`}>
            <div className="giftcard__header">
                <h6 className="giftcard__heading">
                    Loods 5
                    {' '}
                    {trans('containers.accountDashboardConfig.giftcard.giftcard')}
                </h6>
            </div>

            <form className="giftcard__form">
                <legend className="giftcard__form-legend">
                    {trans('containers.accountDashboardConfig.giftcard.cardNumber')}
                </legend>

                <div className="giftcard__inputs-wrapper">
                    <TextInput
                        label={trans('containers.accountDashboardConfig.giftcard.cardNumber')}
                        hideLabel
                        placeholder="0000"
                        onChange={handleOnChange}
                        className="giftcard__card-number-input"
                    />

                    <TextInput
                        label={trans('containers.accountDashboardConfig.giftcard.cardNumber')}
                        hideLabel
                        placeholder="0000"
                        onChange={handleOnChange}
                        className="giftcard__card-number-input"
                    />

                    <TextInput
                        label={trans('containers.accountDashboardConfig.giftcard.cardNumber')}
                        hideLabel
                        placeholder="0000"
                        onChange={handleOnChange}
                        className="giftcard__card-number-input"
                    />

                    <TextInput
                        label={trans('containers.accountDashboardConfig.giftcard.cardNumber')}
                        hideLabel
                        placeholder="0000"
                        onChange={handleOnChange}
                        className="giftcard__card-number-input"
                    />

                    <TextInput
                        label={trans('containers.accountDashboardConfig.giftcard.cardNumber')}
                        hideLabel
                        placeholder="0000"
                        onChange={handleOnChange}
                        className="giftcard__card-number-input"
                    />
                </div>

                <TextInput
                    label={trans('containers.accountDashboardConfig.giftcard.scratchCode')}
                    maxLength={4}
                    placeholder={trans('containers.accountDashboardConfig.giftcard.scratchCodePlaceholder')}
                    onChange={handleOnChange}
                    className="giftcard__scratch-code-input"
                />

                {balance && (
                    <p className="giftcard__giftcard-balance">
                        {trans('containers.accountDashboardConfig.giftcard.giftcardBalanceMessage', { balance })}
                    </p>
                )}

                <RecaptchaDisclaimer />

                {botDetected && (
                    <ErrorLabel text={trans('errors.unknownError')} />
                )}
            </form>

            <Button
                text={trans('containers.accountDashboardConfig.giftcard.checkGiftcardBalance')}
                onClick={handleSubmit}
            />
        </div>
    );
};
