import { transformSlugToName } from '../../helpers/string';
import trans from '../../helpers/trans';
import { removeTrailingSlash, replaceUrlParamKeysWithValues } from '../../helpers/url';
import { Link } from '../Link/Link';
import { transformToProductBreadcrumb } from '../Link/LinkTransformers';
import { AppRoute, appRoutes } from '../Routing/Routing';
import { ProductCategory, ProductCategoryParams } from './ProductCategory';

export const generateProductCategoryBreadcrumbs = (categoryParams: ProductCategoryParams): Link[] => {
    const categoryBreadcrumbs: Link[] = [];

    const { slug = '', subCategory = '', subSubCategory = '' } = categoryParams;

    const addBreadcrumb = (key: string, overrides: ProductCategoryParams): void => {
        if (!key) {
            return;
        }

        const productBreadcrumb = transformToProductBreadcrumb(key, overrides);

        categoryBreadcrumbs.push(productBreadcrumb);
    };

    addBreadcrumb(slug, {
        slug,
        subCategory: undefined,
        subSubCategory: undefined,
    });

    addBreadcrumb(subCategory, {
        slug,
        subCategory,
        subSubCategory: undefined,
    });

    addBreadcrumb(subSubCategory, {
        slug,
        subCategory,
        subSubCategory,
    });

    return categoryBreadcrumbs;
};

export const getProductCategoryReplacementUrl = (mainProductCategory: ProductCategory, categoryParams: ProductCategoryParams): string => {
    const { slug = '', subCategory = '', subSubCategory = '' } = categoryParams;

    const productSubCategory = subCategory
        ? mainProductCategory.children?.find(child => (
            child.name.toLowerCase() === transformSlugToName(subCategory)
        ))
        : undefined;

    const productSubSubCategory = subSubCategory
        ? productSubCategory?.children?.find(child => (
            child.name.toLowerCase() === transformSlugToName(subSubCategory)
        ))
        : undefined;

    const subCategoryDoesNotExists = subCategory && !productSubCategory;
    const subSubCategoryDoesNotExists = subSubCategory && !productSubSubCategory;

    if (subCategoryDoesNotExists || subSubCategoryDoesNotExists) {
        const productCategoryPath = trans(appRoutes[AppRoute.productCategory].path);

        const replacementUrl = replaceUrlParamKeysWithValues(productCategoryPath, {
            slug,
            subCategory: productSubCategory ? subCategory : undefined,
            subSubCategory: undefined,
        });

        return removeTrailingSlash(replacementUrl);
    }

    return '';
};
