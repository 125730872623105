export const range = (start = 0, end = start + 1, step = 1): number[] => {
    const output = [];

    for (let i = start; i < end; i += step) {
        output.push(i);
    }

    return output;
};

export const generateIdArray = (amount: number): number[] => range(0, amount);

export const shuffleArray = <T>(array: T[]): T[] => {
    const shuffledArray = [...array];

    return shuffledArray
        .map(value => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
};

export const retrieveUniqueValues = <T>(array: T[]): T[] => (
    Array.from(new Set(array))
);

export const zipArray = <T, U>(primaryArray: T[], secondaryArray: U[]): (T | U)[] => {
    const zippedArray: (T | U)[] = [];

    for (let i = 0; i < primaryArray.length; i += 1) {
        zippedArray.push(primaryArray[i]);

        if (secondaryArray[i]) {
            zippedArray.push(secondaryArray[i]);
        }
    }

    return zippedArray;
};
