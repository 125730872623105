import trans from '../helpers/trans';
import { FormOption } from './@forms/Form/Form';

export enum Country {
    nl = 'nl',
    be = 'be',
    de = 'de',
}

const countries = Object.values(Country)
    .map(country => country.toLowerCase());

export const countryOptions: FormOption[] = countries
    .map(value => ({
        label: trans(`entities.country.${value.toLowerCase()}`),
        value,
    }));
