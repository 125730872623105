import {
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
} from 'react';

import { useClientEffect } from '../../hooks';
import { getCart } from '../../redux/cart/cartActions';
import { fetchCustomer } from '../../redux/customer/customerActions';
import { fetchCustomPageSlugs } from '../../redux/customPages/customPagesActions';
import { fetchProductCategorySlugs } from '../../redux/productCategories/productCategoriesActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { getWishList } from '../../redux/wishlist/wishlistActions';

export const ConnectedAppWrapper: FC<PropsWithChildren> = ({
    children,
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const customer = useTypedSelector(state => state.customerSlice.customer);

    const isAuthenticated = useTypedSelector(state => state.authenticationSlice.isAuthenticated);

    useClientEffect((): void => {
        dispatch(fetchCustomPageSlugs());
        dispatch(fetchProductCategorySlugs());
    }, []);

    useEffect((): void => {
        dispatch(fetchCustomer());
    }, []);

    useEffect((): void => {
        dispatch(getCart());
        dispatch(getWishList());
    }, [customer]);

    useEffect((): void => {
        if (!isAuthenticated) {
            return;
        }

        dispatch(fetchCustomer());
    }, [isAuthenticated]);

    return <div>{children}</div>;
};
