import {
    CreateOrderItemResponse,
    CreateOrderResponse,
    DeleteOrderItemResponse,
    GetOrderResponse,
    GetProductVariantResponse,
    SetOrderItemResponse,
} from '../../entities/Api/Webshop';
import { CartItem, cartToken } from '../../entities/Cart/Cart';
import { transformToCart } from '../../entities/Cart/CartTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { authorizedSyliusFetch, syliusCustomerOrGuestFetch, syliusFetch } from '../../entities/Sylius/SyliusService';
import { SliceGetter, TypedDispatch } from '../store';
import {
    setCart,
    setError,
    setIsLoading,
    setIsSuccessfullyAdded,
    setIsSuccessfullyRemoved,
} from './cartSlice';

const createAndSetCartForGuest = async (dispatch: TypedDispatch): Promise<void> => {
    try {
        const body = { localeCode: 'nl' };

        const createCartResponse = await syliusFetch<CreateOrderResponse>('/shop/orders', {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(createCartResponse)) {
            dispatch(setError(createCartResponse.error));
            return;
        }

        if (!createCartResponse.data.tokenValue) {
            console.error('Token value is missing after creating a cart.');
            return;
        }

        localStorage.setItem(cartToken, JSON.stringify(createCartResponse.data.tokenValue));

        const cart = transformToCart(createCartResponse.data);
        dispatch(setCart(cart));
    } catch (error) {
        console.error('[createAndSetCartForGuest]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

const getAndSetCartForGuest = async (dispatch: TypedDispatch, storedCartToken: string): Promise<void> => {
    try {
        const cartTokenValue = JSON.parse(storedCartToken);

        const getCartResponse = await syliusFetch<CreateOrderResponse>(`/shop/orders/${cartTokenValue}`);

        if (!isFetchResultSuccessful(getCartResponse)) {
            dispatch(setError(getCartResponse.error));
            return;
        }

        const cart = transformToCart(getCartResponse.data);

        const getVariantIdFromCartItem = async (): Promise<CartItem[]> => Promise.all(cart.items.map(async item => {
            const productVariantResponse = await syliusFetch<GetProductVariantResponse>(`/shop/product-variants/${item.variantCode}`);

            if (!isFetchResultSuccessful(productVariantResponse)) {
                dispatch(setError(productVariantResponse.error));
                return item;
            }

            const { id } = productVariantResponse.data;

            return {
                ...item,
                variantId: id?.toString() || '',
            };
        }));

        const cartItems = await getVariantIdFromCartItem();

        const newCart = {
            ...cart,
            items: cartItems,
        };

        dispatch(setCart(newCart));
    } catch (error) {
        console.error('[getAndSetCartForGuest]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

const handleCustomerCart = async (dispatch: TypedDispatch): Promise<void> => {
    try {
        const body = { localeCode: 'nl' };

        const response = await authorizedSyliusFetch<CreateOrderResponse>('/shop/orders', {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));
            return;
        }

        const cart = transformToCart(response.data);

        const getVariantIdFromCartItem = async (): Promise<CartItem[]> => Promise.all(cart.items.map(async item => {
            const productVariantResponse = await syliusFetch<GetProductVariantResponse>(`/shop/product-variants/${item.variantCode}`);

            if (!isFetchResultSuccessful(productVariantResponse)) {
                dispatch(setError(productVariantResponse.error));
                return item;
            }

            const { id } = productVariantResponse.data;

            return {
                ...item,
                variantId: id?.toString() || '',
            };
        }));

        const cartItems = await getVariantIdFromCartItem();

        const newCart = {
            ...cart,
            items: cartItems,
        };

        dispatch(setCart(newCart));
    } catch (error) {
        console.error('[handleCustomerCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

const handleGuestCart = async (dispatch: TypedDispatch): Promise<void> => {
    const storedCartToken = localStorage.getItem(cartToken);

    if (!storedCartToken) {
        await createAndSetCartForGuest(dispatch);
    } else {
        await getAndSetCartForGuest(dispatch, storedCartToken);
    }
};

export const getCart = () => async (dispatch: TypedDispatch, getState: SliceGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        if (customer?.id) {
            await handleCustomerCart(dispatch);
        } else {
            await handleGuestCart(dispatch);
        }
    } catch (error) {
        console.error('[getCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addProductVariantToCart = (productVariantCode: string) => async (dispatch: TypedDispatch, getState: SliceGetter): Promise<void> => {
    dispatch(setIsSuccessfullyAdded(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { cart } = cartSlice;

        const body = {
            productVariant: productVariantCode,
            quantity: 1,
        };

        const addProductVariantToCartResponse = await syliusCustomerOrGuestFetch<CreateOrderItemResponse>(customer?.id, `/shop/orders/${cart.id}/items`, {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(addProductVariantToCartResponse)) {
            dispatch(setError(addProductVariantToCartResponse.error));
            return;
        }

        const transformedCart = transformToCart(addProductVariantToCartResponse.data);

        dispatch(setCart(transformedCart));
        dispatch(setIsSuccessfullyAdded(true));
    } catch (error) {
        console.error('[addProductVariantToCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateProductVariantInCart = (
    cartItemId: string,
    newQuantity: number,
) => async (dispatch: TypedDispatch, getState: SliceGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { cart } = cartSlice;

        const body = {
            quantity: newQuantity,
        };

        const updateProductVariantInCartResponse = await syliusCustomerOrGuestFetch<SetOrderItemResponse>(customer?.id, `/shop/orders/${cart.id}/items/${cartItemId}`, {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/merge-patch+json' },
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(updateProductVariantInCartResponse)) {
            dispatch(setError(updateProductVariantInCartResponse.error));
            return;
        }

        const transformedCart = transformToCart(updateProductVariantInCartResponse.data);

        dispatch(setCart(transformedCart));
    } catch (error) {
        console.error('[updateProductVariantInCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const removeProductVariantFromCart = (cartItemId: string) => async (dispatch: TypedDispatch, getState: SliceGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { cart } = cartSlice;

        const removeProductVariantFromCartResponse = await syliusCustomerOrGuestFetch<DeleteOrderItemResponse>(customer?.id, `/shop/orders/${cart.id}/items/${cartItemId}`, {
            method: 'DELETE',
        });

        if (!isFetchResultSuccessful(removeProductVariantFromCartResponse)) {
            dispatch(setError(removeProductVariantFromCartResponse.error));
            return;
        }

        const getCartResponse = await syliusCustomerOrGuestFetch<GetOrderResponse>(customer?.id, `/shop/orders/${cart.id}`);

        if (!isFetchResultSuccessful(getCartResponse)) {
            dispatch(setError(getCartResponse.error));
            return;
        }

        const updatedCart = transformToCart(getCartResponse.data);

        dispatch(setCart(updatedCart));
        dispatch(setIsSuccessfullyRemoved(true));
    } catch (error) {
        console.error('[removeProductVariantFromCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const clearCart = () => async (dispatch: TypedDispatch, getState: SliceGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { cartSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { cart } = cartSlice;

        const clearCartResponse = await syliusCustomerOrGuestFetch<DeleteOrderItemResponse>(customer?.id, `/shop/orders/${cart.id}`, {
            method: 'DELETE',
        });

        if (!isFetchResultSuccessful(clearCartResponse)) {
            dispatch(setError(clearCartResponse.error));
        }

        const storedCartToken = localStorage.getItem(cartToken);

        if (storedCartToken) {
            localStorage.removeItem(cartToken);
        }

        dispatch(getCart());
    } catch (error) {
        console.error('[clearCart]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
