import { FC, useEffect } from 'react';

import { useHits, useSearchBox } from 'react-instantsearch';

import { InstantSearchPage } from '../../../entities/Api/Webshop/Resource/InstantSearchPage';
import { Link } from '../../../entities/Link/Link';
import { transformPageHitToSearchPopupPageItem } from '../../../entities/PageSearchResult/PageSearchResultTransformers';

interface ConnectedPageHitsHandlerProps {
    query: string;
    onNewItems: (hits: Link[]) => void;
}

export const ConnectedPageHitsHandler: FC<ConnectedPageHitsHandlerProps> = ({
    query,
    onNewItems,
}): null => {
    const { refine } = useSearchBox();
    const { items } = useHits<InstantSearchPage>();

    useEffect((): void => {
        const trimmedQuery = query.trim();

        if (trimmedQuery.length > 2) {
            refine(trimmedQuery);
        }
    }, [query, refine]);

    useEffect((): void => {
        const transformedHits = items
            .map(transformPageHitToSearchPopupPageItem)
            .slice(0, 5);
        onNewItems(transformedHits);
    }, [items, onNewItems]);

    return null;
};
