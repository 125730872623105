import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Icon } from '../../../components';
import { PageSearchResult } from '../../../entities/PageSearchResult/PageSearchResult';
import { trimToMaxLength } from '../../../helpers/string';

import './InstantSearchPageHit.scss';

interface InstantSearchPageHitProps extends PageSearchResult {
    className?: string;
}

export const InstantSearchPageHit: FC<InstantSearchPageHitProps> = ({
    type,
    link,
    title,
    shortDescription,
    className = '',
}): ReactElement => {
    const description = shortDescription ? trimToMaxLength(shortDescription) : undefined;

    return (
        <div className={`instant-search-page-hit ${className}`}>
            <div>
                {type && (
                    <p className="instant-search-page-hit__type">
                        {type}
                    </p>
                )}

                <Link to={link.href} className="instant-search-page-hit__title">
                    {title}
                </Link>

                {description && (
                    <p className="instant-search-page-hit__description">
                        {description}
                    </p>
                )}
            </div>

            <Icon
                name="arrow-short-right"
                className="instant-search-page-hit__icon"
            />
        </div>
    );
};
