import { GetProductTaxonResponse } from '../Api/Webshop';
import { isFetchResultSuccessful } from '../FetchResult/FetchResult';
import { syliusFetch } from '../Sylius/SyliusService';
import { TaxonEntry } from './Taxon';
import { transformTaxonToCategory, transformTaxonToEndpoint, transformTaxonToId } from './TaxonTransformers';

export const fetchTaxonEntry = async (taxon: string): Promise<TaxonEntry | undefined> => {
    const id = transformTaxonToId(taxon);
    const endpoint = transformTaxonToEndpoint(taxon);

    const taxonResponse = await syliusFetch<GetProductTaxonResponse>(endpoint);

    if (!isFetchResultSuccessful(taxonResponse)) {
        return undefined;
    }

    const category = transformTaxonToCategory(taxonResponse.data.taxon);

    return [id, category];
};
