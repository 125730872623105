import { FC, ReactElement } from 'react';

import { ConnectedAccountData } from '../../connectors';
import { useTrans } from '../../hooks';

import './AccountDataPage.scss';

export const AccountDataPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <div className="account-data-page">
            <div className="account-data-page__header">
                <h5 className="account-data-page__heading">
                    {trans('pages.accountData.accountData')}
                </h5>

                <p className="account-data-page__description">
                    {trans('pages.accountData.description')}
                </p>
            </div>

            <div className="account-data-page__content">
                <ConnectedAccountData />
            </div>
        </div>
    );
};
