import { defaultAddress } from '../Address/Address';
import { transformStrapiAddressToAddress } from '../Address/AddressTransformers';
import { transformToBlock } from '../Block/BlockTransformers';
import { transformToDay } from '../Date/DateTransformers';
import { transformToImage } from '../Media/MediaTransformers';
import {
    AdjustedOpeningHours,
    AdjustedOpeningHoursResource,
    OpeningHours,
    OpeningHoursResource,
    Store,
    StoreResource,
} from './Store';

export const transformToOpeningHours = (resource: OpeningHoursResource): OpeningHours => {
    const day = transformToDay(resource.day);

    return {
        day,
        from: resource.openingHours.from,
        to: resource.openingHours.to,
        closed: resource.openingHours.closed,
    };
};

export const transformToAdjustedOpeningHours = (resource: AdjustedOpeningHoursResource): AdjustedOpeningHours => ({
    day: resource.day,
    from: resource.openingHours.from,
    to: resource.openingHours.to,
    closed: resource.openingHours.closed,
});

export const transformToStore = (resource: StoreResource): Store => {
    const address = resource.address
        ? transformStrapiAddressToAddress(resource.address)
        : defaultAddress();

    const adjustedOpeningHours = resource.adjustedOpeningHours.map(transformToAdjustedOpeningHours);

    const image = resource.image
        ? transformToImage(resource.image)
        : undefined;

    const openingHours = resource.openingHours.map(transformToOpeningHours);

    const blocks = resource.elements
        ? resource.elements.map(transformToBlock)
        : [];

    const publishedBlocks = blocks.filter(block => block.isPublished);

    return {
        id: resource.id,
        title: resource.title || '',
        description: resource.description || '',
        address,
        openingHours,
        adjustedOpeningHours,
        slug: resource.slug || '',
        image,
        blocks: publishedBlocks,
    };
};
