import { FC, ReactElement } from 'react';

import { Block } from '../../entities/Block/Block';
import { Image } from '../../entities/Media/Media';
import { BlockRenderer, CategoryHeader } from '..';

import './ProductCategoryRenderer.scss';

interface ProductCategoryRendererProps {
    isLoading: boolean;
    title: string;
    image: Image;
    blocks: Block[];
    className?: string;
}

export const ProductCategoryRenderer: FC<ProductCategoryRendererProps> = ({
    isLoading,
    title,
    image,
    blocks,
    className = '',
}): ReactElement => (
    <div className={`product-category-renderer ${className}`}>
        <CategoryHeader
            title={title}
            image={image}
            className="product-category-renderer__header"
        />

        {blocks.length > 0 && (
            <BlockRenderer
                isLoading={isLoading}
                blocks={blocks}
                className="product-category-renderer__block-renderer"
            />
        )}
    </div>
);
