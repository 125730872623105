import { generateUuid } from '../../helpers/string';
import trans from '../../helpers/trans';
import {
    AttributeResource,
    ChoiceItem,
    Locales,
    ProductAttributeKey,
    ProductFixedAttributeKey,
    ProductResource,
    ProductVariantResource,
} from '../Api/Webshop';
import { InstantSearchProductResource } from '../Api/Webshop/Resource/InstantSearchProduct';
import { transformBrandNameToLink, transformInstantSearchBrandNameToLink } from '../Brand/BrandTransformers';
import { defaultMediaItem, transformInstantSearchImagesToMediaItems, transformProductImageToMediaItem } from '../Media/MediaTransformers';
import {
    Product,
    ProductAttribute,
    ProductFixedAttribute,
    productVariantSeparator,
} from './Product';
import { ProductDefault } from './ProductDefault';
import { ProductVariant } from './ProductVariant';

const locale = Locales.nl;

export const transformSlugToVariantId = (slug: string): string => slug.split(productVariantSeparator)[1];

export const transformToProductCode = (product: string): string => product.split('/api/v2/shop/products/')[1];

export const transformToVariantCode = (product: string): string => product.split('/api/v2/shop/product-variants/')[1];

const getLocalizedAttributeValues = (choices: ChoiceItem, values: string[]): string[] => (
    values
        .filter(id => id in choices)
        .map(id => choices[id][locale])
);

const transformToProductAttribute = (resource: AttributeResource): ProductAttribute => {
    const values = resource.value;
    const { choices } = resource.attribute.configuration;

    const localizedValues = getLocalizedAttributeValues(choices, values);
    const formattedValue = localizedValues.join(', ');

    return {
        key: resource.code,
        label: resource.name,
        value: formattedValue,
    };
};

export const transformProductHitToProduct = (resource: InstantSearchProductResource): Product => {
    const variantSlug = resource.slug + productVariantSeparator + resource.code;

    const brand = resource.brand
        ? transformInstantSearchBrandNameToLink(resource.brand)
        : undefined;

    const mediaItems = resource.main_image && resource.image
        ? transformInstantSearchImagesToMediaItems(resource)
        : [defaultMediaItem()];

    return {
        id: resource.id.toString(),
        slug: resource.article_slug,
        name: resource.article_title || '',
        productCode: resource.code,
        shortDescription: resource.short_description || '',
        description: resource.description || '',
        variantId: resource.id,
        variantCode: resource.code,
        brand,
        mediaItems,
        price: resource.price.LOODS_5.price / 100,
        attributes: [],
        fixedAttributes: [],
        colors: [],
        variants: resource.variants || 0,
        variantSlug,
        stock: resource.stock,
        categories: resource.categories,
    };
};

export const transformInstantSearchProductToSearchPopupProductItem = (resource: InstantSearchProductResource) => {
    const slug = resource.slug + productVariantSeparator + resource.code;

    return {
        id: resource.id,
        name: resource.name || '',
        slug,
        brand: resource.brand.name,
        price: resource.price.LOODS_5.price / 100,
    };
};

export const transformToProductVariant = (resource: ProductVariantResource): ProductVariant => {
    const productCode = transformToProductCode(resource.product);

    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const translatedProductAttributes = resource.attributes
        ? resource.attributes.filter(attribute => attribute.localeCode === locale)
        : [];

    const productSpecificAttributes = translatedProductAttributes
        ? translatedProductAttributes.map(transformToProductAttribute)
        : [];

    const productCodeAttribute = {
        key: ProductAttributeKey.sku,
        label: trans('entities.product.sku'),
        value: productCode,
    };

    const attributes: ProductAttribute[] = [
        productCodeAttribute,
        ...productSpecificAttributes,
    ];

    // TODO: Retrieve from back-end
    const fixedAttributes: ProductFixedAttribute[] = [
        ProductFixedAttributeKey.width,
        ProductFixedAttributeKey.height,
        ProductFixedAttributeKey.depth,
    ].map(key => ({
        key,
        label: key,
        value: 'Data is niet beschikbaar',
    }));

    return {
        id,
        name: resource.name || '',
        variantCode: resource.code,
        variantId: resource.id?.toString() || '',
        attributes,
        fixedAttributes,
        price: resource.price,
        stock: resource.inStock,
        productCode,
    };
};

export const transformToProductDefault = (resource: ProductResource): ProductDefault => {
    const name = resource.name || '';

    const mediaItems = resource.images.length
        ? resource.images.map(image => transformProductImageToMediaItem(image, name))
        : [];

    return {
        id: resource['@id'],
        slug: resource.slug || '',
        name,
        shortDescription: resource.shortDescription || '',
        description: resource.description || '',
        mediaItems,
        colors: [],
        variants: resource.variants.length,
        productCode: resource.code,
        variantSlug: resource.slug + productVariantSeparator + resource.code,
    };
};

export const transformToProduct = (
    productDefault: ProductDefault,
    productVariant: ProductVariant,
    categories: string[],
    brandName: string,
): Product => {
    const brand = brandName
        ? transformBrandNameToLink(brandName)
        : undefined;

    return {
        id: productDefault.id,
        slug: productDefault.slug || '',
        name: productVariant.name || '',
        shortDescription: productDefault.shortDescription || '',
        description: productDefault.description || '',
        brand,
        mediaItems: productDefault.mediaItems,
        price: productVariant.price,
        attributes: productVariant.attributes,
        fixedAttributes: productVariant.fixedAttributes,
        colors: productDefault.colors,
        variantCode: productVariant.variantCode,
        variantId: productVariant.variantId,
        variantSlug: productDefault.variantSlug,
        variants: productDefault.variants,
        stock: productVariant.stock,
        categories,
        productCode: productDefault.productCode,
    };
};
