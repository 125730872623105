import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../../components';
import { ConnectedStoreDetail } from '../../../connectors/ConnectedStoreDetail/ConnectedStoreDetail';

export const StoreDetailPage: FC = (): ReactElement => (
    <Page className="store-detail-page">
        <Wrapper>
            <ConnectedStoreDetail />
        </Wrapper>
    </Page>
);
