import { UspItem } from '../@blocks/UspSection/UspSection';
import { FormOption } from '../@forms/Form/Form';
import { Image } from '../Media/Media';

export const cartToken = 'cartToken';

export enum CartCheckoutState {
    cart = 'cart',
    addressed = 'addressed',
    shipmentMethodSelected = 'shipmentMethodSelected',
    paymentInProgress = 'paymentInProgress',
    paymentDone = 'paymentDone',
}

export interface ShippingMethod {
    id: string;
    label: string;
    value: string;
    description: string;
    price: number;
}

export interface CartItem {
    id: string;
    variantId: string;
    variantCode: string;
    slug: string;
    name: string;
    quantity: number;
    mainImage: Image;
    totalPrice: number;
    price: number;
}

export interface CartInterface {
    id: string
    checkoutState?: CartCheckoutState;
    items: CartItem[];
    shippingMethods: ShippingMethod[];
    paymentMethods: FormOption[];
    subTotalPrice: number;
    shippingPrice: number;
    totalPrice: number;
}

export interface CartSidebarInterface extends CartInterface {
    uspItems: UspItem[];
}
