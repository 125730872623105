import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Link } from 'react-router-dom';
import { useDebounce } from 'react-use';

import { Picture, Price } from '../../../../components';
import { IconButton, NumberInputWithControls, WishlistButton } from '../../../../compositions';
import { CartItem } from '../../../../entities/Cart/Cart';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import { useTrans } from '../../../../hooks';

import './CartProduct.scss';

interface CartProductProps extends CartItem {
    isLoading?: boolean;
    wishlistItems: string[];
    onRemoveItemFromCart: (cartItemId: string) => void;
    onUpdateCartItem: (cartItemId: string, quantity: number) => void;
    onUpdateCartItemIsInWishlist: (productId: string, isInWishlist: boolean) => void;
    contentWrapperClassName?: string;
    className?: string;
}

export const CartProduct: FC<CartProductProps> = ({
    isLoading,
    id,
    mainImage,
    name,
    quantity,
    slug,
    totalPrice,
    variantId,
    wishlistItems,
    onRemoveItemFromCart,
    onUpdateCartItem,
    onUpdateCartItemIsInWishlist,
    contentWrapperClassName = '',
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [newQuantity, setNewQuantity] = useState<number>(quantity);
    const [isInWishlist, setIsInWishlist] = useState<boolean>(false);

    useEffect((): void => {
        if (wishlistItems.length === 0) {
            return;
        }

        const isProductVariantInWishlist = wishlistItems.includes(variantId);

        setIsInWishlist(isProductVariantInWishlist);
    }, [wishlistItems]);

    useDebounce((): void => {
        if (newQuantity && quantity !== newQuantity) {
            onUpdateCartItem(id, newQuantity);
        }
    }, 450, [newQuantity]);

    const handleRemoveFromCart = (): void => {
        onRemoveItemFromCart(id);
    };

    const handleUpdateWishlistStatus = (): void => {
        setIsInWishlist(!isInWishlist);

        onUpdateCartItemIsInWishlist(variantId, isInWishlist);
    };

    const productPath = trans(appRoutes[AppRoute.productDetail].path);
    const productUrl = replaceUrlParamKeysWithValues(productPath, { slug });

    return (
        <div className={`cart-product ${className}`}>
            <div className="cart-product__header">
                {mainImage && (
                    <Picture
                        {...mainImage}
                        className="cart-product__image"
                    />
                )}

                <Link to={productUrl} className="cart-product__name">
                    {name}
                </Link>
            </div>

            <div className={`cart-product__content-wrapper ${contentWrapperClassName}`}>
                <div className="cart-product__content">
                    {/* TODO: When product quantity has updated, the newQuantity is not updated. This is an issue in the NumberInputWithControls (<Input />) */}
                    <NumberInputWithControls
                        hideLabel
                        label={trans('containers.cartProduct.changeQuantity')}
                        value={newQuantity}
                        min={1}
                        max={99}
                        disabled={isLoading}
                        onChange={setNewQuantity}
                        className="cart-product__quantity-input"
                        buttonClassName="cart-product__quantity-button"
                    />

                    <WishlistButton
                        isActive={isInWishlist}
                        text={isInWishlist ? trans('common.removeFromWishlist') : trans('common.addToWishlist')}
                        disabled={isLoading}
                        onClick={handleUpdateWishlistStatus}
                        className="cart-product__button cart-product__wishlist-button"
                    />

                    <IconButton
                        hideLabel
                        text={trans('common.delete')}
                        icon="cross"
                        disabled={isLoading}
                        onClick={handleRemoveFromCart}
                        className="cart-product__button"
                    />
                </div>

                <Price
                    includeCurrencySymbol
                    amount={totalPrice}
                    className="cart-product__price"
                />
            </div>
        </div>
    );
};
