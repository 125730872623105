import { FC, ReactElement } from 'react';

import { useTrans } from '../../hooks';

import './ResultsCountForSearchTerm.scss';

interface ResultsCountForSearchTermProps {
    resultsCount: number;
    query?: string;
    className?: string;
}

export const ResultsCountForSearchTerm: FC<ResultsCountForSearchTermProps> = ({
    resultsCount,
    query,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`results-count-for-search-term ${className}`}>
            <p className="results-count-for-search-term__text">
                {query && query.length && (
                    trans('compositions.resultsCountForSearchTerm.searchResults', {
                        count: resultsCount,
                        query,
                    })
                )}
            </p>
        </div>
    );
};
