import { FC, ReactElement } from 'react';

import { StoreList } from '../../containers';
import { useTypedSelector } from '../../redux/store';

interface ConnectedStoreOverviewProps {
    className?: string;
}

export const ConnectedStoreOverview: FC<ConnectedStoreOverviewProps> = ({
    className = '',
}): ReactElement => {
    const stores = useTypedSelector(state => state.storeSlice.stores);

    return (
        <StoreList
            stores={stores}
            className={className}
        />
    );
};
