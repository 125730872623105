import { DefaultBlock, DefaultBlockResource } from '../../Block/Block';
import { Link } from '../../Link/Link';
import { Store, StoreResource } from '../../Store/Store';
import { StrapiEntity } from '../../Strapi/Strapi';

// Response type Strapi
export type TopNavbarResponse = StrapiEntity<TopNavbarDetailsResource>;

// Strapi response type
export interface TopNavbarDetailsResource extends DefaultBlockResource {
    usps: TopNavbarUsp[];
    links: Link[];
    shop: StoreResource | null;
}

// Front-end types
export interface TopNavbarInterface extends DefaultBlock {
    usps: TopNavbarUsp[];
    links: Link[];
    store: Store | null;
}

// Enum types USPs
export enum TopNabvarUspType {
    text = 'text',
    rating = 'rating',
}

// Interface USPs in front-end
export interface TopNavbarUsp {
    id: string;
    type: TopNabvarUspType;
    title?: string;
    data: string | TopNavbarRating;
}

// Rating-specific type USPs
export interface TopNavbarRating {
    url: string;
    stars: number;
}
