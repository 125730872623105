import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../../components';
import { generateIdArray } from '../../../../../helpers/array';
import { randomInBetweenValue } from '../../../../../helpers/number';

import './InstantSearchPageHitSkeletons.scss';

interface InstantSearchPageHitSkeletonsProps {
    numberOfSkeletons?: number;
    className?: string;
}

export const InstantSearchPageHitSkeletons: FC<InstantSearchPageHitSkeletonsProps> = ({
    numberOfSkeletons = randomInBetweenValue(4, 6),
    className = '',
}): ReactElement => {
    const instantSearchPageHitSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <ul className={`instant-search-page-hit-skeletons ${className}`}>
            {instantSearchPageHitSkeletons.map(skeleton => (
                <li key={skeleton} className="instant-search-page-hit-skeletons__list-item">
                    <div className="instant-search-page-hit-skeletons__tag-wrapper">
                        <Skeleton className="instant-search-page-hit-skeletons__tag" />
                    </div>

                    <div className="instant-search-page-hit-skeletons__title-wrapper ">
                        <Skeleton className="instant-search-page-hit-skeletons__title" />
                    </div>

                    <Skeleton className="instant-search-page-hit-skeletons__description" />
                </li>
            ))}
        </ul>
    );
};
