import { FC, ReactElement } from 'react';

import { useLocation } from 'react-router-dom';

import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useTrans } from '../../hooks';
import { CheckoutNavigationItem } from './subcomponents';

import './CheckoutNavigation.scss';

interface CheckoutNavigationProps {
    className?: string;
}

export const CheckoutNavigation: FC<CheckoutNavigationProps> = ({
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const location = useLocation();

    const currentStep = location.pathname;

    const navigationItems = [
        {
            id: 1,
            route: appRoutes[AppRoute.checkoutOrder],
            description: trans('containers.checkoutNavigation.checkoutOrderDescription'),
        },
        {
            id: 2,
            route: appRoutes[AppRoute.checkoutShipment],
            description: trans('containers.checkoutNavigation.checkoutShipmentDescription'),
        },
        {
            id: 3,
            route: appRoutes[AppRoute.checkoutPayment],
            description: trans('containers.checkoutNavigation.checkoutPaymentDescription'),
        },
        {
            id: 4,
            route: appRoutes[AppRoute.checkoutCompleting],
            description: trans('containers.checkoutNavigation.checkoutCompletingDescription'),
        },
    ];

    const items = navigationItems.map((item, index) => ({
        ...item,
        link: {
            label: trans(item.route.label),
            href: trans(item.route.path),
        },
        stepIndex: index + 1,
    }));

    const activeItem = items.find(item => {
        const routePath = trans(item.route.path);

        return routePath === currentStep;
    });

    const activeIndex = activeItem ? activeItem.stepIndex : 1;

    return (
        <nav
            aria-label={trans('containers.checkoutNavigation.navigationLabel')}
            className={`checkout-navigation ${className}`}
        >
            <ol className="checkout-navigation__list">
                {items.map(item => (
                    <CheckoutNavigationItem
                        key={item.id}
                        to={item.link.href}
                        label={item.link.label}
                        description={item.description}
                        stepIndex={item.stepIndex}
                        activeIndex={activeIndex}
                    />
                ))}
            </ol>
        </nav>
    );
};
