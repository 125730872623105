import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedOtherPageRenderer } from '../../connectors';

import './OtherPage.scss';

export const OtherPage: FC = (): ReactElement => (
    <Page className="other-page">
        <ConnectedOtherPageRenderer />
    </Page>
);
