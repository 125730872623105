import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CartInterface } from '../../entities/Cart/Cart';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type CartState = AsyncReduxState<{
    cart: CartInterface;
    isSuccessfulAdded: boolean;
    isSuccessfulRemoved: boolean;
}>;

const initialState: CartState = {
    ...initialAsyncReduxState,
    cart: {
        id: '',
        items: [],
        shippingMethods: [],
        paymentMethods: [],
        totalPrice: 0,
        subTotalPrice: 0,
        shippingPrice: 0,
    },
    isSuccessfulAdded: false,
    isSuccessfulRemoved: false,
};

const cartSlice = createSlice({
    name: 'cartSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): CartState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): CartState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): CartState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setCart(state, action: PayloadAction<CartInterface>): CartState {
            return {
                ...state,
                cart: action.payload,
            };
        },
        setIsSuccessfullyAdded(state, action: PayloadAction<boolean>): CartState {
            return {
                ...state,
                isSuccessfulAdded: action.payload,
            };
        },
        setIsSuccessfullyRemoved(state, action: PayloadAction<boolean>): CartState {
            return {
                ...state,
                isSuccessfulRemoved: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setCart,
    setIsSuccessfullyAdded,
    setIsSuccessfullyRemoved,
} = cartSlice.actions;

export default cartSlice;
