import qs from 'qs';

import { BlogOverviewRequestConfig } from './BlogOverview';

export const generateBlogOverviewQuery = (config: BlogOverviewRequestConfig): string => {
    const categories = config.categories.filter(Boolean);

    const filters = {
        ...categories ? {
            $or: Object.fromEntries(categories.map((category, index) => ([
                index,
                {
                    blogCategory: {
                        title: {
                            $eq: category,
                        },
                    },
                },
            ]))),
        } : {},
        ...config.excludeBlogId ? {
            $not: {
                id: {
                    $eq: config.excludeBlogId,
                },
            },
        } : {},
    };

    const populate = ['image', 'tags', 'blogCategory'];

    const { sort, pagination } = config;

    return qs.stringify({
        filters,
        populate,
        sort,
        pagination,
    }, {
        encodeValuesOnly: true,
    });
};
