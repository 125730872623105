import { FC, ReactElement } from 'react';

import { Wrapper } from '../../../components';
import { PageSearchResult } from '../../../entities/PageSearchResult/PageSearchResult';
import { InstantSearchPageHit } from '../InstantSearchPageHit/InstantSearchPageHit';
import { InstantSearchPageHitSkeletons } from '../InstantSearchPageHit/skeletons';

import './InstantSearchPagesList.scss';

interface InstantSearchPagesListProps {
    isLoading: boolean;
    pages: PageSearchResult[];
    className?: string;
}

export const InstantSearchPagesList: FC<InstantSearchPagesListProps> = ({
    isLoading,
    pages,
    className = '',
}): ReactElement => (
    <div className={`instant-search-pages-list ${className}`}>
        <Wrapper>
            {isLoading && (
                <InstantSearchPageHitSkeletons numberOfSkeletons={5} />
            )}

            {!isLoading && (
                <ul className="instant-search-pages-list__list">
                    {pages.map(page => (
                        <li key={page.id}>
                            <InstantSearchPageHit {...page} />
                        </li>
                    ))}
                </ul>
            )}
        </Wrapper>
    </div>
);
