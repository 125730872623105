import { capitalizeFirstCharacter, transformToSlug } from '../../helpers/string';
import trans from '../../helpers/trans';
import { removeTrailingSlash, replaceUrlParamKeysWithValues } from '../../helpers/url';
import { defaultBlogCategory } from '../@blog/BlogCategory/BlogCategory';
import { transformToBlogCategory } from '../@blog/BlogCategory/BlogCategoryTransformers';
import {
    InstantSearchBlogPostHitResource,
    InstantSearchBrandHitResource,
    InstantSearchCategoryHitResource,
    InstantSearchDepartmentHitResource,
    InstantSearchPage,
    InstantSearchPageHitResource,
    InstantSearchPageType,
    InstantSearchPageTypeResource,
    InstantSearchStoreHitResource,
} from '../Api/Webshop/Resource/InstantSearchPage';
import { appRoutePageTypes, PageType } from '../Page/Page';
import { transformToPageSearchResultTitle } from '../PageSearchResult/PageSearchResultTransformers';
import { ProductCategoryParams } from '../ProductCategory/ProductCategory';
import { AppRoute, appRoutes } from '../Routing/Routing';
import { Link, LinkTarget } from './Link';

export const transformNameToGoogleMapsLink = (name: string): string => {
    const cleanName = transformToSlug(name);

    return `https://www.google.com/maps/search/${cleanName}`;
};

const transformPageTypeToAppRoute = (pageType: PageType): AppRoute | undefined => {
    const pageTypeRoutes = Object.entries(appRoutePageTypes);
    const route = pageTypeRoutes.find(([, value]) => value === pageType);

    if (!route) {
        console.error(`AppRoute not found for pageType: ${pageType}`);
        return undefined;
    }

    return route[0] as AppRoute;
};

const transformInstantSearchPageHitToPath = (page: InstantSearchPageHitResource): string | undefined => {
    const { fixedType } = page;

    const appRoute = transformPageTypeToAppRoute(fixedType as PageType);

    return appRoute
        ? trans(appRoutes[appRoute].path)
        : undefined;
};

const transformInstantSearchBlogPostHitToPath = (blogPost: InstantSearchBlogPostHitResource): string => {
    const category = blogPost.blogCategory
        ? transformToBlogCategory(blogPost.blogCategory)
        : defaultBlogCategory();

    const blogPostPath = trans(appRoutes[AppRoute.blogPost].path);

    return replaceUrlParamKeysWithValues(blogPostPath, {
        category: category.slug,
        slug: blogPost.slug,
    });
};

const transformInstantSearchBrandHitToPath = (brand: InstantSearchBrandHitResource): string => {
    const brandDetailPath = trans(appRoutes[AppRoute.brandDetail].path);

    return replaceUrlParamKeysWithValues(brandDetailPath, {
        slug: brand.slug,
    });
};

const transformInstantSearchDepartmentHitToPath = (department: InstantSearchDepartmentHitResource): string => {
    const departmentDetailPath = trans(appRoutes[AppRoute.departmentDetail].path);

    return replaceUrlParamKeysWithValues(departmentDetailPath, {
        slug: department.slug,
    });
};

const transformInstantSearchCategorytHitToPath = (category: InstantSearchCategoryHitResource): string => {
    const productCategoryPath = trans(appRoutes[AppRoute.productCategory].path);

    const replacementUrl = replaceUrlParamKeysWithValues(productCategoryPath, {
        category: category.slug.toLowerCase(),
        subCategory: undefined,
        subSubCategory: undefined,
    });

    return removeTrailingSlash(replacementUrl);
};

const transformInstantSearchStoreHitToPath = (store: InstantSearchStoreHitResource): string => {
    const storeDetailPath = trans(appRoutes[AppRoute.storeDetail].path);

    return replaceUrlParamKeysWithValues(storeDetailPath, {
        slug: store.slug,
    });
};

export const transformInstantSearchPageLinkToLink = (resource: InstantSearchPage, type: InstantSearchPageType): Link => {
    let href = '';
    let label = resource.title;

    if (resource._meilisearch_id.includes(InstantSearchPageTypeResource.page)) {
        href = transformInstantSearchPageHitToPath(resource as InstantSearchPageHitResource) || '';
    } else if (resource._meilisearch_id.includes(InstantSearchPageTypeResource.blog)) {
        href = transformInstantSearchBlogPostHitToPath(resource as InstantSearchBlogPostHitResource);
    } else if (resource._meilisearch_id.includes(InstantSearchPageTypeResource.brand)) {
        label = transformToPageSearchResultTitle(resource as InstantSearchBrandHitResource, type);
        href = transformInstantSearchBrandHitToPath(resource as InstantSearchBrandHitResource);
    } else if (resource._meilisearch_id.includes(InstantSearchPageTypeResource.department)) {
        href = transformInstantSearchDepartmentHitToPath(resource as InstantSearchDepartmentHitResource);
    } else if (resource._meilisearch_id.includes(InstantSearchPageTypeResource.category)) {
        href = transformInstantSearchCategorytHitToPath(resource as InstantSearchCategoryHitResource);
    } else if (resource._meilisearch_id.includes(InstantSearchPageTypeResource.store)) {
        href = transformInstantSearchStoreHitToPath(resource as InstantSearchStoreHitResource);
    }

    return ({
        label,
        href,
        target: LinkTarget.self,
    });
};

const transformCategoryToProductBreadcrumb = (categoryName: string, href: string): Link => ({
    label: categoryName,
    href,
});

export const transformToProductBreadcrumb = (category: string, urlParams: ProductCategoryParams): Link => {
    const productCategoryPath = trans(appRoutes[AppRoute.productCategory].path);
    const productCategoryUrl = replaceUrlParamKeysWithValues(productCategoryPath, urlParams);

    const cleanProductCategoryUrl = removeTrailingSlash(productCategoryUrl);

    const title = capitalizeFirstCharacter(decodeURIComponent(category));

    return transformCategoryToProductBreadcrumb(title, cleanProductCategoryUrl);
};

export const transformProductCategoriesToBreadcrumbs = (categories: string[]): Link[] => {
    const breadcrumbParams: ProductCategoryParams = {
        slug: '',
        subCategory: undefined,
        subSubCategory: undefined,
    };

    return categories.map((category, index) => {
        if (index === 0) {
            breadcrumbParams.slug = category;
        }

        if (index === 1) {
            breadcrumbParams.subCategory = category;
        }

        if (index === 2) {
            breadcrumbParams.subSubCategory = category;
        }

        return transformToProductBreadcrumb(category, breadcrumbParams);
    });
};
