import { FC, ReactElement, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import { StoreDetail } from '../../containers/@blocks/StoreDetail/StoreDetail';
import { NotFoundPage } from '../../pages';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { fetchStoreDetail } from '../../redux/store/storeActions';

interface ConnectedStoreDetailProps {
    className?: string;
}

export const ConnectedStoreDetail: FC<ConnectedStoreDetailProps> = ({
    className = '',
}): ReactElement => {
    const { slug = '' } = useParams();
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.storeSlice.isLoading);
    const store = useTypedSelector(state => state.storeSlice.store);

    useEffect((): void => {
        dispatch(fetchStoreDetail(slug));
    }, [slug]);

    if (!store) {
        return (
            <NotFoundPage />
        );
    }

    return (
        <StoreDetail
            isLoading={isLoading}
            store={store}
            className={className}
        />
    );
};
