import { AddressType, CustomerAddress } from '../../entities/Address/Address';
import {
    CreateAddressResponse,
    GetAddressesResponse,
    GetCustomerResponse,
    Token,
    tokenKey,
    UpdateAddressResponse,
    UpdateCustomerResponse,
} from '../../entities/Api/Webshop';
import { Customer } from '../../entities/Customer/Customer';
import { transformCustomerAddressToAddressFormData, transformToAddress, transformToCustomer } from '../../entities/Customer/CustomerTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { authorizedSyliusFetch } from '../../entities/Sylius/SyliusService';
import trans from '../../helpers/trans';
import { ReduxFetchAction } from '../defaults';
import { addPositiveToast } from '../toast/toastActions';
import { setCustomer, setError, setIsLoading } from './customerSlice';

export const getAddresses = async (defaultAddressId: string): Promise<CustomerAddress[]> => {
    try {
        const getAddressesResponse = await authorizedSyliusFetch<GetAddressesResponse>('/shop/addresses');

        if (!isFetchResultSuccessful(getAddressesResponse)) {
            return [];
        }

        return getAddressesResponse.data.map(item => {
            const type = item.id === Number(defaultAddressId)
                ? AddressType.shipping
                : AddressType.billing;

            return transformToAddress(item, type);
        });
    } catch (error) {
        console.error('[getAddresses]', error);
        return [];
    }
};

export const fetchCustomer: ReduxFetchAction = () => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const storedToken = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey);
        const tokenData = storedToken
            ? JSON.parse(storedToken) as Token
            : undefined;

        const customerId = tokenData?.customerId || '';

        const customerResponse = await authorizedSyliusFetch<GetCustomerResponse>(`/shop/customers/${customerId}`);

        if (!isFetchResultSuccessful(customerResponse)) {
            dispatch(setError(customerResponse.error));
            return;
        }

        const shippingAddressId = customerResponse.data.defaultAddress?.replace('/api/v2/shop/addresses/', '');

        const addresses = shippingAddressId
            ? await getAddresses(shippingAddressId)
            : [];

        const customer = transformToCustomer(customerResponse.data, customerId, addresses);
        dispatch(setCustomer(customer));
    } catch (error) {
        console.error('[fetchCustomer]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateCustomer: ReduxFetchAction<Customer> = newCustomer => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        const customerId = customer?.id || '';

        const customerResponse = await authorizedSyliusFetch<UpdateCustomerResponse>(`/shop/customers/${customer?.id}`, {
            method: 'PUT',
            body: JSON.stringify(newCustomer),
        });

        if (!isFetchResultSuccessful(customerResponse)) {
            dispatch(setError(customerResponse.error));
            return;
        }

        const updatedCustomer = transformToCustomer(customerResponse.data, customerId, newCustomer.addresses);
        dispatch(setCustomer(updatedCustomer));

        dispatch(addPositiveToast({
            title: trans('redux.customer.updateProfileSuccessMessageTitle'),
            description: trans('redux.customer.updateProfileSuccessMessageDescription'),
        }));
    } catch (error) {
        console.error('[updateCustomer]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateAddress: ReduxFetchAction<CustomerAddress> = address => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        const customerResponse = await authorizedSyliusFetch<GetCustomerResponse>(`/shop/customers/${customer?.id}`);

        if (!isFetchResultSuccessful(customerResponse)) {
            dispatch(setError(customerResponse.error));
            return;
        }

        const addressId = address.id?.replace('api/v2/shop/addresses/', '');

        const body = transformCustomerAddressToAddressFormData(address);

        const updateAddressResponse = await authorizedSyliusFetch<UpdateAddressResponse>(`/shop/addresses/${addressId}`, {
            method: 'PUT',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(updateAddressResponse)) {
            dispatch(setError(updateAddressResponse.error));
            return;
        }

        const shippingAddressId = customerResponse.data.defaultAddress?.replace('/api/v2/shop/addresses/', '');

        const addresses = shippingAddressId
            ? await getAddresses(shippingAddressId)
            : [];

        const updatedCustomer = transformToCustomer(customerResponse.data, customer?.id || '', addresses);
        dispatch(setCustomer(updatedCustomer));

        dispatch(addPositiveToast({
            title: trans('redux.customer.updateProfileSuccessMessageTitle'),
            description: trans('redux.customer.updateProfileSuccessMessageDescription'),
        }));
    } catch (error) {
        console.error('[updateAddress]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const addAddress: ReduxFetchAction<CustomerAddress> = address => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        const body = transformCustomerAddressToAddressFormData(address, customer);

        const addAddressResponse = await authorizedSyliusFetch<CreateAddressResponse>('/shop/addresses', {
            method: 'POST',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(addAddressResponse)) {
            dispatch(setError(addAddressResponse.error));
            return;
        }

        const customerResponse = await authorizedSyliusFetch<GetCustomerResponse>(`/shop/customers/${customer?.id}`);

        if (!isFetchResultSuccessful(customerResponse)) {
            dispatch(setError(customerResponse.error));
            return;
        }

        const shippingAddressId = customerResponse.data.defaultAddress?.replace('/api/v2/shop/addresses/', '');

        const addresses = shippingAddressId
            ? await getAddresses(shippingAddressId)
            : [];

        const updatedCustomer = transformToCustomer(customerResponse.data, customer?.id || '', addresses);
        dispatch(setCustomer(updatedCustomer));
    } catch (error) {
        console.error('[addAddress]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const updateDefaultAddress: ReduxFetchAction<string> = addressId => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        const body = { defaultAddress: `/api/v2/shop/addresses/${addressId}` };

        const updateDefaultAddressResponse = await authorizedSyliusFetch<UpdateCustomerResponse>(`/shop/customers/${customer?.id}`, {
            method: 'PUT',
            body: JSON.stringify(body),
        });

        if (!isFetchResultSuccessful(updateDefaultAddressResponse)) {
            dispatch(setError(updateDefaultAddressResponse.error));
            return;
        }

        const customerResponse = await authorizedSyliusFetch<GetCustomerResponse>(`/shop/customers/${customer?.id}`);

        if (!isFetchResultSuccessful(customerResponse)) {
            dispatch(setError(customerResponse.error));
            return;
        }

        const shippingAddressId = customerResponse.data.defaultAddress?.replace('/api/v2/shop/addresses/', '');

        const addresses = shippingAddressId
            ? await getAddresses(shippingAddressId)
            : [];

        const updatedCustomer = transformToCustomer(customerResponse.data, customer?.id || '', addresses);
        dispatch(setCustomer(updatedCustomer));

        dispatch(addPositiveToast({
            title: trans('redux.customer.updateProfileSuccessMessageTitle'),
            description: trans('redux.customer.updateProfileSuccessMessageDescription'),
        }));
    } catch (error) {
        console.error('[updateDefaultAddress]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
