import { FC, ReactElement } from 'react';

import { ProductInformation } from '../../containers';
import { useClientEffect } from '../../hooks';
import { fetchBrandItem } from '../../redux/brand/brandActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

interface ConnectedProductInformationProps {
    className?: string;
}

export const ConnectedProductInformation: FC<ConnectedProductInformationProps> = ({
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.productSlice.isLoading);
    const product = useTypedSelector(state => state.productSlice.product);

    const brandItem = useTypedSelector(state => state.brandSlice.brandItem);

    useClientEffect((): void => {
        if (!product?.brand) {
            return;
        }

        dispatch(fetchBrandItem(product.brand.label));
    }, [product]);

    return (
        <ProductInformation
            isLoading={isLoading}
            product={product}
            brand={brandItem}
            className={className}
        />
    );
};
