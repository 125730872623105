import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { PageResponse } from '../../entities/Page/Page';
import { generateProductCategoriesQuery } from '../../entities/ProductCategory/ProductCategoryRequests';
import { strapiFetch } from '../../entities/Strapi/StrapiService';
import { generateIdArray } from '../../helpers/array';
import { transformToSlug } from '../../helpers/string';
import { ReduxFetchAction } from '../defaults';
import {
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setSlugs,
} from './productCategoriesSlice';

export const fetchProductCategorySlugs: ReduxFetchAction = () => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));
    dispatch(setSlugs([]));

    try {
        const query = generateProductCategoriesQuery(1);
        const productCategoriesResponse = await strapiFetch<PageResponse>(`/categories?${query}`);

        if (!isFetchResultSuccessful(productCategoriesResponse)) {
            dispatch(setError(productCategoriesResponse.error));
            return;
        }

        const { data: productCategoriesData, meta } = productCategoriesResponse.data;
        const { pagination } = meta;

        const extraPages = productCategoriesData
            ? Math.ceil(pagination.total / productCategoriesData.length) - 1
            : 0;

        const pagesToFetch = generateIdArray(extraPages).map(page => page + 2);

        let slugs = productCategoriesData
            ? productCategoriesData.map(customPage => transformToSlug(customPage.slug))
            : [];

        const pageFetchPromises = pagesToFetch.map(async page => {
            const pageQuery = generateProductCategoriesQuery(page);
            const pageResponse = await strapiFetch<PageResponse>(`/categories?${pageQuery}`);

            if (!isFetchResultSuccessful(pageResponse)) {
                dispatch(setError(pageResponse.error));
                return;
            }

            const { data: pageData } = pageResponse.data;

            const pageSlugs = pageData
                ? pageData.map(productCategory => transformToSlug(productCategory.slug))
                : [];

            slugs = [...slugs, ...pageSlugs];
        });

        await Promise.allSettled(pageFetchPromises);

        dispatch(setSlugs(slugs));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchProductCategorySlugs]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
