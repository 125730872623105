import { FC, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { Link as LinkInterface } from '../../entities/Link/Link';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useTrans } from '../../hooks';
import { generateBreadcrumbsStructuredData } from './helpers';
import { BreadcrumbsSkeletons } from './skeletons';

import './Breadcrumbs.scss';

interface BreadcrumbsProps {
    isLoading?: boolean;
    breadcrumbs: LinkInterface[];
    className?: string;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
    isLoading,
    breadcrumbs,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const startBreadcrumb: LinkInterface = {
        label: trans(appRoutes[AppRoute.home].label),
        href: trans(appRoutes[AppRoute.home].path),
    };

    const totalBreadcrumbs: LinkInterface[] = [
        startBreadcrumb,
        ...breadcrumbs,
    ];

    const siteUrl = trans('company.url');
    const structuredData = totalBreadcrumbs.length > 1
        ? generateBreadcrumbsStructuredData(totalBreadcrumbs, siteUrl)
        : undefined;

    return (
        <div className={`breadcrumbs ${className}`}>
            {isLoading && (
                <BreadcrumbsSkeletons />
            )}

            {!isLoading && totalBreadcrumbs.map((breadcrumb, index) => (
                <div key={breadcrumb.href} className="breadcrumbs__item">
                    <Link to={breadcrumb.href}>
                        {breadcrumb.label}
                    </Link>

                    {index < breadcrumbs.length && ' / '}
                </div>
            ))}

            {structuredData && (
                <Helmet>
                    <script type="application/ld+json">
                        {structuredData}
                    </script>
                </Helmet>
            )}
        </div>
    );
};
