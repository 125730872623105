import { generateUuid } from '../../helpers/string';
import { BlogOverviewBlockResource } from '../@blocks/BlogOverview/BlogOverview';
import { transformToBlogOverview } from '../@blocks/BlogOverview/BlogOverviewTransformers';
import { BrandCarouselResource } from '../@blocks/BrandCarousel/BrandCarousel';
import { transformToBrandCarousel } from '../@blocks/BrandCarousel/BrandCarouselTransformers';
import { CarouselResource } from '../@blocks/Carousel/Carousel';
import { transformToCarousel } from '../@blocks/Carousel/CarouselTransformers';
import { ContentColumnsResource } from '../@blocks/ContentColumns/ContentColumns';
import { transformToContentColumns } from '../@blocks/ContentColumns/ContentColumnsTransformers';
import { FaqDataResource } from '../@blocks/Faq/Faq';
import { transformToFaqItems } from '../@blocks/Faq/FaqTransformers';
import { FormResource } from '../@blocks/Form/Form';
import { transformToForm } from '../@blocks/Form/FormTransformers';
import { HeaderResource } from '../@blocks/Header/Header';
import { transformToHeader } from '../@blocks/Header/HeaderTransformers';
import { HighlightedBlogPostResource } from '../@blocks/HighlightedBlogPost/HighlightedBlogPost';
import { transformToHighlightedBlogPost } from '../@blocks/HighlightedBlogPost/HighlightedBlogPostTransformers';
import { IconColumnsResource } from '../@blocks/IconColumns/IconColumns';
import { transformToIconColumns } from '../@blocks/IconColumns/IconColumnsTransformers';
import { MediaGridResource } from '../@blocks/MediaGrid/MediaGrid';
import { transformToMediaGrid } from '../@blocks/MediaGrid/MediaGridTransformers';
import { NewsletterCallToActionResource } from '../@blocks/NewsletterCallToAction/NewsletterCallToAction';
import { transformToNewsletterCallToAction } from '../@blocks/NewsletterCallToAction/NewsletterCallToActionTransformers';
import { ServiceSectionResource } from '../@blocks/ServiceSection/ServiceSection';
import { transformToServiceSection } from '../@blocks/ServiceSection/ServiceSectionTransformers';
import { TopNavbarDetailsResource } from '../@blocks/TopNavbar/TopNavbar';
import { transformToTopNavbar } from '../@blocks/TopNavbar/TopNavbarTransformers';
import { UrgentBannerResource } from '../@blocks/UrgentBanner/UrgentBanner';
import { transformToUrgentBanner } from '../@blocks/UrgentBanner/UrgentBannerTransformers';
import { UspSectionResource } from '../@blocks/UspSection/UspSection';
import { transformToUspSection } from '../@blocks/UspSection/UspSectionTransformers';
import { transformSchedulingToPublishState } from '../Scheduling/SchedulingTransformers';
import { Block, BlockResource, BlockTypeResource } from './Block';

export const transformToDefaultBlock = (resource: BlockResource): Block => {
    const isPublished = resource.scheduling
        ? transformSchedulingToPublishState(resource.scheduling)
        : true;

    const id = resource.id
        ? `${resource.__component}-${resource.id}`
        : generateUuid();

    return {
        isPublished,
        id,
        type: resource.__component,
    };
};

export const transformToBlock = (resource: BlockResource): Block => {
    const component = resource.__component;

    if (component === BlockTypeResource.blogOverview) {
        return transformToBlogOverview(resource as BlogOverviewBlockResource);
    }

    if (component === BlockTypeResource.brandCarousel) {
        return transformToBrandCarousel(resource as BrandCarouselResource);
    }

    if (component === BlockTypeResource.carousel) {
        return transformToCarousel(resource as CarouselResource);
    }

    if (component === BlockTypeResource.contentColumns) {
        return transformToContentColumns(resource as ContentColumnsResource);
    }

    if (component === BlockTypeResource.form) {
        return transformToForm(resource as FormResource);
    }

    if (component === BlockTypeResource.header) {
        return transformToHeader(resource as HeaderResource);
    }

    if (component === BlockTypeResource.highlightedBlogPost) {
        return transformToHighlightedBlogPost(resource as HighlightedBlogPostResource);
    }

    if (component === BlockTypeResource.iconColumns) {
        return transformToIconColumns(resource as IconColumnsResource);
    }

    if (component === BlockTypeResource.faq) {
        return transformToFaqItems(resource as FaqDataResource);
    }

    if (component === BlockTypeResource.mediaGrid) {
        return transformToMediaGrid(resource as MediaGridResource);
    }

    if (component === BlockTypeResource.newsletterCallToAction) {
        return transformToNewsletterCallToAction(resource as NewsletterCallToActionResource);
    }

    if (component === BlockTypeResource.service) {
        return transformToServiceSection(resource as ServiceSectionResource);
    }

    if (component === BlockTypeResource.topNavbar) {
        return transformToTopNavbar(resource as TopNavbarDetailsResource);
    }

    if (component === BlockTypeResource.urgentBanner) {
        return transformToUrgentBanner(resource as UrgentBannerResource);
    }

    if (component === BlockTypeResource.uspSection) {
        return transformToUspSection(resource as UspSectionResource);
    }

    return { isPublished: false, type: '' };
};
