import { FC, ReactElement } from 'react';

import { Navigation } from '../../../../entities/Navigation/Navigation';
import { useTrans } from '../../../../hooks';
import { SsrNavigationItem } from '../SsrNavigationItem/SsrNavigationItem';

import './SsrNavigation.scss';

interface SsrNavigationProps {
    navigation: Navigation;
}

export const SsrNavigation: FC<SsrNavigationProps> = ({
    navigation,
}): ReactElement => {
    const trans = useTrans();

    const navigationLabel = navigation?.parent?.label || trans('containers.menu.mainNavigation');

    return (
        <div className="ssr-navigation">
            <nav aria-label={navigationLabel}>
                <ul>
                    {navigation.groups.map(group => (
                        group.items.map(item => (
                            <SsrNavigationItem key={item.id} item={item} />
                        ))
                    ))}
                </ul>
            </nav>
        </div>
    );
};
