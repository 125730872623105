import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './ProductDetailSkeletons.scss';

interface ProductDetailSkeletonsProps {
    numberOfSkeletons?: number;
}

export const ProductDetailSkeletons: FC<ProductDetailSkeletonsProps> = ({
    numberOfSkeletons = 6,
}): ReactElement => {
    const imageSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <div className="product-detail-skeletons__content">
            <ul className="product-detail-skeletons__images">
                {imageSkeletons.map(skeleton => (
                    <li key={skeleton} className="product-detail-skeletons__item">
                        <Skeleton className="product-detail-skeletons__image" />
                    </li>
                ))}
            </ul>

            <Skeleton className="product-detail-skeletons__sidebar" />
        </div>
    );
};
