import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Price, SafeHtml } from '../../../../components';
import { ProductCallToAction } from '../../../../compositions';
import { Product } from '../../../../entities/Product/Product';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import { useTrans } from '../../../../hooks';

import './ProductSidebar.scss';

interface ProductSidebarProps {
    product: Product;
    className?: string;
}

export const ProductSidebar: FC<ProductSidebarProps> = ({
    product,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const brandDetailPath = trans(appRoutes[AppRoute.brandDetail].path);
    const brandDetailUrl = replaceUrlParamKeysWithValues(brandDetailPath, {
        slug: product.brand?.href,
    });

    return (
        <div className={`product-sidebar ${className}`}>
            <div className="product-sidebar__brand-wrapper">
                {product.brand && (
                    <Link to={brandDetailUrl} className="product-sidebar__brand-link">
                        <h3 className="product-sidebar__brand">
                            {product.brand.label}
                        </h3>
                    </Link>
                )}
            </div>

            <h1 className="product-sidebar__name">
                {product.name}
            </h1>

            <Price
                amount={product.price}
                className="product-sidebar__price"
            />

            {product.shortDescription && (
                <SafeHtml
                    html={product.shortDescription}
                    className="product-sidebar__description"
                />
            )}

            <div className="product-sidebar__notices">
                {/* TODO: Retrieve from API */}
                <p className="product-sidebar__notice">
                    Verkrijgbaar vanaf maart
                </p>
            </div>

            <ProductCallToAction
                product={product}
                className="product-sidebar__call-to-action"
            />
        </div>
    );
};
