import { InstantSearchBrand } from '../Api/Webshop/Resource/InstantSearchProduct';
import { transformToBlock } from '../Block/BlockTransformers';
import { Link } from '../Link/Link';
import { transformToImage } from '../Media/MediaTransformers';
import { Brand, BrandItemResource } from './Brand';

export const transformInstantSearchBrandNameToLink = (resource: InstantSearchBrand): Link => ({
    label: resource.name,
    href: resource.slug,
});

export const transformBrandNameToLink = (value: string): Link => ({
    label: value,
    href: value.toLowerCase(),
});

export const transformBrandItemToBrand = (resource: BrandItemResource): Brand => {
    const image = resource.logo
        ? transformToImage(resource.logo)
        : undefined;

    const blocks = resource.elements
        ? resource.elements.map(transformToBlock)
        : [];

    const publishedBlocks = blocks.filter(block => block.isPublished);

    return {
        id: resource.documentId,
        label: resource.name,
        description: resource.description || '',
        href: resource.slug,
        logo: image,
        blocks: publishedBlocks,
    };
};
