import { FC, ReactElement } from 'react';

import { Breadcrumbs } from '../../containers';
import { useTypedSelector } from '../../redux/store';

interface ConnectedProductCategoryBreadcrumbsProps {
    className?: string;
}

export const ConnectedProductCategoryBreadcrumbs: FC<ConnectedProductCategoryBreadcrumbsProps> = ({
    className = '',
}): ReactElement => {
    const isLoading = useTypedSelector(state => state.productCategorySlice.isLoading);
    const breadcrumbs = useTypedSelector(state => state.productCategorySlice.breadcrumbs);

    return (
        <Breadcrumbs
            isLoading={isLoading}
            breadcrumbs={breadcrumbs}
            className={className}
        />
    );
};
