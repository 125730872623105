import {
    AddVariantToWishlistResponse,
    CreateWishlistResponse,
    DeleteVariantFromWishlistResponse,
    GetWishlistResponse,
} from '../../entities/Api/Webshop/Response/Wishlist';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { authorizedSyliusFetch, syliusCustomerOrGuestFetch, syliusFetch } from '../../entities/Sylius/SyliusService';
import { Wishlist, wishlistToken } from '../../entities/Wishlist/Wishlist';
import { transformToWishlist } from '../../entities/Wishlist/WishlistTransformers';
import { SliceGetter, TypedDispatch } from '../store';
import {
    setError,
    setIsLoading,
    setIsSuccessfullyAdded,
    setIsSuccessfullyRemoved,
    setWishlist,
} from './wishlistSlice';

export const addProductVariantToWishlist = (productVariantId: string) => async (dispatch: TypedDispatch, getState: SliceGetter): Promise<void> => {
    dispatch(setIsSuccessfullyAdded(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { wishlistSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { wishlist } = wishlistSlice;

        const body = { productVariantId: Number(productVariantId) };

        const addProductVariantToWishlistResponse = await syliusCustomerOrGuestFetch<AddVariantToWishlistResponse>(
            customer?.id,
            `/shop/wishlists/${wishlist.id}/variant`,
            {
                method: 'PATCH',
                headers: { 'Content-Type': 'application/merge-patch+json' },
                body: JSON.stringify(body),
            },
        );

        if (!isFetchResultSuccessful(addProductVariantToWishlistResponse)) {
            dispatch(setError(addProductVariantToWishlistResponse.error));
            return;
        }

        const transformedWishlist = transformToWishlist(addProductVariantToWishlistResponse.data);

        dispatch(setWishlist(transformedWishlist));
        dispatch(setIsSuccessfullyAdded(true));
    } catch (error) {
        console.error('[addProductVariantToWishList]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

export const removeProductVariantFromWishlist = (productVariantId: string) => async (
    dispatch: TypedDispatch,
    getState: SliceGetter,
): Promise<void> => {
    dispatch(setIsSuccessfullyRemoved(false));
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { wishlistSlice, customerSlice } = getState();
        const { customer } = customerSlice;
        const { wishlist } = wishlistSlice;

        const removeProductVariantFromWishlistResponse = await syliusCustomerOrGuestFetch<DeleteVariantFromWishlistResponse>(
            customer?.id,
            `/shop/wishlists/${wishlist.id}/productVariants/${productVariantId}`,
            {
                method: 'DELETE',
            },
        );

        if (!isFetchResultSuccessful(removeProductVariantFromWishlistResponse)) {
            dispatch(setError(removeProductVariantFromWishlistResponse.error));
            return;
        }

        const transformedWishlist: Wishlist = {
            ...wishlist,
            productVariantIds: wishlist.productVariantIds.filter(item => item !== productVariantId),
        };

        dispatch(setWishlist(transformedWishlist));
        dispatch(setIsSuccessfullyRemoved(true));
    } catch (error) {
        console.error('[removeProductVariantFromWishlist]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

const createAndSetWishlist = async (dispatch: TypedDispatch): Promise<void> => {
    const createWishlistResponse = await syliusFetch<CreateWishlistResponse>('/shop/wishlists', {
        method: 'POST',
        body: JSON.stringify({ localeCode: 'nl' }),
    });

    if (!isFetchResultSuccessful(createWishlistResponse)) {
        dispatch(setError(createWishlistResponse.error));
        return;
    }

    const { token = '' } = createWishlistResponse.data;

    localStorage.setItem(wishlistToken, JSON.stringify(token));

    const wishlist = transformToWishlist(createWishlistResponse.data);
    dispatch(setWishlist(wishlist));
};

const getAndSetWishlist = async (dispatch: TypedDispatch, storedWishListToken: string): Promise<void> => {
    const wishlistTokenValue = JSON.parse(storedWishListToken);

    const getWishlistResponse = await syliusFetch<GetWishlistResponse>(`/shop/wishlists/${wishlistTokenValue}`);

    if (!isFetchResultSuccessful(getWishlistResponse)) {
        dispatch(setError(getWishlistResponse.error));
        return;
    }

    const wishlist = transformToWishlist(getWishlistResponse.data);
    dispatch(setWishlist(wishlist));
};

const handleCustomerWishlist = async (dispatch: TypedDispatch, getState: SliceGetter): Promise<void> => {
    try {
        const { wishlistSlice } = getState();
        const { wishlist } = wishlistSlice;

        const response = await authorizedSyliusFetch<CreateWishlistResponse>('/shop/wishlists', {
            method: 'POST',
            body: JSON.stringify({ localeCode: 'nl' }),
        });

        if (!isFetchResultSuccessful(response)) {
            dispatch(setError(response.error));
            return;
        }

        const transformedWishlist = transformToWishlist(response.data);
        dispatch(setWishlist(transformedWishlist));

        if (wishlist.productVariantIds.length) {
            wishlist.productVariantIds.map(async (productVariantId) => dispatch(addProductVariantToWishlist(productVariantId)));
        }
    } catch (error) {
        console.error('[handleCustomerWishlist]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};

const handleGuestWishlist = async (dispatch: TypedDispatch): Promise<void> => {
    const storedWishlistToken = localStorage.getItem(wishlistToken);

    if (!storedWishlistToken) {
        await createAndSetWishlist(dispatch);
    } else {
        await getAndSetWishlist(dispatch, storedWishlistToken);
    }
};

export const getWishList = () => async (dispatch: TypedDispatch, getState: SliceGetter): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));

    try {
        const { customerSlice } = getState();
        const { customer } = customerSlice;

        if (customer?.id) {
            localStorage.removeItem(wishlistToken);

            await handleCustomerWishlist(dispatch, getState);
        } else {
            await handleGuestWishlist(dispatch);
        }
    } catch (error) {
        console.error('[getWishList]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
