import { FC, ReactElement, useEffect } from 'react';

import { TopNavbar } from '../../containers';
import { fetchTopNavbar } from '../../redux/@blocks/topNavbar/topNavbarActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { fetchStores } from '../../redux/store/storeActions';

interface ConnectedTopNavbarProps {
    className?: string;
}

export const ConnectedTopNavbar: FC<ConnectedTopNavbarProps> = ({
    className = '',
    ...topNavbarProps
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const topNavbarItems = useTypedSelector(state => state.topNavbarSlice.topNavbarItems);
    const stores = useTypedSelector(state => state.storeSlice.stores);

    useEffect((): void => {
        dispatch(fetchTopNavbar());
        dispatch(fetchStores());
    }, []);

    const store = stores.length > 0
        ? topNavbarItems?.store || stores[0]
        : null;

    return (
        <TopNavbar
            {...topNavbarProps}
            links={topNavbarItems?.links || []}
            usps={topNavbarItems?.usps || []}
            store={store}
            className={className}
        />
    );
};
