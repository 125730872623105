import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { createRoot } from 'react-dom/client';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

// @ts-ignore
import packageJson from '../package.json';
import { App } from './App';
import { CookieProvider, LocaleProvider } from './context';
import { isProduction } from './helpers';
import { registerServiceWorker } from './helpers/pwa';
import { store } from './redux/store';

import './styles/global.scss';

if (process.env.REACT_APP_BUGSNAG_API_KEY) {
    Bugsnag.start({
        apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginReact()],
        appVersion: isProduction ? packageJson.version : 'staging',
        appType: 'browser',
        redactedKeys: [
            'token',
            'password',
        ],
    });
}

const container = document.getElementById('app-root') as Element;
const root = createRoot(container);

const helmetContext = {};

root.render(
    <StoreProvider store={store}>
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY || ''}
            scriptProps={{ defer: true }}
        >
            <BrowserRouter>
                <CookieProvider>
                    <LocaleProvider>
                        <HelmetProvider context={helmetContext}>
                            <Helmet>
                                <link rel="stylesheet" href="https://use.typekit.net/ylw8azo.css" />
                            </Helmet>
                            <App />
                        </HelmetProvider>
                    </LocaleProvider>
                </CookieProvider>
            </BrowserRouter>
        </GoogleReCaptchaProvider>
    </StoreProvider>,
);

// Temporary overwrite to block service worker registration
const enableServiceWorker = false;

// Service worker registration
if (isProduction && enableServiceWorker) {
    registerServiceWorker().catch(error => {
        console.error('Service worker registration failed:', error);
    });
}
