import { generateUuid } from '../../helpers/string';
import { AddressType, CustomerAddress } from '../Address/Address';
import { AddressFormData, AddressResource, CustomerResource } from '../Api/Webshop';
import { Customer } from './Customer';

export const transformCustomerAddressToAddressFormData = (address: CustomerAddress, customer?: Customer): AddressFormData => ({
    countryCode: address.country,
    street: address.street,
    city: address.city,
    number: address.houseNumber,
    postcode: address.postalCode,
    addition: address.addition,
    firstName: customer?.firstName,
    lastName: customer?.lastName,
    phoneNumber: customer?.phoneNumber,
});

export const transformToAddress = (resource: AddressResource, type?: AddressType): CustomerAddress => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const addition = resource.addition
        ? resource.addition
        : undefined;

    return ({
        id,
        type: type || AddressType.billing,
        street: resource.street,
        postalCode: resource.postcode,
        houseNumber: resource.number,
        addition,
        city: resource.city,
        country: resource.countryCode,
    });
};

export const transformToCustomer = (resource: CustomerResource, id: string, customerAddresses?: CustomerAddress[]): Customer => {
    const addresses = customerAddresses || [];
    const company = undefined; // TODO: Add company (waiting to be added to API)

    return ({
        id,
        addresses,
        firstName: resource.firstName || '',
        insertion: '',
        lastName: resource.lastName || '',
        email: resource.email,
        birthday: resource.birthday || undefined,
        phoneNumber: resource.phoneNumber || '',
        company,
    });
};
