import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Index } from 'react-instantsearch';
import { useParams } from 'react-router-dom';

import { Page } from '../../components';
import { ConnectedBrandRenderer, ConnectedNewsletterCallToAction, ConnectedProductList } from '../../connectors';
import { MeilisearchAttributes } from '../../entities/Api/Webshop';
import { MeilisearchIndex } from '../../entities/Meilisearch/Meilisearch';
import { generateFilterQuery } from '../../entities/Meilisearch/MeilisearchRequests';
import { useTrans } from '../../hooks';
import { useTypedSelector } from '../../redux/store';
import { NotFoundPage } from '../NotFoundPage/NotFoundPage';

import './BrandDetailPage.scss';

interface BrandDetailProps {
    className?: string;
}

export const BrandDetailPage: FC<BrandDetailProps> = ({
    className = '',
}): ReactElement => {
    const { slug = '' } = useParams();

    const trans = useTrans();

    // TODO: Should be done from within a connector
    const ssrBrandQuery = useTypedSelector(state => state.brandSlice.ssrBrandQuery);

    const [productsQuery, setProductsQuery] = useState<string>('');

    useEffect(() => {
        if (!slug) {
            return;
        }

        const query = generateFilterQuery(MeilisearchAttributes.brand, slug);

        setProductsQuery(query);
    }, [slug]);

    if (!slug) {
        return (
            <NotFoundPage />
        );
    }

    return (
        <Page className={`brand-detail-page ${className}`}>
            <ConnectedBrandRenderer slug={slug} />

            <Index indexName={MeilisearchIndex.variantsNl}>
                <ConnectedProductList
                    showFilterBar
                    title={trans('pages.brands.shopProducts')}
                    filterQuery={ssrBrandQuery || productsQuery}
                    className="brand-detail-page__block"
                />
            </Index>

            <ConnectedNewsletterCallToAction
                title="Wekelijks inspiratie in je inbox?"
                description="Meld je aan voor de Loods 5 nieuwsbrief en ontvang inspiratie, persoonlijke acties en korting!"
                textColor="#000000"
                className="brand-detail-page__block"
            />
        </Page>
    );
};
