import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import { Icon } from '../../../../components';
import { Button } from '../../../../compositions';
import { NavigationItem } from '../../../../entities/Navigation/Navigation';
import { NavLinkClassName } from '../../../../types';

import './NavigationLink.scss';

interface NavigationLinkProps {
    showActiveState?: boolean;
    hasLowerCaseTypeface?: boolean;
    isExpanded?: boolean;
    isAccountNavigation?: boolean;
    isSale?: boolean;
    item: NavigationItem;
    onButtonClick?: (item: NavigationItem) => void;
    onLinkClick?: () => void;
    className?: string;
}

export const NavigationLink: FC<NavigationLinkProps> = ({
    showActiveState,
    hasLowerCaseTypeface,
    isExpanded,
    isAccountNavigation,
    isSale,
    item,
    onButtonClick,
    onLinkClick,
    className = '',
}): ReactElement => {
    const { pathname } = useLocation();

    const handleButtonClick = (): void => {
        if (onButtonClick) {
            onButtonClick(item);
        }
    };

    const navigationLinkClassNames = classNames('navigation-link', {
        'navigation-link--is-lower-case': hasLowerCaseTypeface,
        'navigation-link--is-expanded': isExpanded,
        'navigation-link--is-active': showActiveState && pathname.startsWith(item.to.toString()),
        'navigation-link--is-sale': isSale,
        'navigation-link--is-account': isAccountNavigation,
    }, className);

    const navigationLinkLinkClassNames: NavLinkClassName = ({ isActive }): string => classNames('navigation-link__link', {
        'navigation-link__link--is-active': showActiveState && isActive,
    });

    return (
        <li className={navigationLinkClassNames}>
            {item.subNavigation && (
                <Button
                    text={item.label}
                    onClick={handleButtonClick}
                    className="navigation-link__button"
                    labelClassName="navigation-link__label-wrapper"
                >
                    {item.icon && (
                        <Icon name={item.icon} className="navigation-link__icon" />
                    )}

                    <span className="navigation-link__label">
                        {item.label}

                        {item.subNavigation && (
                            <Icon name="chevron-right" className="navigation-link__chevron" />
                        )}
                    </span>
                </Button>
            )}

            {!item.subNavigation && (
                <NavLink
                    to={item.to}
                    onClick={onLinkClick}
                    className={navigationLinkLinkClassNames}
                >
                    {!hasLowerCaseTypeface && item.icon && (
                        <Icon name={item.icon} className="navigation-link__icon" />
                    )}

                    <span className="navigation-link__label">
                        {item.label}

                        {!hasLowerCaseTypeface && (
                            <Icon name="chevron-right" className="navigation-link__chevron" />
                        )}
                    </span>
                </NavLink>
            )}
        </li>
    );
};
