import { FC, ReactElement } from 'react';

import { Cart } from '../../containers';
import { clearCart, removeProductVariantFromCart, updateProductVariantInCart } from '../../redux/cart/cartActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { addProductVariantToWishlist, removeProductVariantFromWishlist } from '../../redux/wishlist/wishlistActions';

export const ConnectedCart: FC = (): ReactElement => {
    const dispatch = useTypedDispatch();

    const cart = useTypedSelector(state => state.cartSlice.cart);
    const isLoading = useTypedSelector(state => state.cartSlice.isLoading);

    const wishlist = useTypedSelector(state => state.wishlistSlice.wishlist);

    const handleUpdateCartItemQuantity = (id: string, quantity: number): void => {
        dispatch(updateProductVariantInCart(id, quantity));
    };

    const handleRemoveItemFromCart = (id: string): void => {
        dispatch(removeProductVariantFromCart(id));
    };

    const handleCartItemIsInWishlist = async (productVariantId: string, isInWishlist: boolean): Promise<void> => {
        if (isInWishlist) {
            await dispatch(removeProductVariantFromWishlist(productVariantId));
            return;
        }

        await dispatch(addProductVariantToWishlist(productVariantId));
    };

    const handleClearCart = (): void => {
        dispatch(clearCart());
    };

    return (
        <Cart
            {...cart}
            isLoading={isLoading}
            wishlistItems={wishlist.productVariantIds}
            onRemoveItemFromCart={handleRemoveItemFromCart}
            onUpdateCartItem={handleUpdateCartItemQuantity}
            onUpdateCartItemIsInWishlist={handleCartItemIsInWishlist}
            onClearCart={handleClearCart}
        />
    );
};
