import { FC, ReactElement } from 'react';

import { LinkIconButton } from '../../../compositions';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { Store } from '../../../entities/Store/Store';
import { getStoreOpeningStatus } from '../../../helpers/storeDayOpeningStatus';
import { replaceUrlParamKeysWithValues } from '../../../helpers/url';
import { useTrans } from '../../../hooks';

import './StoreList.scss';

interface StoreListProps {
    stores: Store[];
    className?: string;
}

export const StoreList: FC<StoreListProps> = ({
    stores,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const storePath = trans(appRoutes[AppRoute.storeDetail].path);

    return (
        <div className={`store-list ${className}`}>
            {stores.map(store => {
                const storeUrl = replaceUrlParamKeysWithValues(storePath, {
                    slug: store.slug,
                });

                return (
                    <div key={store.title} className="store-list__item">
                        <h3 className="store-list__title">
                            {store.title}
                        </h3>

                        <p className="store-list__address">
                            {store.address.street}
                            {' '}
                            {store.address.houseNumber}
                            ,
                            {' '}
                            {store.address.postalCode}
                            {' '}
                            {store.address.city}
                        </p>

                        <div className="store-list__opening-hours">
                            <p className="top-navbar__shop-opening-hours">
                                {getStoreOpeningStatus(
                                    store.openingHours,
                                    store.adjustedOpeningHours,
                                )}
                            </p>
                        </div>

                        <LinkIconButton
                            isSmall
                            hasAnimation
                            to={storeUrl}
                            iconPos={HorizontalAlignment.right}
                            icon="arrow-right"
                            text={trans('common.moreInfo')}
                            className="store-list__link-button"
                        />
                    </div>
                );
            })}
        </div>
    );
};
