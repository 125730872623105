import { FC, ReactElement, useState } from 'react';

import { AddressCard, Button, SortInput } from '../../../../compositions';
import { AddressType, CustomerAddress } from '../../../../entities/Address/Address';
import { ShippingMethod } from '../../../../entities/Cart/Cart';
import { useTrans } from '../../../../hooks';

import './ShipmentStep.scss';

interface ShipmentStepProps {
    addresses: CustomerAddress[];
    shippingMethods: ShippingMethod[];
    onChooseShippingMethod: (shipmentMethod: string) => void;
    className?: string;
}

export const ShipmentStep: FC<ShipmentStepProps> = ({
    addresses,
    shippingMethods,
    onChooseShippingMethod,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [shippingMethod, setShippingMethod] = useState<string>('');

    const handleContinueButtonClick = (): void => {
        onChooseShippingMethod(shippingMethod);
    };

    const shippingAddress = addresses.find(address => address.type === AddressType.shipping);
    const otherAddresses = addresses.filter(address => address.type !== AddressType.shipping);

    // TODO: Let customer choose which address to use for shipping
    // TODO: Let customer edit customer addresses

    return (
        <div className={`shipment-step ${className}`}>
            <h2 className="shipment-step__heading">
                {trans('containers.checkout.shipmentStep.heading')}
            </h2>

            <div className="shipment-step__addresses">
                <p className="shipment-step__addresses-heading">
                    {trans('containers.checkout.shipmentStep.shippingAddress')}
                </p>

                {shippingAddress && (
                    <AddressCard
                        address={shippingAddress}
                        onClickEditButton={console.log}
                        onChangeShippingAddress={console.log}
                        className="shipment-step__address-card"
                    />
                )}

                {otherAddresses.map(address => (
                    <AddressCard
                        address={address}
                        onClickEditButton={console.log}
                        onChangeShippingAddress={console.log}
                        className="shipment-step__address-card"
                    />
                ))}
            </div>

            <div className="shipment-step__shipping-methods">
                <p className="shipment-step__addresses-heading">
                    {trans('containers.checkout.shipmentStep.shippingCosts')}
                </p>

                <SortInput
                    hideLabel
                    name="shipment-methods"
                    options={shippingMethods}
                    label={trans('containers.checkout.shipmentStep.chooseShipmentMethod')}
                    placeholder={trans('containers.checkout.shipmentStep.chooseShipmentMethod')}
                    onChange={setShippingMethod}
                />
            </div>

            <Button
                isSmall
                text={trans('common.continue')}
                disabled={!shippingMethod}
                onClick={handleContinueButtonClick}
                className="shipment-step__button"
            />
        </div>
    );
};
