import { generateUuid } from '../../helpers/string';
import { MOCK_IMAGE_7 } from '../../mock/mock-data';
import { Block, BlockResource } from '../Block/Block';
import { DateResource } from '../Date/Date';
import { Image, MediaResource } from '../Media/Media';
import { ProductListCallToActionInterface, ProductListCallToActionResource } from '../ProductListCallToAction/ProductListCallToAction';
import { SeoResource } from '../Seo/Seo';
import { StrapiEntity, StrapiEntityData } from '../Strapi/Strapi';

export interface ProductCategoryResource extends StrapiEntityData {
    id: number;
    title: string;
    slug: string;
    description: string;
    sylius_id: number;
    image?: MediaResource;
    callToAction?: ProductListCallToActionResource[];
    elements: BlockResource[];
    seo?: SeoResource;
    createdAt: DateResource;
    publishedAt: DateResource;
    updatedAt: DateResource;
}

export type ProductCategoryResponse = StrapiEntity<ProductCategoryResource[]>;

export interface ProductCategory {
    id: string;
    slug: string;
    name: string;
    children?: ProductCategory[];
}

export interface ProductCategoryPageData {
    id: string;
    slug: string;
    title: string;
    image: Image;
    callToActions: ProductListCallToActionInterface[];
    blocks: Block[];
}

enum ProductCategoryHierarchy {
    slug = 'slug',
    subCategory = 'subCategory',
    subSubCategory = 'subSubCategory',
}

export type ProductCategoryParams = Record<ProductCategoryHierarchy, string | undefined>;

export const defaultCategoryPageData = (): ProductCategoryPageData => ({
    id: generateUuid(),
    slug: '',
    title: '',
    image: MOCK_IMAGE_7, // TODO: Use different image
    callToActions: [],
    blocks: [],
});
