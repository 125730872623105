import { generateUuid } from '../../../helpers/string';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { UrgentBannerInterface, UrgentBannerResource } from './UrgentBanner';

export const transformToUrgentBanner = (resource: UrgentBannerResource): UrgentBannerInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const bannerInfo = resource.urgentBannerInfo
        ? resource.urgentBannerInfo[0]
        : undefined;

    const text = bannerInfo?.text
        ? bannerInfo.text
            .map(paragraph => paragraph.children?.map(child => child.text).join(''))
            .join('\n')
        : undefined;

    return {
        ...defaultBlock,
        id: bannerInfo?.id || generateUuid(),
        text,
        links: bannerInfo?.links || [],
        backgroundColor: bannerInfo?.backgroundColor || '',
        textColor: bannerInfo?.textColor || '',
    };
};
