import {
    FC,
    FormEvent,
    ReactElement,
    useRef,
    useState,
} from 'react';

import { Wrapper } from '../../../components';
import {
    Button,
    Checkbox,
    SelectInput,
    TextInput,
} from '../../../compositions';
import { FormOption, FormProps } from '../../../entities/@forms/Form/Form';
import { ParticipateFormData } from '../../../entities/@forms/ParticipateFormData/ParticipateFormData';
import { Country, countryOptions } from '../../../entities/Country';
import { scrollIntoView } from '../../../helpers/scroll';
import { useTrans } from '../../../hooks';
import { DialogWithButton } from '../../DialogWithButton/DialogWithButton';
import { ParticipateFormErrors, validateParticipateFormData } from './validations';

import './ParticipateForm.scss';

interface ParticipateFormProps extends FormProps<ParticipateFormData> {
    termsAndConditions?: string;
    className?: string;
}

export const ParticipateForm: FC<ParticipateFormProps> = ({
    isLoading,
    onSubmit,
    termsAndConditions,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const formRef = useRef<HTMLFormElement>(null);
    const [formErrors, setFormErrors] = useState<ParticipateFormErrors>({});

    const defaultCountry = countryOptions.find(item => item.value === Country.nl) || countryOptions[0];

    const [companyName, setCompanyName] = useState<string>('');
    const [postalCode, setPostalCode] = useState<string>('');
    const [houseNumber, setHouseNumber] = useState<string>('');
    const [addition, setAddition] = useState<string>('');
    const [street, setStreet] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [country, setCountry] = useState<FormOption>(defaultCountry);

    const [kvk, setKvk] = useState('');

    const [firstName, setFirstName] = useState<string>('');
    const [insertion, setInsertion] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const [website, setWebsite] = useState<string>('');
    const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState<boolean>(false);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        const formData: ParticipateFormData = {
            companyName,
            postalCode,
            houseNumber,
            addition,
            street,
            city,
            country: country.value,
            kvk,
            firstName,
            insertion,
            lastName,
            email,
            phoneNumber,
            website,
            acceptTermsAndConditions,
        };

        const [errors, hasErrors] = validateParticipateFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    const handleCountryDropDown = (value: string): void => {
        const correctCountry = countryOptions.find(item => item.value === value) as FormOption;
        setCountry(correctCountry);
    };

    return (
        <Wrapper className={`participate-form ${className}`}>
            <form
                ref={formRef}
                onSubmit={handleSubmit}
                className="participate-form__form"
            >
                <p className="participate-form__title">
                    {trans('containers.participateForm.title')}
                </p>

                <div className="participate-form__group">
                    <TextInput
                        label={trans('form.companyName')}
                        value={companyName}
                        error={formErrors.companyName}
                        disabled={isLoading}
                        onChange={setCompanyName}
                        className="participate-form__item"
                    />

                    <div className="participate-form__row">
                        <TextInput
                            label={trans('form.postalCode')}
                            value={postalCode}
                            error={formErrors.postalCode}
                            disabled={isLoading}
                            onChange={setPostalCode}
                            className="participate-form__item"
                        />

                        <TextInput
                            label={trans('form.houseNumber')}
                            value={houseNumber}
                            error={formErrors.houseNumber}
                            disabled={isLoading}
                            onChange={setHouseNumber}
                            className="participate-form__item"
                        />

                        <TextInput
                            label={trans('form.addition')}
                            value={addition}
                            error={formErrors.addition}
                            disabled={isLoading}
                            onChange={setAddition}
                            className="participate-form__item"
                        />
                    </div>

                    <div className="participate-form__row participate-form__row--two">
                        <TextInput
                            label={trans('form.street')}
                            value={street}
                            error={formErrors.street}
                            disabled={isLoading}
                            onChange={setStreet}
                            className="participate-form__item"
                        />

                        <TextInput
                            label={trans('form.city')}
                            value={city}
                            error={formErrors.city}
                            disabled={isLoading}
                            onChange={setCity}
                            className="participate-form__item"
                        />
                    </div>

                    <SelectInput
                        name="countrySelect"
                        options={countryOptions}
                        disabled={isLoading}
                        label={trans('form.country')}
                        placeholder={country.label}
                        value={country}
                        onChange={handleCountryDropDown}
                        className="participate-form__item"
                    />
                </div>

                <div className="participate-form__group">
                    <TextInput
                        label={trans('form.kvk')}
                        value={kvk}
                        error={formErrors.kvk}
                        disabled={isLoading}
                        onChange={setKvk}
                        className="participate-form__item"
                    />
                </div>

                <div className="participate-form__group">
                    <div className="participate-form__row">
                        <TextInput
                            label={trans('form.firstName')}
                            value={firstName}
                            error={formErrors.firstName}
                            disabled={isLoading}
                            onChange={setFirstName}
                            className="participate-form__item"
                        />

                        <TextInput
                            label={trans('form.insertion')}
                            value={insertion}
                            error={formErrors.insertion}
                            disabled={isLoading}
                            onChange={setInsertion}
                            className="participate-form__item"
                        />

                        <TextInput
                            label={trans('form.lastName')}
                            value={lastName}
                            error={formErrors.lastName}
                            disabled={isLoading}
                            onChange={setLastName}
                            className="participate-form__item"
                        />
                    </div>

                    <TextInput
                        label={trans('form.email')}
                        value={email}
                        error={formErrors.email}
                        disabled={isLoading}
                        onChange={setEmail}
                        className="participate-form__item"
                    />

                    <TextInput
                        label={trans('form.phoneNumber')}
                        value={phoneNumber}
                        error={formErrors.phoneNumber}
                        disabled={isLoading}
                        onChange={setPhoneNumber}
                        className="participate-form__item"
                    />
                </div>

                <TextInput
                    label={trans('form.website')}
                    value={website}
                    error={formErrors.website}
                    disabled={isLoading}
                    onChange={setWebsite}
                    className="participate-form__item"
                />

                <Checkbox
                    label={trans('form.acceptTermsAndConditions')}
                    error={formErrors.acceptTermsAndConditions}
                    checked={acceptTermsAndConditions}
                    name="acceptTermsAndConditions"
                    disabled={isLoading}
                    onChange={setAcceptTermsAndConditions}
                    className="participate-form__item"
                />

                {termsAndConditions && (
                    <DialogWithButton
                        text={termsAndConditions}
                        buttonText={trans('containers.participateForm.readTheTermsAndConditions')}
                        title={trans('containers.participateForm.termsAndConditions')}
                    />
                )}

                <Button
                    type="submit"
                    text={trans('containers.participateForm.sendRequest')}
                    className="participate-form__button"
                />
            </form>
        </Wrapper>
    );
};

