import {
    FormErrors,
    FormValidation,
    validateEmailAddress,
    validateForm,
    validateRequiredBoolean,
    validateRequiredString,
    validateUrl,
} from '../../../../entities/@forms/FormValidation/FormValidationService';
import { ParticipateFormData } from '../../../../entities/@forms/ParticipateFormData/ParticipateFormData';

export type ParticipateFormErrors = FormErrors<ParticipateFormData>;

export const validateParticipateFormData = (formData: ParticipateFormData): FormValidation<ParticipateFormErrors> => {
    const errors: ParticipateFormErrors = {
        companyName: validateRequiredString('companyName', formData.companyName),
        postalCode: validateRequiredString('postalCode', formData.postalCode),
        houseNumber: validateRequiredString('houseNumber', formData.houseNumber),
        street: validateRequiredString('street', formData.street),
        city: validateRequiredString('city', formData.city),
        country: validateRequiredString('country', formData.country),
        kvk: validateRequiredString('kvk', formData.kvk),
        firstName: validateRequiredString('firstName', formData.firstName),
        lastName: validateRequiredString('lastName', formData.lastName),
        email: validateEmailAddress(formData.email),
        phoneNumber: validateRequiredString('phoneNumber', formData.phoneNumber),
        website: formData.website ? validateUrl('website', formData.website) : undefined,
        acceptTermsAndConditions: validateRequiredBoolean('acceptTermsAndConditions', formData.acceptTermsAndConditions),
    };

    return validateForm<ParticipateFormErrors>(errors);
};
