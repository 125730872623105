import { BlogPostResource } from '../../../@blog/BlogPost/BlogPost';
import { DepartmentDetailResource } from '../../../@departments/DepartmentDetail/DepartmentDetail';
import { BrandItemResource } from '../../../Brand/Brand';
import { PageResource, PageType } from '../../../Page/Page';
import { ProductCategoryResource } from '../../../ProductCategory/ProductCategory';
import { StoreResource } from '../../../Store/Store';

export enum InstantSearchPageTypeResource {
    blog = 'blog',
    brand = 'brand',
    category = 'category',
    department = 'department',
    page = 'page',
    store = 'shop',
}

export enum InstantSearchPageType {
    blog = 'blog',
    brand = 'brand',
    category = 'category',
    department = 'department',
    page = 'page',
    store = 'store',
}

export type InstantSearchPageId = `${InstantSearchPageTypeResource}-${number}`;

export interface InstantSearchPageResource extends PageResource {
    _meilisearch_id: InstantSearchPageId;
}

export interface InstantSearchPageHitResource extends InstantSearchPageResource {
    fixedType: PageType | null;
    description: string;
}

export type InstantSearchBlogPostHitResource = InstantSearchPageResource & BlogPostResource;
export type InstantSearchBrandHitResource = InstantSearchPageResource & BrandItemResource;
export type InstantSearchDepartmentHitResource = InstantSearchPageResource & DepartmentDetailResource;
export type InstantSearchCategoryHitResource = InstantSearchPageResource & ProductCategoryResource;
export type InstantSearchStoreHitResource = InstantSearchPageResource & StoreResource;

export type InstantSearchPage = InstantSearchPageHitResource
| InstantSearchBlogPostHitResource
| InstantSearchBrandHitResource
| InstantSearchDepartmentHitResource
| InstantSearchCategoryHitResource
| InstantSearchStoreHitResource
