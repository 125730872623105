import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Checkout } from '../../containers';
import { UspItem } from '../../entities/@blocks/UspSection/UspSection';
import { FormOption } from '../../entities/@forms/Form/Form';
import { ShippingMethod } from '../../entities/Cart/Cart';
import { CheckoutTab } from '../../entities/Checkout/Checkout';
import { MOCK_CUSTOMER } from '../../mock/mock-data/customer';
import { clearCart, removeProductVariantFromCart, updateProductVariantInCart } from '../../redux/cart/cartActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { addProductVariantToWishlist, removeProductVariantFromWishlist } from '../../redux/wishlist/wishlistActions';

interface ConnectedCheckoutProps {
    tab?: CheckoutTab;
}

export const ConnectedCheckout: FC<ConnectedCheckoutProps> = ({
    tab,
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isAuthenticated = useTypedSelector(state => state.authenticationSlice.isAuthenticated);

    const isLoading = useTypedSelector(state => state.cartSlice.isLoading);
    const cart = useTypedSelector(state => state.cartSlice.cart);

    const wishlist = useTypedSelector(state => state.wishlistSlice.wishlist);

    const mockUspItems: UspItem[] = [
        { id: 0, text: 'Online besteld? Gratis verzonden', icon: 'cart-truck' },
        { id: 1, text: 'Keuze uit meer dan 300 meubelmerken', icon: 'lamp' },
        { id: 2, text: 'Niet tevreden? Geld terug!', icon: 'happy' },
    ];

    // TODO: Replace with real data from API
    const { addresses } = MOCK_CUSTOMER;

    // TODO: Replace with real data from API
    const mockPaymentMethods: FormOption[] = [
        {
            label: 'iDeal',
            value: 'ideal',
        },
        {
            label: 'Creditcard',
            value: 'creditcard',
        },
        {
            label: 'Cadeaubon',
            value: 'giftcard',
        },
    ];

    // TODO: Replace with real data from API
    const mockShipmentMethods: ShippingMethod[] = [
        {
            id: '1',
            label: 'UPS',
            value: 'ups',
            description: 'Snel en betrouwbaar',
            price: 2500,
        },
        {
            id: '2',
            label: 'DHL',
            value: 'dhl',
            description: 'Snel en betrouwbaar',
            price: 2500,
        },
        {
            id: '3',
            label: 'FedEx',
            value: 'fedex',
            description: 'Snel en betrouwbaar',
            price: 2500,
        },
    ];

    const [shipmentMethods, setShipmentMethods] = useState<ShippingMethod[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<FormOption[]>([]);

    useEffect((): void => {
        // TODO: Fetch shipping methods from API and set them in shipmentMethods
        setShipmentMethods(mockShipmentMethods);
    }, [tab]);

    useEffect((): void => {
        setPaymentMethods(mockPaymentMethods);
    }, [cart]);

    // TODO: Redirect user to the correct checkout step, based on the state of the cart
    // TODO: Add address to the order (for shipment and billing) (API)

    // TODO: if payment is successful, redirect to thank you page
    // TODO: if payment is failed, then?
    // TODO: if payment is pending, redirect to payment pending page

    const handleSelectShipment = async (value: string): Promise<void> => {
        console.log('Selected shipment method:', value);
        // TODO: Add shipment method to the order (API)
        // TODO: Navigate to payment page
    };

    const handleSelectPayment = async (value: string): Promise<void> => {
        console.log('Selected payment method:', value);
        // TODO: Add payment method to the order (API)
        // TODO: Redirect user to PAY-NL environment (url from API)
    };

    const handleUpdateCartItemQuantity = async (id: string, quantity: number): Promise<void> => {
        await dispatch(updateProductVariantInCart(id, quantity));
    };

    const handleRemoveItemFromCart = async (id: string): Promise<void> => {
        await dispatch(removeProductVariantFromCart(id));
    };

    const handleCartItemIsInWishlist = async (productVariantId: string, isInWishlist: boolean): Promise<void> => {
        if (isInWishlist) {
            await dispatch(removeProductVariantFromWishlist(productVariantId));
            return;
        }

        await dispatch(addProductVariantToWishlist(productVariantId));
    };

    const handleClearCart = async (): Promise<void> => {
        await dispatch(clearCart());
    };

    return (
        <Checkout
            {...cart}
            isLoading={isLoading}
            isAuthenticated={isAuthenticated}
            tab={tab}
            addresses={addresses}
            shippingMethods={shipmentMethods}
            paymentMethods={paymentMethods}
            wishlistItems={wishlist.productVariantIds}
            uspItems={mockUspItems}
            onChooseShippingMethod={handleSelectShipment}
            onChoosePaymentMethod={handleSelectPayment}
            onUpdateCartItem={handleUpdateCartItemQuantity}
            onRemoveItemFromCart={handleRemoveItemFromCart}
            onUpdateCartItemIsInWishlist={handleCartItemIsInWishlist}
            onClearCart={handleClearCart}
        />
    );
};
