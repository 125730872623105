import { FC, ReactElement, useMemo } from 'react';

import { IconName } from '../../components';
import { useTrans } from '../../hooks';
import { IconButton } from '../@buttons/IconButton/IconButton';

import './ShoppingCartCount.scss';

interface ShoppingCartCountProps {
    count: number;
    onClick: () => void;
    className?: string;
}

export const ShoppingCartCount: FC<ShoppingCartCountProps> = ({
    count,
    onClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const cartIcon = useMemo((): IconName => {
        if (count > 4) {
            return 'cart-wagon';
        }

        if (count > 9) {
            return 'cart-truck';
        }

        return 'cart-bag';
    }, [count]);

    return (
        <div className={`shopping-cart-count ${className}`}>
            <IconButton
                hideLabel
                icon={cartIcon}
                text={trans('common.openCart')}
                onClick={onClick}
                className="shopping-cart-count__icon-button"
                iconClassName="shopping-cart-count__icon-accent"
            />

            {count > 0 && (
                <div className="shopping-cart-count__amount">
                    {count}
                </div>
            )}
        </div>
    );
};
