import qs from 'qs';

export const generateTopNavbarQuery = (): string => {
    const populate = {
        shop: {
            populate: {
                openingHours: {
                    populate: '*',
                },
                adjustedOpeningHours: {
                    populate: '*',
                },
            },
        },
        links: {
            populate: {
                populate: '*',
            },
        },
        usps: {
            populate: {
                populate: '*',
            },
        },
    };

    return qs.stringify({ populate }, { encode: true });
};
