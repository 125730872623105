import { RouteParams } from '../../LocalizedRoutes';

export enum CheckoutTab {
    order = 'checkoutOrder',
    shipment = 'checkoutShipment',
    payment = 'checkoutPayment',
    completing = 'checkoutCompleting',
}

export interface CheckoutParams extends RouteParams {
    tab?: CheckoutTab;
}
