import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';
import { useLocation } from 'react-router-dom';

import { Wrapper } from '../../../../components';
import { Navigation, NavigationItem } from '../../../../entities/Navigation/Navigation';
import { isSSR } from '../../../../helpers';
import { useTrans } from '../../../../hooks';
import { findMatchingSubNavigation } from '../../helpers';
import { DesktopMainNavigationColumn, SsrNavigation } from '..';

import './DesktopMainNavigationDrawer.scss';

interface DesktopMainNavigationDrawerProps {
    navigationIsOpen: boolean;
    navigationLabel?: string;
    navigation: Navigation;
    className?: string;
}

export const DesktopMainNavigationDrawer: FC<DesktopMainNavigationDrawerProps> = ({
    navigationIsOpen,
    navigationLabel,
    navigation,
    className = '',
}): ReactElement => {
    const { pathname } = useLocation();
    const trans = useTrans();

    const [subNavigation, setSubNavigation] = useState<Navigation>();
    const [subSubNavigation, setSubSubNavigation] = useState<Navigation>();

    useEffect((): void => {
        if (!navigationIsOpen) {
            return;
        }

        const matchingSubNavigation = findMatchingSubNavigation(pathname, navigation);
        const matchingSubSubNavigation = matchingSubNavigation
            ? findMatchingSubNavigation(pathname, matchingSubNavigation)
            : undefined;

        setSubNavigation(matchingSubNavigation);
        setSubSubNavigation(matchingSubSubNavigation);
    }, [navigationIsOpen]);

    const handleLinkClick = (): void => {
        setSubNavigation(undefined);
        setSubSubNavigation(undefined);
    };

    const handleNavigationItemClick = (item: NavigationItem): void => {
        const activeSubNavigation = item.id !== subNavigation?.id
            ? item.subNavigation
            : undefined;

        setSubNavigation(activeSubNavigation);
        setSubSubNavigation(undefined);
    };

    const handleSubNavigationItemClick = (item: NavigationItem): void => {
        const activeSubSubNavigation = item.id !== subSubNavigation?.id
            ? item.subNavigation
            : undefined;

        setSubSubNavigation(activeSubSubNavigation);
    };

    const subNavigationColumnClassNames = classNames('desktop-main-navigation-drawer__column', {
        'desktop-main-navigation-drawer__column--is-expanded': !!subNavigation,
    });

    const subSubNavigationColumnClassNames = classNames('desktop-main-navigation-drawer__column', {
        'desktop-main-navigation-drawer__column--is-expanded': !!subSubNavigation,
    });

    if (isSSR && navigation) {
        return (
            <SsrNavigation navigation={navigation} />
        );
    }

    return (
        <nav
            // @ts-ignore
            inert={!navigationIsOpen ? 'true' : undefined}
            aria-label={navigationLabel || trans('containers.menu.mainNavigation')}
            className={`desktop-main-navigation-drawer ${className}`}
        >
            <Wrapper className="desktop-main-navigation-drawer__inner-wrapper">
                <DesktopMainNavigationColumn
                    navigation={navigation}
                    activeItemId={subNavigation?.id}
                    onLinkClick={handleLinkClick}
                    onItemClick={handleNavigationItemClick}
                    className="desktop-main-navigation-drawer__column"
                />

                <div className={subNavigationColumnClassNames}>
                    {subNavigation && (
                        <DesktopMainNavigationColumn
                            hasLowerCaseTypeface
                            navigation={subNavigation}
                            activeItemId={subSubNavigation?.id}
                            onLinkClick={handleLinkClick}
                            onItemClick={handleSubNavigationItemClick}
                        />
                    )}
                </div>

                <div className={subSubNavigationColumnClassNames}>
                    {subSubNavigation && (
                        <DesktopMainNavigationColumn
                            hasLowerCaseTypeface
                            navigation={subSubNavigation}
                            onLinkClick={handleLinkClick}
                        />
                    )}
                </div>

                <div className="desktop-main-navigation-drawer__column">
                    [image here]
                </div>
            </Wrapper>
        </nav>
    );
};
