import { BlockTypeResource } from '../Block/Block';

// TODO: Find more permanent name/fix for this populate solution
export const strapiBlockPopulates = {
    on: {
        [BlockTypeResource.blogOverview]: {
            populate: ['blogCategory', 'scheduling'],
        },
        [BlockTypeResource.brandCarousel]: {
            populate: ['brands', 'brands.logo'],
        },
        [BlockTypeResource.contentColumns]: {
            populate: ['columns', 'columns.media', 'scheduling', 'scheduling'],
        },
        [BlockTypeResource.carousel]: {
            populate: ['images', 'scheduling'],
        },
        [BlockTypeResource.form]: {
            populate: '*',
        },
        [BlockTypeResource.header]: {
            populate: ['autoplayMedia', 'autoplayMedia.media', 'scheduling'],
        },
        [BlockTypeResource.highlightedBlogPost]: {
            populate: ['blog', 'blog.image', 'scheduling'],
        },
        [BlockTypeResource.iconColumns]: {
            populate: ['columns', 'columns.icon', 'scheduling'],
        },
        [BlockTypeResource.faq]: {
            populate: ['faqs', 'scheduling'],
        },
        [BlockTypeResource.featuredProducts]: {
            populate: [
                'product_selection',
                'button',
                'button.link',
                'button.icon',
            ],
        },
        [BlockTypeResource.mediaGrid]: {
            populate: ['mediaItems', 'mediaItems.media', 'scheduling'],
        },
        [BlockTypeResource.newsletterCallToAction]: {
            populate: ['image', 'scheduling'],
        },
        [BlockTypeResource.service]: {
            populate: '*',
        },
        [BlockTypeResource.uspSection]: {
            populate: ['uniqueSellingPoint', 'uniqueSellingPoint.icon', 'scheduling'],
        },
    },
};
