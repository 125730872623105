import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { Index } from 'react-instantsearch';
import { useParams } from 'react-router-dom';

import { Page, Wrapper } from '../../components';
import {
    ConnectedProductCategoryBreadcrumbs,
    ConnectedProductCategoryRenderer,
    ConnectedProductCategoryWrapper,
    ConnectedProductList,
} from '../../connectors';
import { MeilisearchAttributes } from '../../entities/Api/Webshop';
import { MeilisearchIndex } from '../../entities/Meilisearch/Meilisearch';
import { generateFilterQuery } from '../../entities/Meilisearch/MeilisearchRequests';
import { ProductCategoryParams } from '../../entities/ProductCategory/ProductCategory';
import { useTypedSelector } from '../../redux/store';

import './ProductCategoryPage.scss';

export const ProductCategoryPage: FC = (): ReactElement => {
    const { slug = '', subCategory, subSubCategory } = useParams<ProductCategoryParams>();

    // TODO: Should be done from within a connector
    const ssrProductCategoryQuery = useTypedSelector(state => state.productCategorySlice.ssrProductCategoryQuery);
    const pageData = useTypedSelector(state => state.productCategorySlice.pageData);

    const [productsQuery, setProductsQuery] = useState<string>('');

    useEffect((): void => {
        if (!slug) {
            return;
        }

        const value = subSubCategory || subCategory || slug;
        const query = generateFilterQuery(MeilisearchAttributes.categories, value);

        setProductsQuery(query);
    }, [slug, subCategory, subSubCategory]);

    return (
        <ConnectedProductCategoryWrapper>
            <Page className="product-category-page">
                <Wrapper>
                    <ConnectedProductCategoryBreadcrumbs />
                </Wrapper>

                <ConnectedProductCategoryRenderer />

                <Index indexName={MeilisearchIndex.variantsNl}>
                    <ConnectedProductList
                        showFilterBar
                        filterQuery={ssrProductCategoryQuery || productsQuery}
                        callToActions={pageData.callToActions}
                    />
                </Index>
            </Page>
        </ConnectedProductCategoryWrapper>
    );
};
