import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { AddressType, CustomerAddress } from '../../entities/Address/Address';
import { countryOptions } from '../../entities/Country';
import { useTrans } from '../../hooks';
import { IconButton } from '../@buttons/IconButton/IconButton';

import './AddressCard.scss';

interface AddressesCardProps {
    address: CustomerAddress;
    onClickEditButton: () => void;
    onChangeShippingAddress: (addressId: string, type: AddressType) => void;
    className?: string;
    typeLabelClassName?: string;
}

export const AddressCard: FC<AddressesCardProps> = ({
    address,
    onClickEditButton,
    onChangeShippingAddress,
    className = '',
    typeLabelClassName = '',
}): ReactElement => {
    const trans = useTrans();

    const country = countryOptions.find(item => item.value === address.country.toLowerCase()) || countryOptions[0];

    const handleBillingAddressButtonClick = (): void => {
        onChangeShippingAddress(address.id, AddressType.billing);
    };

    const handleShippingAddressButtonClick = (): void => {
        onChangeShippingAddress(address.id, AddressType.shipping);
    };

    const shippingButtonIconPathClassNames = classNames('address-card__button-icon', {
        'address-card__button-icon--filled': address.type === AddressType.shipping,
    });

    const billingButtonIconPathClassNames = classNames('address-card__button-icon', {
        'address-card__button-icon--filled': address.type === AddressType.billing,
    });

    return (
        <div className={`address-card ${className}`}>
            <address className="address-card__info">
                <strong className={`address-card__type ${typeLabelClassName}`}>
                    {trans(`compositions.addressCard.${address.type}`)}
                </strong>

                {`${address.street} ${address.houseNumber} ${address.addition || ''}`}

                <br />

                {`${address.postalCode}  ${address.city.toUpperCase()}`}

                <br />

                {country.label}
            </address>

            <div className="address-card__button-wrapper">
                <IconButton
                    isSmall
                    icon="star"
                    disabled={address.type === AddressType.shipping}
                    text={trans('compositions.addressCard.shipping')}
                    onClick={handleShippingAddressButtonClick}
                    className="address-card__button"
                    labelClassName="address-card__button-label"
                    iconPathClassName={shippingButtonIconPathClassNames}
                />

                <IconButton
                    isSmall
                    icon="star"
                    disabled={address.type === AddressType.billing}
                    text={trans('compositions.addressCard.billing')}
                    onClick={handleBillingAddressButtonClick}
                    className="address-card__button"
                    labelClassName="address-card__button-label"
                    iconPathClassName={billingButtonIconPathClassNames}
                />

                <IconButton
                    isSmall
                    icon="edit"
                    text={trans('common.edit')}
                    onClick={onClickEditButton}
                    className="address-card__button"
                    labelClassName="address-card__button-label"
                    iconClassName="address-card__button-icon"
                />
            </div>
        </div>
    );
};
