import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { NavigationRequestConfig, NavigationResponse } from '../../entities/Navigation/Navigation';
import { generateNavigationQuery } from '../../entities/Navigation/NavigationRequests';
import { transformToNavigation } from '../../entities/Navigation/NavigationTransformers';
import { strapiFetch } from '../../entities/Strapi/StrapiService';
import { ReduxFetchAction } from '../defaults';
import {
    setError,
    setIsLoading,
    setIsSuccessful,
    setNavigations,
} from './navigationsSlice';

export const fetchNavigation: ReduxFetchAction<NavigationRequestConfig> = config => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generateNavigationQuery(config);

        const navigationResponse = await strapiFetch<NavigationResponse>(`/tree-menus/menu?${query}`);

        if (!isFetchResultSuccessful(navigationResponse)) {
            dispatch(setError(navigationResponse.error));
            return;
        }

        const { data: navigationData } = navigationResponse.data;

        const navigationResource = navigationData
            ? navigationData[0]
            : undefined;

        const navigation = navigationResource
            ? transformToNavigation(navigationResource)
            : undefined;

        const state = getState();
        const { navigations } = state.navigationsSlice;

        if (navigation) {
            const updatedNavigations = {
                ...navigations,
                [config.key]: navigation,
            };

            dispatch(setNavigations(updatedNavigations));
            dispatch(setIsSuccessful(true));
        }
    } catch (error) {
        console.error('[fetchNavigation]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
