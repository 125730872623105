import qs from 'qs';

import { NavigationRequestConfig } from './Navigation';

export const generateNavigationQuery = (config: NavigationRequestConfig): string => {
    const filters = {
        slug: {
            $eq: config.slug,
        },
    };

    return qs.stringify({
        filters,
    }, {
        encodeValuesOnly: true,
    });
};
