import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { BlockRenderer } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { fetchDepartmentDetail } from '../../../redux/@departments/departmentDetail/departmentDetailActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedDepartmentDetailRendererProps {
    className?: string;
}

export const ConnectedDepartmentDetailRenderer: FC<ConnectedDepartmentDetailRendererProps> = ({
    className = '',
}): ReactElement => {
    const { slug = '' } = useParams();

    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.departmentDetailSlice.isLoading);
    const departmentDetail = useTypedSelector(state => state.departmentDetailSlice.departmentDetail);

    useClientEffect((): void => {
        dispatch(fetchDepartmentDetail(slug));
    }, [slug]);

    return (
        <BlockRenderer
            isLoading={isLoading}
            blocks={departmentDetail?.blocks || []}
            className={className}
        />
    );
};
