import { ProductAttributeKey, ProductFixedAttributeKey } from '../Api/Webshop';
import { ProductDefault } from './ProductDefault';
import { ProductVariant } from './ProductVariant';

type ProductBase = 'id' | 'slug' | 'name';

export interface Product extends Omit<ProductDefault, ProductBase>, Omit<ProductVariant, ProductBase> {
    id: string;
    slug: string;
    name: string;
}

export interface ProductFixedAttribute {
    key: ProductFixedAttributeKey;
    label: string;
    value: string;
}

export interface ProductAttribute {
    key: ProductAttributeKey;
    label: string;
    value: string;
}

export enum ProductSeoAvailability {
    backOrder = 'https://schema.org/BackOrder',
    discontinued = 'https://schema.org/Discontinued',
    inStock = 'https://schema.org/InStock',
    inStoreOnly = 'https://schema.org/InStoreOnly',
    limitedAvailability = 'https://schema.org/LimitedAvailability',
    madeToOrder = 'https://schema.org/MadeToOrder',
    onlineOnly = 'https://schema.org/OnlineOnly',
    outOfStock = 'https://schema.org/OutOfStock',
    preOrder = 'https://schema.org/PreOrder',
    preSale = 'https://schema.org/PreSale',
    reserved = 'https://schema.org/Reserved',
    soldOut = 'https://schema.org/SoldOut',
}

export enum ProductSeoCondition {
    new = 'https://schema.org/NewCondition',
    damaged = 'https://schema.org/DamagedCondition',
    refurbished = 'https://schema.org/RefurbishedCondition',
    used = 'https://schema.org/UsedCondition',
}

export const productVariantSeparator = '--';
