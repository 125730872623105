import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';
import { generateIdArray } from '../../../../helpers/array';

import './BreadcrumbsSkeletons.scss';

interface BreadcrumbsSkeletonsProps {
    numberOfSkeletons?: number;
    className?: string;
}

export const BreadcrumbsSkeletons: FC<BreadcrumbsSkeletonsProps> = ({
    numberOfSkeletons = 1,
    className = '',
}): ReactElement => {
    const breadcrumbsSkeletons = generateIdArray(numberOfSkeletons);

    return (
        <ul className={`breadcrumbs-skeletons ${className}`}>
            {breadcrumbsSkeletons.map(skeleton => (
                <li key={skeleton} className="breadcrumbs-skeletons__list-item">
                    <Skeleton />
                </li>
            ))}
        </ul>
    );
};
