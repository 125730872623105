import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useSearchParams } from 'react-router-dom';

import { FaqCategories, FaqItemInterface } from '../../entities/@blocks/Faq/Faq';
import { serviceFaqSearchParam } from '../../entities/@forms/ServiceForm/ServiceForm';
import { normalizeString } from '../../helpers/string';
import { useTrans } from '../../hooks';
import { Accordion, TextInput } from '..';
import { FaqItem } from './subcomponents';

import './FaqList.scss';

interface FaqListProps {
    showAllFaq?: boolean;
    faqList: FaqItemInterface[];
    faqCategories: FaqCategories;
    className?: string;
}

export const FaqList: FC<FaqListProps> = ({
    showAllFaq,
    faqList,
    faqCategories,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const [searchParams] = useSearchParams();

    const allFaqList = useMemo((): FaqItemInterface[] => (
        Object.values(faqCategories).flat()
    ), [faqCategories]);

    const initialFaqItems = showAllFaq ? allFaqList : faqList;

    const [query, setQuery] = useState<string>('');
    const [searchResults, setSearchResults] = useState<FaqItemInterface[]>(initialFaqItems);

    const queryLengthThreshold = 2;
    const queryExceedsThreshold = query.length >= queryLengthThreshold;

    useEffect((): void => {
        const searchQuery = searchParams.get(serviceFaqSearchParam);

        if (searchQuery) {
            setQuery(searchQuery);
        }
    }, [searchParams]);

    useEffect((): void => {
        if (!queryExceedsThreshold) {
            setSearchResults(initialFaqItems);
            return;
        }

        const searchTerms = query.trim().split(' ');
        let filteredItems = allFaqList;

        searchTerms.forEach(term => {
            const normalizedTerm = normalizeString(term.toLowerCase());

            filteredItems = filteredItems.filter(item => {
                const question = normalizeString(item.question.toLowerCase());
                const answer = normalizeString(item.answer.toLowerCase());

                return question.includes(normalizedTerm) || answer.includes(normalizedTerm);
            });
        });

        setSearchResults(filteredItems);
    }, [allFaqList, query]);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    return (
        <div className={`faq-list ${className}`}>
            <form enterKeyHint="search" onSubmit={handleSubmit}>
                <TextInput
                    hideLabel
                    hasUnderline
                    type="search"
                    icon="search"
                    label={trans('compositions.faqList.searchLabel')}
                    placeholder={trans('compositions.faqList.searchLabel')}
                    value={query}
                    onChange={setQuery}
                    className="faq-list__search-input"
                    labelClassName="faq-list__search-input-label"
                    fieldClassName="faq-list__search-input-field"
                />
            </form>

            {searchResults.length === 0 && (
                <p className="faq-list__no-results">
                    {trans('compositions.faqList.noResults')}
                </p>
            )}

            {(!showAllFaq || queryExceedsThreshold) && searchResults.length > 0 && (
                <ul className="faq-list__list">
                    {searchResults.map(item => (
                        <FaqItem
                            {...item}
                            key={item.question}
                            className="faq-list__item"
                        />
                    ))}
                </ul>
            )}

            {showAllFaq && !queryExceedsThreshold && searchResults.length > 0 && (
                <ul className="faq-list__category-list">
                    {Object.entries(faqCategories).map(([category, faqItems]) => (
                        <li key={category} className="faq-list__category">
                            <Accordion
                                hasBorder
                                header={trans(`compositions.faqList.categories.${category}`)}
                                className="faq-list__category"
                                headingClassName="faq-list__category-summary"
                                contentClassName="faq-list__category-content"
                            >
                                <ul className="faq-list__list">
                                    {faqItems.map(item => (
                                        <FaqItem
                                            {...item}
                                            key={item.question}
                                            className="faq-list__category-item"
                                            accordionClassName="faq-list__category-item-accordion"
                                        />
                                    ))}
                                </ul>
                            </Accordion>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};
