import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type SearchState = AsyncReduxState<{
    ssrQuery: string;
}>;

const initialState: SearchState = {
    ...initialAsyncReduxState,
    ssrQuery: '',
};

const searchSlice = createSlice({
    name: 'searchSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): SearchState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): SearchState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): SearchState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): SearchState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSsrQuery(state, action: PayloadAction<string>): SearchState {
            return {
                ...state,
                ssrQuery: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setSsrQuery,
} = searchSlice.actions;

export default searchSlice;
