import { generateUuid, transformToSlug } from '../../helpers/string';
import { MOCK_PRODUCT_IMAGE_1 } from '../../mock/mock-data';
import { Order, OrderItem } from '../Api/Webshop';
import { productVariantSeparator } from '../Product/Product';
import { transformToVariantCode } from '../Product/ProductTransformers';
import { CartCheckoutState, CartInterface, CartItem } from './Cart';

export const transformToCartItem = (resource: OrderItem): CartItem => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const variantCode = transformToVariantCode(resource.variant);

    const formattedName = resource.productName
        ? transformToSlug(resource.productName)
        : '';

    const slug = formattedName + productVariantSeparator + variantCode;

    return {
        id,
        price: resource.originalUnitPrice ?? 0,
        variantId: resource.variant,
        variantCode,
        slug,
        name: resource.productName ?? '',
        mainImage: MOCK_PRODUCT_IMAGE_1, // TODO: Replace with actual image
        quantity: resource.quantity,
        totalPrice: resource.total,
    };
};

export const transformToCart = (resource: Order): CartInterface => {
    const items = resource.items
        ? resource.items.map(transformToCartItem)
        : undefined;

    const checkoutState = CartCheckoutState.cart; // TODO: Replace with actual checkout state (API)

    return {
        id: resource.tokenValue || generateUuid(),
        checkoutState,
        items: items ?? [],
        shippingMethods: [],
        paymentMethods: [],
        subTotalPrice: resource.itemsTotal,
        shippingPrice: resource.shippingTotal,
        totalPrice: resource.total,
    };
};
