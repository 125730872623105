import { FaqItemInterface, FaqItemsResponse } from '../../entities/@blocks/Faq/Faq';
import { sortFaqItemsByCategory } from '../../entities/@blocks/Faq/FaqSorters';
import { transformToFaqCategories, transformToFaqItem } from '../../entities/@blocks/Faq/FaqTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { strapiFetch } from '../../entities/Strapi/StrapiService';
import { ReduxFetchAction } from '../defaults';
import {
    setCategories,
    setError,
    setFaqItems,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
} from './faqSlice';

export const fetchFaqCategories: ReduxFetchAction = () => async dispatch => {
    dispatch(setFaqItems([]));
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const faqItemsResponse = await strapiFetch<FaqItemsResponse>('/faqs');

        if (!isFetchResultSuccessful(faqItemsResponse)) {
            dispatch(setError(faqItemsResponse.error));
            return;
        }

        const { data: faqItemsData } = faqItemsResponse.data;

        const faqItems: FaqItemInterface[] = faqItemsData
            ? faqItemsData.map(transformToFaqItem)
            : [];

        const sortedFaqItems = faqItems.sort(sortFaqItemsByCategory);
        const categories = transformToFaqCategories(sortedFaqItems);

        dispatch(setFaqItems(sortedFaqItems));
        dispatch(setCategories(categories));

        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchFaqCategories]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
