import { FC, ReactElement } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { Page, Wrapper } from '../../components';
import { ConnectedBlockRenderer, ConnectedCheckout } from '../../connectors';
import { CheckoutParams, CheckoutTab } from '../../entities/Checkout/Checkout';
import { AppRoute } from '../../entities/Routing/Routing';
import { useRouteByTranslatedPath } from '../../hooks';

import './CheckoutPage.scss';

interface CheckoutPageProps {
    page?: string;
}

export const CheckoutPage: FC<CheckoutPageProps> = (): ReactElement => {
    const { pathname } = useLocation();

    const { id } = useParams<CheckoutParams>();
    const routeByTranslatedPath = useRouteByTranslatedPath();

    const translatedTab = routeByTranslatedPath(pathname, { id }) as CheckoutTab;

    return (
        <Page className="checkout-page">
            <Wrapper>
                <ConnectedCheckout tab={translatedTab} />
            </Wrapper>

            <ConnectedBlockRenderer appRoute={AppRoute.checkout} />
        </Page>
    );
};
