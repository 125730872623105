import { Link, LinkTarget } from '../Link/Link';
import {
    Navigation,
    NavigationGroup,
    NavigationItem,
    NavigationItemResource,
    NavigationResource,
} from './Navigation';

const transformNavigationItemToParentLink = (resource: NavigationItemResource): Link => ({
    href: resource.url,
    label: resource.title,
    target: resource.target as LinkTarget,
});

const transformNavigationItemChildrenToNavigation = (resource: NavigationItemResource): Navigation => {
    const parent = {
        ...transformNavigationItemToParentLink(resource),
        id: resource.id,
    };

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const groups = transformToNavigationGroups(resource.children);

    return {
        id: resource.id,
        parent,
        groups,
    };
};

const transformToNavigationItem = (resource: NavigationItemResource): NavigationItem => {
    const subNavigation = resource.children.length > 0
        ? transformNavigationItemChildrenToNavigation(resource)
        : undefined;

    return {
        id: resource.id,
        to: resource.url,
        label: resource.title,
        subNavigation,
    };
};

const transformToNavigationGroups = (resourceItems: NavigationItemResource[]): NavigationGroup[] => {
    const indexedItems = resourceItems.map((item, index) => ({
        ...item,
        index,
    }));

    // Create array of indexes of end of groups, based on 'isProtected' boolean
    const splitIndexes = indexedItems.reduce<number[]>((accumulator, item, index) => {
        if (item.isProtected) {
            accumulator.push(index);
        }

        return accumulator;
    }, []);

    // Add final index to index array
    splitIndexes.push(indexedItems.length);

    // Keep track of index array was last split on
    let previousIndex = 0;

    // Group the items based on split indexes
    return splitIndexes.map((endIndex, index) => {
        const groupItems = indexedItems.slice(previousIndex, endIndex + 1).map(transformToNavigationItem);

        previousIndex = endIndex + 1;

        return {
            id: index.toString(),
            items: groupItems,
        };
    });
};

export const transformToNavigation = (resource: NavigationResource): Navigation => {
    const groups = transformToNavigationGroups(resource.items);

    return {
        id: resource.documentId,
        groups,
    };
};
