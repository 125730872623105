import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Wishlist } from '../../entities/Wishlist/Wishlist';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type WishlistState = AsyncReduxState<{
    wishlist: Wishlist;
    isSuccessfulAdded: boolean;
    isSuccessfulRemoved: boolean;
}>;

const initialState: WishlistState = {
    ...initialAsyncReduxState,
    wishlist: {
        id: '',
        productVariantIds: [],
    },
    isSuccessfulAdded: false,
    isSuccessfulRemoved: false,
};

const wishlistSlice = createSlice({
    name: 'wishlistSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): WishlistState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): WishlistState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): WishlistState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setWishlist(state, action: PayloadAction<Wishlist>): WishlistState {
            return {
                ...state,
                wishlist: action.payload,
            };
        },
        setIsSuccessfullyAdded(state, action: PayloadAction<boolean>): WishlistState {
            return {
                ...state,
                isSuccessfulAdded: action.payload,
            };
        },
        setIsSuccessfullyRemoved(state, action: PayloadAction<boolean>): WishlistState {
            return {
                ...state,
                isSuccessfulRemoved: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setWishlist,
    setIsSuccessfullyAdded,
    setIsSuccessfullyRemoved,
} = wishlistSlice.actions;

export default wishlistSlice;
