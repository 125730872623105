import { FC, ReactElement } from 'react';

import { Index } from 'react-instantsearch';

import { ConnectedProductList } from '../../connectors';
import { MeilisearchIndex } from '../../entities/Meilisearch/Meilisearch';
import { useTrans } from '../../hooks';

import './WishlistItems.scss';

interface WishlistItemsProps {
    productsQuery: string;
    resultsCount: number;
    className?: string;
}

export const WishlistItems: FC<WishlistItemsProps> = ({
    productsQuery,
    resultsCount,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`wishlist-items ${className}`}>
            {resultsCount === 0 && (
                <p className="wishlist-items__no-items-label">
                    {trans('containers.wishlistItems.noWishlistItem')}
                </p>
            )}

            {resultsCount > 0 && (
                <div className="wishlist-items__items">
                    <Index indexName={MeilisearchIndex.variantsNl}>
                        <ConnectedProductList filterQuery={productsQuery} />
                    </Index>
                </div>
            )}
        </div>
    );
};
