import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { IconBrokenLogo } from '../../components/Icon/set';
import { LinkIconButton } from '../../compositions';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import trans from '../../helpers/trans';

import './NotFoundPage.scss';

export const NotFoundPage: FC = (): ReactElement => (
    <Page className="not-found-page">
        <Wrapper className="not-found-page__wrapper">
            <IconBrokenLogo className="not-found-page__logo" />

            <p className="not-found-page__heading">
                {trans('pages.notFound.somethingWentWrong')}
            </p>

            <p className="not-found-page__explanation">
                {trans('pages.notFound.pageDoesNotExist')}
            </p>

            <div className="not-found-page__buttons">
                <LinkIconButton
                    hasAnimation
                    to={trans(appRoutes[AppRoute.home].path)}
                    icon="arrow-right"
                    iconPos={HorizontalAlignment.right}
                    text={trans('pages.notFound.shopping')}
                />

                <LinkIconButton
                    hasAnimation
                    to={trans(appRoutes[AppRoute.customerService].path)}
                    icon="arrow-right"
                    iconPos={HorizontalAlignment.right}
                    text={trans('pages.notFound.askAQuestion')}
                    className="not-found-page__question-button"
                />
            </div>
        </Wrapper>
    </Page>
);
