import {
    forwardRef,
    ForwardRefExoticComponent,
    ReactElement,
    Ref,
    RefAttributes,
} from 'react';

import { Tab } from '../../entities/Tab/Tab';
import { TabItem } from './subcomponents';

import './TabList.scss';

interface TabListProps extends RefAttributes<HTMLUListElement> {
    tabs: Tab[];
    activeTab: string;
    onTabSelect: (value: string) => void;
    className?: string;
    tabItemLabelClassName?: string;
}

export const TabList: ForwardRefExoticComponent<TabListProps> = forwardRef(({
    tabs,
    activeTab,
    onTabSelect,
    className = '',
    tabItemLabelClassName = '',
}, ref: Ref<HTMLUListElement>): ReactElement => (
    <ul ref={ref} className={`tab-list ${className}`}>
        {tabs.map(tab => (
            <TabItem
                {...tab}
                key={tab.value}
                isActive={tab.value === activeTab}
                onClick={onTabSelect}
                labelClassName={tabItemLabelClassName}
            />
        ))}
    </ul>
));
