import { NavLinkProps } from 'react-router-dom';

import { IconName } from '../../components';
import { DateResource } from '../Date/Date';
import { Link } from '../Link/Link';
import { StrapiEntity, StrapiEntityData } from '../Strapi/Strapi';

export interface NavigationRequestConfig {
    key: string;
    slug: string;
}

export interface NavigationItemResource {
    id: string;
    collapsed: boolean;
    isProtected: boolean;
    title: string;
    url: string;
    target: string;
    children: NavigationItemResource[];
}

export interface NavigationResource extends StrapiEntityData {
    title: string;
    slug: string;
    locale: string;
    items: NavigationItemResource[];
    createdAt: DateResource;
    publishedAt: DateResource;
    updatedAt: DateResource;
}

export type NavigationResponse = StrapiEntity<NavigationResource[]>;

export interface NavigationParent extends Link {
    id: string;
}

export interface Navigation {
    id: string;
    parent?: NavigationParent;
    groups: NavigationGroup[];
}

export interface NavigationGroup {
    id: string;
    items: NavigationItem[];
}

type NavigationLink = Pick<NavLinkProps, 'to' | 'replace' | 'caseSensitive' | 'end'>;

export interface NavigationItem extends NavigationLink {
    id: string;
    order?: number;
    icon?: IconName;
    label: string;
    subNavigation?: Navigation;
}

export enum NavigationTransitionState {
    idle = 'idle',
    goingBackwards = 'going-backwards',
    goingForwards = 'going-forwards',
}
