import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductVariant } from '../../entities/Product/ProductVariant';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type ProductVariantState = AsyncReduxState<{
    productVariant?: ProductVariant;
}>;

const initialState: ProductVariantState = {
    ...initialAsyncReduxState,
};

const productVariantSlice = createSlice({
    name: 'productVariantSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): ProductVariantState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): ProductVariantState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ProductVariantState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ProductVariantState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setProductVariant(state, action: PayloadAction<ProductVariant | undefined>): ProductVariantState {
            return {
                ...state,
                productVariant: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setHasFetched,
    setProductVariant,
} = productVariantSlice.actions;

export default productVariantSlice;
