import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Picture } from '../../../components';
import { LinkTarget } from '../../../entities/Link/Link';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { Store } from '../../../entities/Store/Store';
import { getLinkGoogleMapToStore } from '../../../helpers/linkGoogleMapToStore';
import { getStoreOpeningStatus } from '../../../helpers/storeDayOpeningStatus';
import { useTrans } from '../../../hooks';
import { BlockRenderer } from '../../BlockRenderer/BlockRenderer';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';

import './StoreDetail.scss';

interface StoreDetailProps {
    isLoading: boolean;
    store: Store;
    className?: string;
}

export const StoreDetail: FC<StoreDetailProps> = ({
    isLoading,
    store,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const firstColumn = store.openingHours
        ? store.openingHours.slice(0, 4)
        : [];

    const secondColumn = store.openingHours.slice(4)
        ? store.openingHours.slice(4)
        : [];

    const breadcrumbs = [
        {
            label: trans(appRoutes[AppRoute.storesOverview].label),
            href: trans(appRoutes[AppRoute.storesOverview].path),
        },
        { label: store.title || '', href: '' },
    ];

    const linkToGoogleMapsStore = getLinkGoogleMapToStore(store);

    return (
        <div className={`store-detail ${className}`}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />

            <div className="store-detail__header">
                {store.image && (
                    <Picture
                        {...store.image}
                        alt={store.image.alt || store.title}
                        className="store-detail__image"
                    />
                )}

                <div className="store-detail__info">
                    <h4 className="store-detail__info-title">
                        {store.title}
                    </h4>

                    {linkToGoogleMapsStore && (
                        <Link
                            to={linkToGoogleMapsStore}
                            target={LinkTarget.blank}
                            rel="noreferrer"
                        >
                            {`${store.address.street} ${store.address.houseNumber}, ${store.address.postalCode} ${store.address.city}`}
                        </Link>
                    )}

                    <h4 className="store-detail__info-is-open">
                        <p className="top-navbar__shop-opening-hours">
                            {store.openingHours && getStoreOpeningStatus(store.openingHours)}
                        </p>
                    </h4>

                    <div className="store-detail__info-opening-hours">
                        <div className="opening-hours__column">
                            {firstColumn.map(({ day, from, to }) => (
                                <div key={day}>
                                    {trans(`date.days.${day}`)}
                                    :
                                    {`${from?.substring(0, 5)} - ${to?.substring(0, 5)} ${trans('date.hour')}`}
                                </div>
                            ))}
                        </div>

                        <div className="opening-hours__column">
                            {secondColumn.map(({ day, from, to }) => (
                                <div key={day}>
                                    {trans(`date.days.${day}`)}
                                    :
                                    {`${from?.substring(0, 5)} - ${to?.substring(0, 5)} ${trans('date.hour')}`}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {store.description && (
                <div className="store-detail__description">
                    {store.description}
                </div>
            )}

            <BlockRenderer
                isLoading={isLoading}
                pageType={AppRoute.storeDetail}
                blocks={store?.blocks}
                className="store-detail__block-renderer"
            />
        </div>
    );
};
