export enum MeilisearchAttributes {
    brand = 'attributes.Brand',
    categories = 'categories',
    code = 'code',
    id = 'id',
    is_new = 'is_new',
    is_sale = 'is_sale',
    price = 'price.LOODS_5.price',
    ranking = 'ranking',
    stock = 'stock',
}
