import { Store } from '../entities/Store/Store';

export const getLinkGoogleMapToStore = (store: Store): string => {
    let linkToGoogleMapsStore = '';

    if (store.address?.geoCoords?.latitude && store.address?.geoCoords?.longitude) {
        linkToGoogleMapsStore = `https://www.google.com/maps?q=${store.address.geoCoords.latitude},${store.address.geoCoords.longitude}`;
    } else if (store.address?.street && store.address?.houseNumber && store.address?.postalCode && store.address?.city) {
        linkToGoogleMapsStore = `https://www.google.com/maps?q=${encodeURIComponent(
            `${store.address.street} ${store.address.houseNumber}, ${store.address.postalCode} ${store.address.city}`,
        )}`;
    }

    return linkToGoogleMapsStore;
};
