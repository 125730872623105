import { FC, ReactElement, useState } from 'react';

import { AddressForm, IconButton } from '../../../../compositions';
import { FormProps } from '../../../../entities/@forms/Form/Form';
import { AddressType, CustomerAddress } from '../../../../entities/Address/Address';
import { Country, countryOptions } from '../../../../entities/Country';
import { useTrans } from '../../../../hooks';

import './MyAddresses.scss';

interface MyAddressesProps extends FormProps<CustomerAddress> {
    addresses: CustomerAddress[];
    onChangeShippingAddress: (addressId: string) => void;
    className?: string;
}

export const MyAddresses: FC<MyAddressesProps> = ({
    addresses,
    onSubmit,
    onChangeShippingAddress,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [openNewAddress, setOpenNewAddress] = useState<boolean>(false);

    const handleEditButton = (): void => {
        setOpenNewAddress(true);
    };

    const defaultCountry = countryOptions.find(item => item.value === Country.nl) || countryOptions[0];

    const defaultAddress: CustomerAddress = {
        id: '',
        city: '',
        postalCode: '',
        country: defaultCountry.value,
        street: '',
        houseNumber: '',
        type: AddressType.billing,
    };

    const handleShippingAddressButtonClick = (addressId: string, type: AddressType): void => {
        const matchingAddress = addresses.find(item => item.id === addressId);

        if (!matchingAddress) {
            return;
        }

        const isBillingToShipping = matchingAddress.type === AddressType.billing && type === AddressType.shipping;
        if (isBillingToShipping) {
            onChangeShippingAddress(addressId);
            return;
        }

        const otherAddress = addresses.find(item => item.type === AddressType.billing);
        if (otherAddress) {
            onChangeShippingAddress(otherAddress.id);
        }
    };

    return (
        <div className={`my-addresses ${className}`}>
            <div className="my-addresses__header">
                <h6 className="my-addresses__heading">
                    {trans('pages.accountDashboard.myAddresses')}
                </h6>

                {addresses.length < 2 && !openNewAddress && (
                    <IconButton
                        type="submit"
                        icon="plus"
                        text={trans('containers.accountDashboardConfig.myAddresses.addNewAddress')}
                        onClick={handleEditButton}
                        className="my-addresses__edit-button"
                    />
                )}
            </div>

            {addresses && (
                <div className="my-addresses__list">
                    {addresses.map(address => (
                        <AddressForm
                            key={address.id}
                            address={address}
                            onSubmit={onSubmit}
                            onChangeShippingAddress={handleShippingAddressButtonClick}
                        />
                    ))}

                    {openNewAddress && addresses.length < 2 && (
                        <AddressForm
                            isEditModeOn
                            address={defaultAddress}
                            onSubmit={onSubmit}
                            onChangeShippingAddress={handleShippingAddressButtonClick}
                            className="my-addresses__new-address-form"
                        />
                    )}
                </div>
            )}
        </div>
    );
};
