import { FC, PropsWithChildren, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { useClientEffect } from '../../hooks';
import { NotFoundPage } from '../../pages';
import { fetchProductFromVariantSlug } from '../../redux/product/productActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

export const ConnectedProductDetailWrapper: FC<PropsWithChildren> = ({
    children,
}): ReactElement => {
    const { slug } = useParams();

    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.productSlice.hasFetched);
    const product = useTypedSelector(state => state.productSlice.product);

    useClientEffect((): void => {
        if (slug) {
            dispatch(fetchProductFromVariantSlug(slug));
        }
    }, [slug]);

    if (hasFetched && !product) {
        return (
            <NotFoundPage />
        );
    }

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{children}</>
    );
};
