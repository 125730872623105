import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';
import { ParticipateFormState } from '../../participateForm/participateFormSlice';

export type NewsletterFormState = AsyncReduxState<{
    isSent: boolean;
}>;

const initialState: NewsletterFormState = {
    ...initialAsyncReduxState,
    isSent: false,
};

const newsletterFormSlice = createSlice({
    name: 'newsletterFormSlice',
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>): NewsletterFormState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): NewsletterFormState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): NewsletterFormState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsSent(state, action: PayloadAction<boolean>): ParticipateFormState {
            return {
                ...state,
                isSent: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setIsSuccessful,
    setError,
    setIsSent,
} = newsletterFormSlice.actions;

export default newsletterFormSlice;
