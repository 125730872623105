import { FC, ReactElement } from 'react';

import { UrgentBanner } from '../../containers';
import { useClientEffect } from '../../hooks';
import { fetchUrgentBanner } from '../../redux/@blocks/urgentBanner/urgentBannerActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

export const ConnectedUrgentBanner: FC = (): ReactElement | null => {
    const dispatch = useTypedDispatch();

    const urgentBanner = useTypedSelector(state => state.urgentBannerSlice.urgentBanner);

    useClientEffect((): void => {
        dispatch(fetchUrgentBanner());
    }, []);

    if (!urgentBanner) {
        return null;
    }

    return (
        <UrgentBanner
            id={urgentBanner.id}
            text={urgentBanner.text}
            links={urgentBanner.links}
            backgroundColor={urgentBanner.backgroundColor}
            textColor={urgentBanner.textColor}
        />
    );
};
