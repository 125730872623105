import { FC, ReactElement, useMemo } from 'react';

import { Customer } from '../../entities/Customer/Customer';
import { Navigation, NavigationItem } from '../../entities/Navigation/Navigation';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { useDeviceWidth, useTrans } from '../../hooks';
import { DesktopMenu, MobileMenu } from './subcomponents';

import './Menu.scss';

interface MenuProps {
    isLoading: boolean;
    isAuthenticated: boolean;
    customer?: Customer;
    hasActiveSubNavigation: boolean;
    navigation?: Navigation;
    activeNavigation?: Navigation;
    onNavigationChange: (navigation?: Navigation) => void;
    onBackButtonClick: () => void;
    className?: string;
}

export const Menu: FC<MenuProps> = ({
    isLoading,
    isAuthenticated,
    customer,
    hasActiveSubNavigation,
    navigation,
    activeNavigation,
    onNavigationChange,
    onBackButtonClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const { isMobile } = useDeviceWidth();

    const accountNavigationItems = useMemo((): NavigationItem[] => {
        const customerServiceItem = {
            id: AppRoute.customerService,
            to: trans(appRoutes[AppRoute.customerService].path),
            label: trans(appRoutes[AppRoute.customerService].label),
        };

        if (isAuthenticated) {
            return [
                customerServiceItem,
                {
                    id: AppRoute.account,
                    to: trans(appRoutes[AppRoute.account].path),
                    label: trans(appRoutes[AppRoute.account].label),
                },
            ];
        }

        return [
            customerServiceItem,
            {
                id: AppRoute.login,
                to: trans(appRoutes[AppRoute.login].path),
                label: trans(appRoutes[AppRoute.login].label),
            },
        ];
    }, [isAuthenticated]);

    return (
        <section className={`menu ${className}`}>
            {isMobile && (
                <MobileMenu
                    isLoading={isLoading}
                    hasActiveSubNavigation={hasActiveSubNavigation}
                    navigation={navigation}
                    activeNavigation={activeNavigation}
                    accountNavigationItems={accountNavigationItems}
                    onNavigationChange={onNavigationChange}
                    onBackButtonClick={onBackButtonClick}
                />
            )}

            {!isMobile && (
                <DesktopMenu
                    isAuthenticated={isAuthenticated}
                    navigation={navigation}
                    customer={customer}
                />
            )}
        </section>
    );
};
