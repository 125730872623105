import { FC, ReactElement } from 'react';

import { useParams } from 'react-router-dom';

import { productVariantSeparator } from '../../entities/Product/Product';
import {
    CustomPage,
    NotFoundPage,
    ProductCategoryPage,
    ProductDetailPage,
} from '../../pages';
import { useTypedSelector } from '../../redux/store';

export const ConnectedOtherPageRenderer: FC = (): ReactElement => {
    const { slug = '', subCategory, subSubCategory } = useParams();

    const customPageSlugs = useTypedSelector(state => state.customPagesSlice.slugs);
    const productCategorySlugs = useTypedSelector(state => state.productCategoriesSlice.slugs);

    // Check if slug exists in fetched custom page slugs
    const isCustomPage = customPageSlugs.includes(slug);

    if (isCustomPage) {
        return (
            <CustomPage />
        );
    }

    // Check if slug exists in fetched product category slugs
    const isProductCategory = productCategorySlugs.includes(slug);

    if (isProductCategory || subCategory || subSubCategory) {
        return (
            <ProductCategoryPage />
        );
    }

    // Check if slug contains the characteristic product variant separator
    const isProductDetail = slug.includes(productVariantSeparator);

    if (isProductDetail) {
        return (
            <ProductDetailPage />
        );
    }

    // Return 404 page if page type is not found
    return (
        <NotFoundPage />
    );
};
