import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import { ProductCard } from '../../containers';
import { Product } from '../../entities/Product/Product';
import { addProductVariantToCart } from '../../redux/cart/cartActions';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';
import { addProductVariantToWishlist, removeProductVariantFromWishlist } from '../../redux/wishlist/wishlistActions';

interface ConnectedProductCardProps {
    product: Product;
    className?: string;
    pictureClassname?: string;
}

export const ConnectedProductCard: FC<ConnectedProductCardProps> = ({
    product,
    className = '',
    pictureClassname = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.cartSlice.isLoading);

    const wishlist = useTypedSelector(state => state.wishlistSlice.wishlist);

    const [isInWishlist, setIsInWishlist] = useState<boolean>(false);

    useEffect((): void => {
        const isProductVariantInWishlist = wishlist.productVariantIds.includes(product.id);

        setIsInWishlist(isProductVariantInWishlist);
    }, [wishlist]);

    const handleAddToCart = async (): Promise<void> => {
        await dispatch(addProductVariantToCart(product.variantCode));
    };

    const handleWishlistButtonClick = async (): Promise<void> => {
        if (isInWishlist) {
            await dispatch(removeProductVariantFromWishlist(product.variantId));
            return;
        }

        await dispatch(addProductVariantToWishlist(product.variantId));
    };

    return (
        <ProductCard
            product={product}
            isLoading={isLoading}
            isInWishlist={isInWishlist}
            onAddToCart={handleAddToCart}
            onClickWishlistButton={handleWishlistButtonClick}
            className={className}
            pictureClassname={pictureClassname}
        />
    );
};
