import { FC, ReactElement } from 'react';

import { Price } from '../../../../components';
import { UspItem } from '../../../../entities/@blocks/UspSection/UspSection';
import { CustomerAddress } from '../../../../entities/Address/Address';
import { CartInterface } from '../../../../entities/Cart/Cart';
import { useTrans } from '../../../../hooks';
import { CartProduct } from '../../../CartSidebar/subcomponents';

import './CartSection.scss';

interface CartSectionProps extends CartInterface {
    isLoading: boolean;
    addresses: CustomerAddress[];
    uspItems: UspItem[];
    wishlistItems: string[];
    onUpdateCartItem: (cartItemId: string, quantity: number) => void;
    onRemoveItemFromCart: (cartItemId: string) => void;
    onUpdateCartItemIsInWishlist: (productId: string, isInWishlist: boolean) => void;
    className?: string;
}

export const CartSection: FC<CartSectionProps> = ({
    isLoading,
    items,
    wishlistItems,
    subTotalPrice,
    shippingPrice,
    totalPrice,
    onUpdateCartItemIsInWishlist,
    onUpdateCartItem,
    onRemoveItemFromCart,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`cart-section ${className}`}>
            <h2 className="cart-section__heading">
                {trans('common.cart')}
            </h2>

            <div className="cart-section__products-wrapper">
                {items.map(item => (
                    <CartProduct
                        {...item}
                        key={item.id}
                        isLoading={isLoading}
                        wishlistItems={wishlistItems}
                        onUpdateCartItem={onUpdateCartItem}
                        onUpdateCartItemIsInWishlist={onUpdateCartItemIsInWishlist}
                        onRemoveItemFromCart={onRemoveItemFromCart}
                        className="cart-section__product"
                    />
                ))}
            </div>

            <div className="cart-section__prices-wrapper">
                <div className="cart-section__price-item">
                    <p>
                        {trans('containers.cart.shippingCosts')}
                    </p>

                    {!shippingPrice ? (
                        <p className="cart-section__free-shipping">
                            {trans('common.free')}
                        </p>
                    ) : (
                        <Price
                            includeCurrencySymbol
                            amount={shippingPrice}
                        />
                    )}
                </div>

                <div className="cart-section__price-item">
                    <p>
                        {trans('containers.cart.subtotal')}
                    </p>

                    <Price
                        includeCurrencySymbol
                        amount={subTotalPrice}
                    />
                </div>

                <div className="cart-section__price-item">
                    <p className="cart-section__price-label-total">
                        {trans('containers.cart.total')}
                    </p>

                    <Price
                        includeCurrencySymbol
                        amount={totalPrice}
                        className="cart-section__price-total"
                    />
                </div>
            </div>
        </div>
    );
};
