import { BaseResource } from './BaseResource';
import { Locales } from './Locale';

export type ChoiceItemContent = Record<Locales, string>;

export type ChoiceItem = Record<string, ChoiceItemContent>;

export interface AttributeConfigurationResource extends BaseResource {
    choices: ChoiceItem;
}

export interface AttributeContentsResource extends BaseResource {
    name: string;
    code: string;
    configuration: AttributeConfigurationResource;
}

export enum ProductAttributeKey {
    sku = 'sku',
    material = 'variant-attribute-5',
    color = 'variant-attribute-1',
}

export enum ProductFixedAttributeKey {
    width = 'Breedte',
    height = 'Hoogte',
    depth = 'Diepte',
    diameter = 'Diameter',
    weight = 'Gewicht',
}

export interface AttributeResource extends BaseResource {
    name: string;
    code: ProductAttributeKey;
    attribute: AttributeContentsResource;
    value: string[];
    localeCode: string;
}

export interface ProductVariantResource extends BaseResource {
    code: string;
    product: string;
    variantId: string;
    variantCode: string;
    optionValues: string[];
    attributes: AttributeResource[];
    name: string;
    inStock: boolean;
    price: number;
    originalPrice: number;
}
