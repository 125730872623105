import { FC, ReactElement } from 'react';

import { LinkIconButton } from '../../../../compositions';
import { HorizontalAlignment } from '../../../../entities/Alignment/Alignment';
import { LinkTarget } from '../../../../entities/Link/Link';
import { transformNameToGoogleMapsLink } from '../../../../entities/Link/LinkTransformers';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { Store } from '../../../../entities/Store/Store';
import { getOpenStatusInfo } from '../../../../helpers/workingHours';
import { useDeviceWidth, useTrans } from '../../../../hooks';

import './Stores.scss';

interface StoresProps {
    stores: Store[];
    onClickSeeAll: () => void;
    className?: string;
}

export const Stores: FC<StoresProps> = ({
    stores,
    onClickSeeAll,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { isMobile } = useDeviceWidth();

    const storesWithOpenStatus = stores.map(store => ({
        ...store,
        ...getOpenStatusInfo(store.openingHours, true),
    }));

    return (
        <div className={`stores ${className}`}>
            <p className="stores__label">
                {trans('containers.searchPopup.openingHours')}
            </p>

            <ul>
                {storesWithOpenStatus.map(store => {
                    const linkToGoogleMapsStore = transformNameToGoogleMapsLink(store.title);

                    return (
                        <li key={store.id} className="stores__list-item">
                            <p className="stores__location-name">
                                {store.title}
                            </p>

                            <p className="stores__location-open-status">
                                {isMobile ? store.shortOpenStatus : store.openStatus}
                            </p>

                            <LinkIconButton
                                hasAnimation
                                isSmall
                                to={linkToGoogleMapsStore}
                                target={LinkTarget.blank}
                                icon="arrow-right"
                                iconPos={HorizontalAlignment.right}
                                text={trans('containers.searchPopup.route')}
                                className="stores__link"
                                iconClassName="stores__link-icon"
                            />
                        </li>
                    );
                })}
            </ul>

            <LinkIconButton
                isSmall
                icon="arrow-right"
                iconPos={HorizontalAlignment.right}
                text={trans('containers.searchPopup.allOpeningHours')}
                to={trans(appRoutes[AppRoute.storesOverview].path)}
                onClick={onClickSeeAll}
                className="stores__show-all-link"
                labelClassName="stores__show-all-link-label"
                iconClassName="stores__link-icon"
            />
        </div>
    );
};

