import { AddressType } from '../../entities/Address/Address';
import { Customer } from '../../entities/Customer/Customer';

export const MOCK_CUSTOMER: Customer = {
    id: '1',
    firstName: 'John',
    lastName: 'Doe',
    birthday: '1990-01-01T00:00:00.000Z',
    email: 'john-doe@testmail.com',
    phoneNumber: '0611111111',
    addresses: [
        {
            type: AddressType.billing,
            id: '1',
            street: 'Euroweg',
            postalCode: '1234 AB',
            houseNumber: '1',
            city: 'Amsterdam',
            country: 'Nederland',
        },
        {
            type: AddressType.shipping,
            id: '2',
            street: 'Schoolstraat',
            postalCode: '8888 XX',
            houseNumber: '73',
            city: 'Hoorn',
            country: 'Nederland',
        },
    ],
    company: {
        name: 'Loods 5 Zaandam',
        vatNumber: 'NL819872489B01',
    },
};
