import { AdjustedOpeningHours, OpeningHours } from '../entities/Store/Store';
import trans from './trans';
import { getTodayIsoDate } from './validation';

const formatTime = (time?: string): string => {
    if (!time) return '';
    return time.split(':').slice(0, 2).join(':');
};

export const getStoreOpeningStatus = (
    openingHours: OpeningHours[],
    adjustedOpeningHours: AdjustedOpeningHours[] = [],
): string => {
    const todayIso = getTodayIsoDate();
    const now = new Date();
    const currentTime = now.toTimeString().split(' ')[0];
    const today = now.getDay() || 7;

    const adjustedHour = adjustedOpeningHours.find(item => item.day === todayIso);

    if (adjustedHour) {
        const todayName = trans(`date.days.${adjustedHour.day}`);

        if (adjustedHour.closed || !adjustedHour.from || !adjustedHour.to) {
            return `${trans('common.nowClosed')}: ${todayName}`;
        }

        if (currentTime >= adjustedHour.from && currentTime <= adjustedHour.to) {
            return `${trans('common.nowOpen')}: ${todayName} ${formatTime(adjustedHour.from)} - ${formatTime(adjustedHour.to)}`;
        }

        return `${trans('common.nowClosed')}: ${todayName} ${formatTime(adjustedHour.from)} - ${formatTime(adjustedHour.to)}`;
    }

    const todayHours = openingHours.find(hour => hour.day === today);
    const todayName = trans(`date.days.${today}`);

    if (!todayHours || todayHours.closed || !todayHours.from || !todayHours.to) {
        return `${trans('common.nowClosed')}: ${todayName}`;
    }

    if (currentTime >= todayHours.from && currentTime <= todayHours.to) {
        return `${trans('common.nowOpen')}: ${todayName} ${formatTime(todayHours.from)} - ${formatTime(todayHours.to)}`;
    }

    return `${trans('common.nowClosed')}: ${todayName} ${formatTime(todayHours.from)} - ${formatTime(todayHours.to)}`;
};
