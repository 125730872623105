import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { UspItem } from '../../entities/@blocks/UspSection/UspSection';
import { FormOption } from '../../entities/@forms/Form/Form';
import { CustomerAddress } from '../../entities/Address/Address';
import { CartSidebarInterface } from '../../entities/Cart/Cart';
import { CheckoutTab } from '../../entities/Checkout/Checkout';
import { CheckoutNavigation } from '../CheckoutNavigation/CheckoutNavigation';
import {
    CartSection,
    PaymentStep,
    ShipmentStep,
    StartStep,
} from './subcomponents';

import './Checkout.scss';

interface CheckoutProps extends CartSidebarInterface {
    isLoading: boolean;
    tab?: CheckoutTab;
    isAuthenticated: boolean;
    addresses?: CustomerAddress[];
    paymentMethods: FormOption[];
    uspItems: UspItem[];
    wishlistItems: string[];
    onChooseShippingMethod: (shippingMethodId: string) => void;
    onChoosePaymentMethod: (shippingMethodId: string) => void;
    onUpdateCartItem: (cartItemId: string, quantity: number) => void;
    onRemoveItemFromCart: (cartItemId: string) => void;
    onUpdateCartItemIsInWishlist: (productId: string, isInWishlist: boolean) => void;
    onClearCart: () => void;
    className?: string;
}

export const Checkout: FC<CheckoutProps> = ({
    isLoading,
    tab,
    isAuthenticated,
    addresses,
    shippingMethods,
    paymentMethods,
    uspItems,
    id,
    items,
    wishlistItems,
    subTotalPrice,
    shippingPrice,
    totalPrice,
    onChooseShippingMethod,
    onChoosePaymentMethod,
    onUpdateCartItem,
    onRemoveItemFromCart,
    onUpdateCartItemIsInWishlist,
    onClearCart,
    className = '',
}): ReactElement => {
    const showCartSection = tab !== CheckoutTab.order && tab !== CheckoutTab.completing;

    const wrapperClassNames = classNames('checkout__wrapper', {
        'checkout__wrapper--has-grid-layout': showCartSection,
    });

    return (
        <div className={`checkout ${className}`}>
            <CheckoutNavigation />

            <div className={wrapperClassNames}>
                {tab === CheckoutTab.order && (
                    <StartStep
                        isLoading={isLoading}
                        isAuthenticated={isAuthenticated}
                        id={id}
                        addresses={addresses || []}
                        shippingMethods={shippingMethods}
                        paymentMethods={paymentMethods}
                        uspItems={uspItems}
                        items={items}
                        wishlistItems={wishlistItems}
                        subTotalPrice={subTotalPrice}
                        shippingPrice={shippingPrice}
                        totalPrice={totalPrice}
                        onRemoveItemFromCart={onRemoveItemFromCart}
                        onUpdateCartItem={onUpdateCartItem}
                        onUpdateCartItemIsInWishlist={onUpdateCartItemIsInWishlist}
                        onClearCart={onClearCart}
                    />
                )}

                {tab === CheckoutTab.shipment && (
                    <ShipmentStep
                        addresses={addresses || []}
                        shippingMethods={shippingMethods}
                        onChooseShippingMethod={onChooseShippingMethod}
                    />
                )}

                {tab === CheckoutTab.payment && (
                    <PaymentStep
                        paymentMethods={paymentMethods}
                        onChoosePaymentMethod={onChoosePaymentMethod}
                    />
                )}

                {tab === CheckoutTab.completing && (
                    <p>TODO</p>
                )}

                {showCartSection && (
                    <CartSection
                        isLoading={isLoading}
                        id={id}
                        addresses={addresses || []}
                        shippingMethods={shippingMethods}
                        paymentMethods={paymentMethods}
                        uspItems={uspItems}
                        items={items}
                        wishlistItems={wishlistItems}
                        subTotalPrice={subTotalPrice}
                        shippingPrice={shippingPrice}
                        totalPrice={totalPrice}
                        onRemoveItemFromCart={onRemoveItemFromCart}
                        onUpdateCartItem={onUpdateCartItem}
                        onUpdateCartItemIsInWishlist={onUpdateCartItemIsInWishlist}
                    />
                )}
            </div>
        </div>
    );
};
