import { FC, ReactElement } from 'react';

import { LinkIconButton } from '../../../../compositions';
import { ConnectedProductCard } from '../../../../connectors';
import { HorizontalAlignment } from '../../../../entities/Alignment/Alignment';
import { Order } from '../../../../entities/Order/Order';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import { useTrans } from '../../../../hooks';

import './OrderItem.scss';

interface OrderItemProps {
    order: Order;
    className?: string;
}

export const OrderItem: FC<OrderItemProps> = ({
    order,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const orderItemPath = trans('routes.orderDetail.path');
    const orderItemUrl = replaceUrlParamKeysWithValues(orderItemPath, { id: order.id });

    return (
        <div
            key={order.id}
            className={`order-item ${className}`}
        >
            <ConnectedProductCard
                product={order.products[0]}
                className="order-item__product-card"
            />

            <p className="order-item__info">
                {trans('containers.accountDashboardOrders.orderNumber', {
                    orderNumber: order.orderNumber,
                })}
            </p>

            <p className="order-item__info">
                {trans('containers.accountDashboardOrders.order', {
                    date: order.orderDate.getDate(),
                })}
            </p>

            <p className="order-item__info">
                {trans('containers.accountDashboardOrders.total', {
                    amount: order.products.length,
                    totalPrice: order.totalPrice,
                })}
            </p>

            <LinkIconButton
                hasAnimation
                to={orderItemUrl}
                icon="arrow-right"
                iconPos={HorizontalAlignment.right}
                text={trans('containers.accountDashboardOrders.viewOrder')}
                className="order-item__link"
            />
        </div>
    );
};
