import { FC, ReactElement, useState } from 'react';

import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';

import { Logo, Wrapper } from '../../../../components';
import { IconButton } from '../../../../compositions';
import { ConnectedCartSideBar, ConnectedSearchPopup } from '../../../../connectors';
import { Navigation, NavigationItem } from '../../../../entities/Navigation/Navigation';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { useTrans } from '../../../../hooks';
import { MobileNavigation } from '..';

import './MobileMenu.scss';

interface MobileMenuProps {
    isLoading: boolean;
    hasActiveSubNavigation: boolean;
    navigation?: Navigation;
    activeNavigation?: Navigation;
    accountNavigationItems: NavigationItem[];
    onNavigationChange: (navigation?: Navigation) => void;
    onBackButtonClick: () => void;
    className?: string;
}

export const MobileMenu: FC<MobileMenuProps> = ({
    isLoading,
    hasActiveSubNavigation,
    navigation,
    activeNavigation,
    accountNavigationItems,
    onNavigationChange,
    onBackButtonClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [navigationIsOpen, toggleNavigationIsOpen] = useToggle(false);
    const [shouldNavigateBack, setShouldNavigateBack] = useState<boolean>(false);
    const [isNavigatingBack, setIsNavigatingBack] = useState<boolean>(false);

    const handleCloseNavigation = (): void => {
        toggleNavigationIsOpen(false);
    };

    const handleBackButtonClick = (): void => {
        setShouldNavigateBack(true);

        // Set boolean value to prevent stuck button on fast clicks in succession
        setIsNavigatingBack(true);
    };

    const handleBackTransitionMidPoint = (): void => {
        setShouldNavigateBack(false);
        onBackButtonClick();
    };

    // Reset boolean value after transition is complete
    const handleTransitionEnd = (): void => {
        setIsNavigatingBack(false);
    };

    return (
        <div className={`mobile-menu ${className}`}>
            <Wrapper className="mobile-menu__wrapper">
                {navigationIsOpen && hasActiveSubNavigation ? (
                    <IconButton
                        hideLabel
                        icon="chevron-left"
                        text={trans('common.back')}
                        disabled={isNavigatingBack}
                        onClick={handleBackButtonClick}
                        className="mobile-menu__back-button"
                    />
                ) : (
                    <Link
                        aria-label={trans('containers.menu.homeLink')}
                        to={trans(appRoutes[AppRoute.home].path)}
                        onClick={handleCloseNavigation}
                    >
                        <Logo className="mobile-menu__logo" />
                    </Link>
                )}

                <div className="mobile-menu__controls">
                    <ConnectedSearchPopup />

                    <ConnectedCartSideBar />

                    <MobileNavigation
                        isLoading={isLoading}
                        isOpen={navigationIsOpen}
                        hasActiveSubNavigation={hasActiveSubNavigation}
                        shouldNavigateBack={shouldNavigateBack}
                        navigation={navigation}
                        activeNavigation={activeNavigation}
                        accountNavigationItems={accountNavigationItems}
                        onNavigationToggle={toggleNavigationIsOpen}
                        onNavigationChange={onNavigationChange}
                        onBackTransitionMidpoint={handleBackTransitionMidPoint}
                        onTransitionEnd={handleTransitionEnd}
                    />
                </div>
            </Wrapper>
        </div>
    );
};
