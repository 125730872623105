import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../components';
import { LinkIconButton } from '../../compositions';
import { HorizontalAlignment } from '../../entities/Alignment/Alignment';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../helpers/url';
import { useTrans } from '../../hooks';

import './IndexPage.scss';

export const IndexPage: FC = (): ReactElement => {
    const trans = useTrans();

    const brandDetailPath = trans(appRoutes[AppRoute.brandDetail].path);
    const brandDetailUrl = replaceUrlParamKeysWithValues(brandDetailPath, { slug: 'hkliving' });

    const productCategoryPath = trans(appRoutes[AppRoute.productCategory].path);
    const productCategoryUrl = replaceUrlParamKeysWithValues(productCategoryPath, {
        category: 'meubels',
        subCategory: 'banken',
        subSubCategory: 'slaapbank',
    });

    return (
        <Page className="index-page">
            <Wrapper>
                <section className="index-page__section">
                    <h2>Pagina&apos;s</h2>

                    <LinkIconButton
                        to={trans(appRoutes[AppRoute.blogOverview].path)}
                        text="Inspiratie overzicht"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />

                    <LinkIconButton
                        to={productCategoryUrl}
                        text="Categorie pagina"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />

                    <LinkIconButton
                        to={brandDetailUrl}
                        text="Merk pagina"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />

                    <LinkIconButton
                        to={trans(appRoutes[AppRoute.customerService].path)}
                        text="Contact / klantenservice"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />

                    <LinkIconButton
                        to={trans(appRoutes[AppRoute.login].path)}
                        text={trans(appRoutes[AppRoute.login].label)}
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />
                </section>

                <section className="index-page__section">
                    <h2>Overige pagina&apos;s</h2>

                    <LinkIconButton
                        to={trans(appRoutes[AppRoute.contentBlocks].path)}
                        text="Content blocks"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />

                    <LinkIconButton
                        to={trans(appRoutes[AppRoute.styleguide].path)}
                        text="Styleguide"
                        icon="arrow-right"
                        iconPos={HorizontalAlignment.right}
                        className="index-page__section-examples"
                    />
                </section>
            </Wrapper>
        </Page>
    );
};
