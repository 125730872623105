import { FC, ReactElement, useState } from 'react';

import { TabList } from '../../compositions';
import { AccountDashboardConfigTab } from '../../entities/Account/Account';
import { CustomerAddress } from '../../entities/Address/Address';
import { Customer } from '../../entities/Customer/Customer';
import { useTrans } from '../../hooks';
import { Giftcard, MyAddresses, MyData } from './subcomponents';

import './AccountDashboardConfig.scss';

interface AccountDashboardConfigProps {
    customer: Customer;
    onUpdateAddresses: (newAddressesData: CustomerAddress) => void;
    onChangeShippingAddress: (addressId: string) => void;
    className?: string;
}

export const AccountDashboardConfig: FC<AccountDashboardConfigProps> = ({
    customer,
    onUpdateAddresses,
    onChangeShippingAddress,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [activeTab, setActiveTab] = useState<AccountDashboardConfigTab>(AccountDashboardConfigTab.myData);

    const configTabs = [
        { label: trans('pages.accountDashboard.myData'), value: AccountDashboardConfigTab.myData },
        { label: trans('pages.accountDashboard.myAddresses'), value: AccountDashboardConfigTab.myAddresses },
        { label: trans('containers.accountDashboardConfig.giftcard.giftcard'), value: AccountDashboardConfigTab.giftcard },
    ];

    const handleConfigTabSelect = (value: string): void => {
        const selectedTab = value as AccountDashboardConfigTab;

        setActiveTab(selectedTab);
    };

    return (
        <div className={`account-dashboard-config ${className}`}>
            <TabList
                tabs={configTabs}
                activeTab={activeTab}
                onTabSelect={handleConfigTabSelect}
                className="account-dashboard-config__tabs"
                tabItemLabelClassName="account-dashboard-config__tabs-item-label"
            />

            {activeTab === AccountDashboardConfigTab.myData && (
                <MyData
                    customer={customer}
                    onSubmit={console.log}
                />
            )}

            {activeTab === AccountDashboardConfigTab.myAddresses && customer.addresses && (
                <MyAddresses
                    addresses={customer.addresses}
                    onSubmit={onUpdateAddresses}
                    onChangeShippingAddress={onChangeShippingAddress}
                />
            )}

            {activeTab === AccountDashboardConfigTab.giftcard && (
                <Giftcard />
            )}
        </div>
    );
};
