export enum AddressType {
    billing = 'billing',
    shipping = 'shipping',
}

export interface StrapiAddressResource {
    streetName: string;
    houseNumber: string;
    zipCode: string;
    city: string;
    country: string;
    latitude?: number | null;
    longitude?: number | null;
}

export interface GeoCoords {
    latitude?: number;
    longitude?: number;
}

export interface Address {
    id: string;
    street: string;
    postalCode: string;
    houseNumber: string;
    addition?: string;
    city: string;
    country: string;
    geoCoords?: GeoCoords;
}

export interface CustomerAddress extends Address {
    type: AddressType;
}

export const defaultAddress = (): Address => ({
    id: '',
    street: '',
    postalCode: '',
    houseNumber: '',
    city: '',
    country: '',
});
