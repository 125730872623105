import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { transformToDay } from '../../Date/DateTransformers';
import {
    AdjustedOpeningHours,
    AdjustedOpeningHoursResource,
    ContactServiceOptionResponse,
    OpeningHoursResource,
    ServiceContactDetails,
    ServiceSectionInterface,
    ServiceSectionResource,
    WorkingHours,
} from './ServiceSection';

export const transformToWorkingHours = (
    openingHours: OpeningHoursResource[] = [],
): WorkingHours[] => {
    const standardHours = openingHours.map((item) => ({
        day: transformToDay(item.day),
        from: item.openingHours.from,
        to: item.openingHours.to,
        closed: item.openingHours.closed,
    }));

    return [...standardHours];
};

export const transformToAdjustableOpeningHours = (
    adjustedOpeningHours: AdjustedOpeningHoursResource[] = [],
): AdjustedOpeningHours[] => {
    const adjustedHours = adjustedOpeningHours.map((item) => ({
        day: item.day,
        from: item.openingHours.from,
        to: item.openingHours.to,
        closed: item.openingHours.closed,
    }));

    return [...adjustedHours];
};

export const transformToServiceContactDetails = (
    resource: ContactServiceOptionResponse,
): ServiceContactDetails => {
    const whatsApp = resource.data?.whatsApp || { phoneNumber: '', openingHours: [], adjustedOpeningHours: [] };
    const phone = resource.data?.phone || { phoneNumber: '', openingHours: [], adjustedOpeningHours: [] };
    const email = resource.data?.email || { emailAddress: '', openingHours: [] };

    return {
        whatsApp: {
            phoneNumber: whatsApp.phoneNumber,
            adjustedOpeningHours: transformToAdjustableOpeningHours(whatsApp.adjustedOpeningHours),
            workingHours: transformToWorkingHours(
                whatsApp.openingHours,
            ),
        },
        phone: {
            phoneNumber: phone.phoneNumber,
            adjustedOpeningHours: transformToAdjustableOpeningHours(phone.adjustedOpeningHours),
            workingHours: transformToWorkingHours(
                phone.openingHours,
            ),
        },
        email: {
            emailAddress: email.emailAddress,
            workingHours: transformToWorkingHours(email.openingHours),
        },
    };
};

export const transformToServiceSection = (
    resource: ServiceSectionResource,
): ServiceSectionInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    return {
        ...defaultBlock,
        type: BlockType.serviceSection,
        title: resource.title,
    };
};
