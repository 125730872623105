import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedBlockRenderer, ConnectedPageHelmet } from '../../connectors';
import { AppRoute } from '../../entities/Routing/Routing';

import './HomePage.scss';

export const HomePage: FC = (): ReactElement => (
    <Page className="home-page">
        <ConnectedPageHelmet appRoute={AppRoute.home} />
        <ConnectedBlockRenderer appRoute={AppRoute.home} />
    </Page>
);
