import { FC, ReactElement, useState } from 'react';

import { Index } from 'react-instantsearch';
import { useLocalStorage } from 'react-use';

import { SearchPopup } from '../../containers';
import { Link, LinkTarget } from '../../entities/Link/Link';
import { MeilisearchIndex } from '../../entities/Meilisearch/Meilisearch';
import { AppRoute, appRoutes } from '../../entities/Routing/Routing';
import { recentSearchesKey, SearchPopupProductItem } from '../../entities/SearchPopup/SearchPopup';
import { useTrans } from '../../hooks';
import { useTypedSelector } from '../../redux/store';
import { ConnectedPageHitsHandler } from '../@instantsearch/ConnectedPageHitsHandler/ConnectedPageHitsHandler';
import { ConnectedProductHitsHandler } from '../@instantsearch/ConnectedProductHitsHandler/ConnectedProductHitsHandler';

export const ConnectedSearchPopup: FC = (): ReactElement => {
    const trans = useTrans();

    const pageSuggestions: Link[] = [
        {
            href: trans(appRoutes[AppRoute.storesOverview].path),
            label: trans(appRoutes[AppRoute.storesOverview].label),
            target: LinkTarget.blank,
        },
        {
            href: trans(appRoutes[AppRoute.customerService].path),
            label: trans(appRoutes[AppRoute.customerService].label),
            target: LinkTarget.blank,
        },
        {
            href: trans(appRoutes[AppRoute.customerServiceFaq].path),
            label: trans(appRoutes[AppRoute.customerServiceFaq].label),
            target: LinkTarget.blank,
        },
    ];

    const stores = useTypedSelector(state => state.storeSlice.stores);

    const [recentSearches, storeRecentSearches] = useLocalStorage<string[]>(recentSearchesKey, []);

    const [searchTerm, setSearchTerm] = useState<string>('');
    const [productResults, setProductResults] = useState<SearchPopupProductItem[]>([]);
    const [pageResults, setPageResults] = useState<Link[]>([]);

    const storeTermInRecentSearches = (term: string): void => {
        if (term.length < 1) {
            return;
        }

        const otherSearches = recentSearches?.filter(item => item !== term) || [];
        const cappedSearches = [term, ...otherSearches].slice(0, 5);

        storeRecentSearches(cappedSearches);
    };

    return (
        <div>
            <SearchPopup
                searchTerm={searchTerm}
                productResults={productResults}
                pageResults={pageResults}
                stores={stores}
                pageSuggestions={pageSuggestions}
                recentSearches={recentSearches}
                onSetStoreTermInRecentSearches={storeTermInRecentSearches}
                onSetSearchTerm={setSearchTerm}
            />

            <Index indexName={MeilisearchIndex.variantsNl}>
                <ConnectedProductHitsHandler
                    query={searchTerm}
                    onNewItems={setProductResults}
                />
            </Index>

            <Index indexName={MeilisearchIndex.pages}>
                <ConnectedPageHitsHandler
                    query={searchTerm}
                    onNewItems={setPageResults}
                />
            </Index>
        </div>
    );
};
