import { FC, ReactElement, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { Page } from '../../../components';
import {
    ConnectedBlockRenderer,
    ConnectedBlogCategories,
    ConnectedBlogOverview,
    ConnectedPageHelmet,
} from '../../../connectors';
import { AppRoute } from '../../../entities/Routing/Routing';
import { useTrans } from '../../../hooks';

import './BlogOverviewPage.scss';

export const BlogOverviewPage: FC = (): ReactElement => {
    const { category = '' } = useParams();

    const trans = useTrans();

    const blogCategory = useMemo((): string => {
        const isAllNews = category === trans('entities.blogCategory.all.value');

        return isAllNews
            ? ''
            : category;
    }, [category]);

    return (
        <Page className="blog-overview-page">
            <ConnectedPageHelmet appRoute={AppRoute.blogOverview} />

            <ConnectedBlockRenderer
                appRoute={AppRoute.blogOverview}
                className="blog-overview-page__block-renderer"
            />

            <ConnectedBlogCategories className="blog-overview-page__categories" />

            <ConnectedBlogOverview
                showPagination
                categories={[blogCategory]}
                className="blog-overview-page__blog-overview"
            />
        </Page>
    );
};
