import { BlockType } from '../../Block/Block';
import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import {
    FormInterface,
    FormResource,
    FormType,
    FormTypeResource,
} from './Form';

const transformToFormType = (resource?: FormTypeResource): FormType => {
    if (resource === FormTypeResource.participate) {
        return FormType.participate;
    }

    return FormType.contact;
};

export const transformToForm = (resource: FormResource): FormInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const formType = transformToFormType(resource.type);

    return {
        ...defaultBlock,
        type: BlockType.form,
        title: resource.title || '',
        information: resource.infoOrTerms || undefined,
        formType,
    };
};
