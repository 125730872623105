import trans from '../../helpers/trans';
import { MeilisearchAttributes } from '../Api/Webshop';

export interface ProductFilter {
    attribute: MeilisearchAttributes;
    label: string;
    operator: 'and' | 'or';
}

export const productDefaultFilters: ProductFilter[] = [
    {
        attribute: MeilisearchAttributes.categories,
        label: trans('entities.productFilters.category'),
        operator: 'or',
    },
];

const categoryFilter = productDefaultFilters.find(item => item.attribute === MeilisearchAttributes.categories);

export const highlightedProductFilters: ProductFilter[] = [
    ...(categoryFilter ? [categoryFilter] : []),
    // TODO: Replace this filter with the filters (when available): product type, brand, color, and material
];
