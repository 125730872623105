import { FC, ReactElement } from 'react';

import { Icon, Price } from '../../../../components';
import { Button, LinkButton } from '../../../../compositions';
import { UspItem } from '../../../../entities/@blocks/UspSection/UspSection';
import { CustomerAddress } from '../../../../entities/Address/Address';
import { CartInterface } from '../../../../entities/Cart/Cart';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { useTrans } from '../../../../hooks';
import { CartProduct } from '../../../CartSidebar/subcomponents';

import './StartStep.scss';

interface StartStepProps extends CartInterface {
    isAuthenticated: boolean;
    isLoading: boolean;
    addresses: CustomerAddress[];
    uspItems: UspItem[];
    wishlistItems: string[];
    onUpdateCartItem: (cartItemId: string, quantity: number) => void;
    onRemoveItemFromCart: (cartItemId: string) => void;
    onUpdateCartItemIsInWishlist: (productId: string, isInWishlist: boolean) => void;
    onClearCart: () => void;
    className?: string;
}

export const StartStep: FC<StartStepProps> = ({
    isAuthenticated,
    isLoading,
    uspItems,
    items,
    wishlistItems,
    subTotalPrice,
    shippingPrice,
    totalPrice,
    onUpdateCartItemIsInWishlist,
    onUpdateCartItem,
    onRemoveItemFromCart,
    onClearCart,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`start-step ${className}`}>
            <section className="start-step__left-section">
                <div className="start-step__top">
                    <h2 className="start-step__heading">
                        {trans('common.cart')}
                    </h2>

                    {items.length !== 0 && (
                        <Button
                            text={trans('containers.cart.clear')}
                            onClick={onClearCart}
                            className="start-step__clear-cart-button"
                        />
                    )}
                </div>

                <div className="start-step__product-column-definitions">
                    <div className="start-step__product-column-definition">
                        {trans('containers.cart.columnName')}
                    </div>

                    <div className="start-step__product-column-definition">
                        {trans('containers.cart.columnCount')}
                    </div>

                    <div className="start-step__product-column-definition">
                        {trans('containers.cart.columnTotal')}
                    </div>
                </div>

                <div>
                    {items.map(item => (
                        <CartProduct
                            {...item}
                            key={item.id}
                            isLoading={isLoading}
                            wishlistItems={wishlistItems}
                            onUpdateCartItem={onUpdateCartItem}
                            onUpdateCartItemIsInWishlist={onUpdateCartItemIsInWishlist}
                            onRemoveItemFromCart={onRemoveItemFromCart}
                            className="start-step__product"
                        />
                    ))}

                    {!isLoading && items.length === 0 && (
                        <div className="start-step__no-products-wrapper">
                            <Icon name="cart" className="start-step__no-products-icon" />

                            <p className="start-step__no-products-text">
                                {trans('containers.cart.noProductsInCart')}
                            </p>
                        </div>
                    )}
                </div>
            </section>

            <section className="start-step__right-section">
                <h2 className="start-step__heading">
                    {trans('containers.checkout.startStep.yourOrder')}
                </h2>

                <ul className="start-step__usp-list">
                    {uspItems.map(item => (
                        <li key={item.id} className="start-step__usp-item">
                            <Icon
                                name={item.icon}
                                className="start-step__usp-item-icon"
                            />

                            {item.text}
                        </li>
                    ))}
                </ul>

                <div className="start-step__prices-wrapper">
                    <div className="start-step__price-item">
                        <p>
                            {trans('containers.cart.shippingCosts')}
                        </p>

                        {!shippingPrice ? (
                            <p className="start-step__free-shipping">
                                {trans('common.free')}
                            </p>
                        ) : (
                            <Price
                                includeCurrencySymbol
                                amount={shippingPrice}
                            />
                        )}
                    </div>

                    <div className="start-step__price-item">
                        <p>
                            {trans('containers.cart.subtotal')}
                        </p>

                        <Price
                            includeCurrencySymbol
                            amount={subTotalPrice}
                        />
                    </div>

                    <div className="start-step__price-item">
                        <p className="start-step__price-label-total">
                            {trans('containers.cart.total')}
                        </p>

                        <Price
                            includeCurrencySymbol
                            amount={totalPrice}
                            className="start-step__price-total"
                        />
                    </div>
                </div>

                {isAuthenticated && (
                    <LinkButton
                        isSmall
                        text={trans('common.continue')}
                        to={trans(appRoutes[AppRoute.checkoutShipment].path)}
                        className="start-step__button"
                    />
                )}

                {!isAuthenticated && (
                    <div className="start-step__buttons-wrapper">
                        <LinkButton
                            isSmall
                            text={trans('containers.checkout.startStep.login')}
                            to={trans(appRoutes[AppRoute.login].path)}
                            className="start-step__button"
                        />

                        <Button
                            isSmall
                            text={trans('containers.checkout.startStep.continueAsGuest')}
                            onClick={console.log}
                            className="start-step__button start-step__button--guest"
                        />
                    </div>
                )}
            </section>
        </div>
    );
};
