import { FC, ReactElement, useState } from 'react';

import { Index } from 'react-instantsearch';
import { useSearchParams } from 'react-router-dom';

import { Page } from '../../components';
import { ResultsCountForSearchTerm } from '../../compositions';
import { ConnectedInstantSearchPageResultsList, ConnectedProductList } from '../../connectors';
import { MeilisearchIndex } from '../../entities/Meilisearch/Meilisearch';
import { useTypedSelector } from '../../redux/store';

import './SearchPage.scss';

export const SearchPage: FC = (): ReactElement => {
    const [queryParams] = useSearchParams();
    const searchTermParam = queryParams.get('q') || '';

    // TODO: Should be done from within a connector
    const ssrQuery = useTypedSelector(state => state.searchSlice.ssrQuery);

    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalProducts, setTotalProducts] = useState<number>(0);

    // TODO: Find a way to get this value on SSR
    const totalResults = totalPages + totalProducts;

    return (
        <Page className="search-page">
            <ResultsCountForSearchTerm
                resultsCount={totalResults}
                query={searchTermParam}
            />

            <Index indexName={MeilisearchIndex.pages}>
                <ConnectedInstantSearchPageResultsList
                    query={ssrQuery || searchTermParam}
                    onTotalResults={setTotalPages}
                />
            </Index>

            <Index indexName={MeilisearchIndex.variantsNl}>
                <ConnectedProductList
                    showProductsCount
                    showFilterBar
                    query={ssrQuery || searchTermParam}
                    onTotalResults={setTotalProducts}
                />
            </Index>
        </Page>
    );
};
