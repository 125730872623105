import { FC, ReactElement, useState } from 'react';

import { Button, SortInput } from '../../../../compositions';
import { FormOption } from '../../../../entities/@forms/Form/Form';
import { useTrans } from '../../../../hooks';

import './PaymentStep.scss';

interface PaymentStepProps {
    paymentMethods: FormOption[];
    onChoosePaymentMethod: (paymentMethodId: string) => void;
    className?: string;
}

export const PaymentStep: FC<PaymentStepProps> = ({
    paymentMethods,
    onChoosePaymentMethod,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const [paymentMethodId, setPaymentMethodId] = useState<string>('');

    const handleContinueButtonClick = (): void => {
        onChoosePaymentMethod(paymentMethodId);
    };

    return (
        <div className={`payment-step ${className}`}>
            <h2 className="payment-step__heading">
                {trans('containers.checkout.paymentStep.heading')}
            </h2>

            {/* TODO: Enhance dropdown with data from API */}
            <SortInput
                hideLabel
                name="payment-methods"
                options={paymentMethods}
                label={trans('containers.checkout.paymentStep.choosePaymentMethod')}
                placeholder={trans('containers.checkout.paymentStep.choosePaymentMethod')}
                onChange={setPaymentMethodId}
            />

            <Button
                isSmall
                disabled={!paymentMethodId}
                text={trans('containers.checkout.paymentStep.goToPayment')}
                onClick={handleContinueButtonClick}
                className="payment-step__button"
            />
        </div>
    );
};
