import { generateUuid } from '../../helpers/string';
import { Address, StrapiAddressResource } from './Address';

export const transformStrapiAddressToAddress = (resource: StrapiAddressResource): Address => {
    const geoCoords = resource.latitude && resource.longitude
        ? {
            latitude: resource.latitude,
            longitude: resource.longitude,
        }
        : undefined;

    return {
        id: generateUuid(),
        street: resource.streetName,
        postalCode: resource.zipCode,
        houseNumber: resource.houseNumber,
        city: resource.city,
        country: resource.country,
        geoCoords,
    };
};
