import { GetProductResponse, GetProductVariantResponse, ProductAttributeValue } from '../../entities/Api/Webshop';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { transformProductCategoriesToBreadcrumbs } from '../../entities/Link/LinkTransformers';
import {
    transformSlugToVariantId,
    transformToProduct,
    transformToProductDefault,
    transformToProductVariant,
} from '../../entities/Product/ProductTransformers';
import { syliusFetch } from '../../entities/Sylius/SyliusService';
import { TaxonEntry } from '../../entities/Taxon/Taxon';
import { fetchTaxonEntry } from '../../entities/Taxon/TaxonService';
import { ReduxFetchAction } from '../defaults';
import {
    setBreadcrumbs,
    setError,
    setHasFetched,
    setIsLoading,
    setProduct,
} from './productSlice';

export const fetchProductFromVariantSlug: ReduxFetchAction<string> = variantSlug => async dispatch => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setHasFetched(false));
    dispatch(setProduct(undefined));

    try {
        const variantId = transformSlugToVariantId(variantSlug);
        const productVariantResponse = await syliusFetch<GetProductVariantResponse>(`/shop/product-variants/${variantId}`);

        if (!isFetchResultSuccessful(productVariantResponse)) {
            dispatch(setError(productVariantResponse.error));
            return;
        }

        const productVariant = transformToProductVariant(productVariantResponse.data);
        const productDefaultResponse = await syliusFetch<GetProductResponse>(`/shop/products/${productVariant.productCode}`);

        if (!isFetchResultSuccessful(productDefaultResponse)) {
            dispatch(setError(productDefaultResponse.error));
            return;
        }

        const taxonList = productDefaultResponse.data.productTaxons;
        const taxonPromises = taxonList.map(fetchTaxonEntry);

        const resolvedTaxonEntries = await Promise.all(taxonPromises);
        const foundTaxonEntries = resolvedTaxonEntries.filter(Boolean) as TaxonEntry[];

        const categories = Object.values(Object.fromEntries(foundTaxonEntries));

        const breadcrumbs = transformProductCategoriesToBreadcrumbs(categories.reverse());

        dispatch(setBreadcrumbs(breadcrumbs));

        const productDefault = transformToProductDefault(productDefaultResponse.data);

        const productAttributeValuesResponse = await syliusFetch<ProductAttributeValue[]>(`/shop/products/${productVariant.productCode}/attributes`);

        if (!isFetchResultSuccessful(productAttributeValuesResponse)) {
            dispatch(setError(productAttributeValuesResponse.error));
            return;
        }

        const brandAttribute = productAttributeValuesResponse.data.find(attribute => attribute.code === 'brand');

        const brand = brandAttribute
            ? brandAttribute.value[0]
            : '';

        const product = transformToProduct(productDefault, productVariant, categories, brand);

        dispatch(setProduct(product));
    } catch (error) {
        console.error('[fetchProductFromVariantSlug]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
