import { getImageType } from '../../helpers/image';
import { generateUuid } from '../../helpers/string';
import { IMAGE_PLACEHOLDER } from '../../mock/mock-data';
import { ProductImage } from '../Api/Webshop';
import { InstantSearchImage, InstantSearchProductResource } from '../Api/Webshop/Resource/InstantSearchProduct';
import {
    AutoplayMediaOption,
    Image,
    ImageFormat,
    ImageFormatSize,
    MediaFormatResource,
    MediaItem,
    MediaItemResource,
    MediaResource,
    Video,
} from './Media';

export const transformToImageFormat = (resource: MediaFormatResource, format: ImageFormatSize): ImageFormat => {
    const mediaSizes = {
        [ImageFormatSize.original]: '(min-width: 1024px)',
        [ImageFormatSize.large]: '(min-width: 768px)',
        [ImageFormatSize.medium]: '(min-width: 480px)',
        [ImageFormatSize.small]: '(min-width: 320px)',
        [ImageFormatSize.thumbnail]: '(max-width: 320px)',
    };

    return {
        srcSet: resource.url,
        type: resource.mime,
        media: mediaSizes[format],
    };
};

export const transformToImageFormats = (
    resource: Partial<Record<ImageFormatSize, MediaFormatResource>>,
): Partial<Record<ImageFormatSize, ImageFormat>> => {
    const original = resource.original
        ? transformToImageFormat(resource.original, ImageFormatSize.original)
        : undefined;

    const large = resource.large
        ? transformToImageFormat(resource.large, ImageFormatSize.large)
        : undefined;

    const medium = resource.medium
        ? transformToImageFormat(resource.medium, ImageFormatSize.medium)
        : undefined;

    const small = resource.small
        ? transformToImageFormat(resource.small, ImageFormatSize.small)
        : undefined;

    const thumbnail = resource.thumbnail
        ? transformToImageFormat(resource.thumbnail, ImageFormatSize.thumbnail)
        : undefined;

    return {
        original,
        large,
        medium,
        small,
        thumbnail,
    };
};

export const transformToImage = (resource: MediaResource): Image => {
    const formats = resource?.formats
        ? transformToImageFormats({ ...resource.formats, [ImageFormatSize.original]: resource })
        : {};

    return {
        src: resource?.url || '',
        formats,
        alt: resource?.alternativeText || resource?.caption || resource?.name || '',
    };
};

export const transformInstantSearchImageToImage = (image: InstantSearchImage): Image => ({
    src: image.url,
    formats: {
        original: {
            srcSet: image.url,
            type: getImageType(image.url),
            media: '(min-width: 320px)',
        },
    },
    alt: image.alt,
});

export const transformInstantSearchImagesToMediaItems = (resource: InstantSearchProductResource): MediaItem[] => {
    const mediaItems = [
        resource.main_image,
        resource.image,
    ];

    return mediaItems.map((image, index) => ({
        id: index.toString(),
        image: transformInstantSearchImageToImage(image),
    }));
};

export const defaultImage = (): Image => transformInstantSearchImageToImage(IMAGE_PLACEHOLDER);

export const defaultMediaItem = (): MediaItem => ({
    id: generateUuid(),
    image: defaultImage(),
});

export const transformImagePathToImage = (imagePath: string, altText: string): Image => ({
    src: imagePath,
    formats: {
        original: {
            srcSet: imagePath,
            type: getImageType(imagePath),
            media: '(min-width: 320px)',
        },
    },
    alt: altText,
});

export const transformProductImageToMediaItem = (resource: ProductImage, productName: string): MediaItem => {
    const id = resource.id
        ? resource.id.toString()
        : generateUuid();

    const image = transformImagePathToImage(resource.path, productName);

    return {
        id,
        image,
    };
};

export const transformToVideo = (resource: MediaResource): Video => ({
    src: resource?.url || '',
    type: resource?.mime || '',
});

export const transformToMediaItem = (resource: MediaItemResource): MediaItem => {
    const { media } = resource;

    const image = media?.mime.startsWith('image')
        ? transformToImage(media)
        : undefined;

    const video = media?.mime.startsWith('video')
        ? transformToVideo(media)
        : undefined;

    const videoPlaysInline = !!video && resource.playOption === AutoplayMediaOption.autoPlay;

    return {
        id: resource.id.toString(),
        image,
        video,
        videoPlaysInline,
    };
};
