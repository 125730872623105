import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { NavLinkClassName } from '../../../../types';

import './CheckoutNavigationItem.scss';

interface CheckoutNavigationItemProps {
    to: string;
    label: string;
    description: string;
    onClick?: () => void;
    className?: string;
    navLinkClassName?: string;
    activeIndex: number;
    stepIndex: number
}

export const CheckoutNavigationItem: FC<CheckoutNavigationItemProps> = ({
    to = '',
    label,
    description,
    onClick,
    className = '',
    navLinkClassName = '',
    activeIndex,
    stepIndex,
}): ReactElement => {
    const navLinkClassNames: NavLinkClassName = ({ isActive }): string => classNames('checkout-navigation-item__link', {
        'checkout-navigation-item__link--is-active': isActive,
        'checkout-navigation-item__link--is-done': stepIndex < activeIndex,
    }, navLinkClassName);

    return (
        <li className={`checkout-navigation-item ${className}`}>
            <NavLink
                to={to}
                onClick={onClick}
                className={navLinkClassNames}
            >
                <span className="checkout-navigation-item__label">{label}</span>

                <span className="checkout-navigation-item__description">{description}</span>
            </NavLink>
        </li>
    );
};
