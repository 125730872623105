import { FC, ReactElement } from 'react';

import { Skeleton } from '../../../../components';

import './ProductInformationSkeletons.scss';

export const ProductInformationSkeletons: FC = (): ReactElement => (
    <div className="product-information-skeletons">
        <Skeleton className="product-information-skeletons__description" />

        <Skeleton className="product-information-skeletons__brand" />
    </div>
);
