import { FC, ReactElement, useState } from 'react';

import classNames from 'classnames';
import { RangeRenderState } from 'instantsearch.js/es/connectors/range/connectRange';
import { SortByItem } from 'instantsearch.js/es/connectors/sort-by/connectSortBy';
import { useToggle } from 'react-use';

import {
    Accordion,
    Button,
    IconButton,
    SortInput,
} from '../../../compositions';
import { HorizontalAlignment } from '../../../entities/Alignment/Alignment';
import { MeilisearchAttributes } from '../../../entities/Api/Webshop';
import { highlightedProductFilters, ProductFilter } from '../../../entities/ProductFilters/ProductFilters';
import { useDeviceWidth, useTrans } from '../../../hooks';
import { InstantSearchPriceRange } from '../InstantSearchPriceRange/InstantSearchPriceRange';
import { FilterAccordion, HighlightedFilter } from './subcomponents';

import './InstantSearchProductFilterBar.scss';

interface InstantSearchProductFilterBarProps {
    filters: ProductFilter[];
    priceRangeConnector: RangeRenderState;
    resultsCount: number;
    sortItems: SortByItem[];
    onChangeSorting: (option: string) => void;
    className?: string;
}

export const InstantSearchProductFilterBar: FC<InstantSearchProductFilterBarProps> = ({
    filters,
    priceRangeConnector,
    resultsCount,
    sortItems,
    onChangeSorting,
    className = '',
}): ReactElement => {
    const trans = useTrans();
    const { isMobile } = useDeviceWidth();

    const [filterSidebarIsActive, toggleFilterSidebarIsActive] = useToggle(false);
    const [activeHighlightedFilterId, setActiveHighlightedFilterId] = useState<MeilisearchAttributes | undefined>();

    const filterButtonClassNames = classNames('instant-search-product-filter-bar__filter-button', {
        'instant-search-product-filter-bar__filter-button--is-active': filterSidebarIsActive,
    });

    const sidebarClassNames = classNames('instant-search-product-filter-bar__sidebar', {
        'instant-search-product-filter-bar__sidebar--is-active': filterSidebarIsActive,
    });

    const handleToggleHighlightedFilter = (filterAttribute: MeilisearchAttributes): void => {
        setActiveHighlightedFilterId(() => (activeHighlightedFilterId === filterAttribute ? undefined : filterAttribute));

        if (filterSidebarIsActive) {
            toggleFilterSidebarIsActive(false);
        }
    };

    const handleToggleSidebar = (): void => {
        if (activeHighlightedFilterId) {
            setActiveHighlightedFilterId(undefined);
        }

        toggleFilterSidebarIsActive();
    };

    return (
        <div className={`instant-search-product-filter-bar ${className}`}>
            <div className="instant-search-product-filter-bar__wrapper">
                <div className="instant-search-product-filter-bar__filter-button-wrapper">
                    <IconButton
                        isSmall={!isMobile}
                        icon="filter"
                        text={trans('containers.instantSearchProductFilterBar.allFilters')}
                        iconPos={isMobile ? HorizontalAlignment.left : HorizontalAlignment.right}
                        onClick={handleToggleSidebar}
                        className={filterButtonClassNames}
                    />
                </div>

                <div className="instant-search-product-filter-bar__highlighted-filters-wrapper">
                    {highlightedProductFilters.map(filter => {
                        const filterIsActive = activeHighlightedFilterId === filter.attribute;
                        const handleToggle = () => handleToggleHighlightedFilter(filter.attribute);

                        return (
                            <HighlightedFilter
                                key={filter.attribute}
                                filter={filter}
                                isActive={filterIsActive}
                                showSearchInput={filter.attribute === MeilisearchAttributes.brand}
                                searchInputLabel={trans('containers.instantSearchProductFilterBar.searchBrand')}
                                onToggle={handleToggle}
                            />
                        );
                    })}
                </div>

                <p className="instant-search-product-filter-bar__products-count">
                    {trans('containers.instantSearchProductFilterBar.countProducts', { count: resultsCount })}
                </p>

                <SortInput
                    name="productSortingSelect"
                    options={sortItems}
                    label={trans('containers.instantSearchProductFilterBar.sortingLabel')}
                    placeholder={sortItems[0].label}
                    onChange={onChangeSorting}
                />
            </div>

            <aside className={sidebarClassNames}>
                <h6 className="instant-search-product-filter-bar__heading">
                    {trans('containers.instantSearchProductFilterBar.allFilters')}
                </h6>

                {filters.map(filter => (
                    <FilterAccordion
                        key={filter.attribute}
                        label={filter.label}
                        attribute={filter.attribute}
                        operator={filter.operator}
                    />
                ))}

                {priceRangeConnector.canRefine && (
                    <Accordion
                        hasBorder
                        key="price"
                        header={trans('entities.productFilters.price')}
                        className="instant-search-product-filter-bar__accordion"
                        headingClassName="instant-search-product-filter-bar__accordion-heading"
                    >
                        <InstantSearchPriceRange rangeConnector={priceRangeConnector} />
                    </Accordion>
                )}

                <Button
                    isSmall
                    text={trans('containers.instantSearchProductFilterBar.results', { count: resultsCount })}
                    onClick={handleToggleSidebar}
                    className="instant-search-product-filter-bar__results-button"
                />
            </aside>
        </div>
    );
};
