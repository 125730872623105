import {
    FC,
    FormEvent,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from 'react';

import { ErrorLabel } from '../../../components';
import { Button, PasswordInput } from '../../../compositions';
import { ChangePasswordFormData } from '../../../entities/@forms/ChangePasswordForm/ChangePasswordForm';
import { FormProps } from '../../../entities/@forms/Form/Form';
import { scrollIntoView } from '../../../helpers/scroll';
import { useTrans } from '../../../hooks';
import { ChangePasswordFormErrors, validateChangePasswordFormData } from './validations';

import './ChangePasswordForm.scss';

interface ChangePasswordFormProps extends FormProps<ChangePasswordFormData> {
    className?: string;
}

export const ChangePasswordForm: FC<ChangePasswordFormProps> = ({
    isLoading,
    isSuccessful,
    error,
    onSubmit,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const formRef = useRef<HTMLFormElement>(null);
    const [formErrors, setFormErrors] = useState<ChangePasswordFormErrors>({});

    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('');

    useEffect((): void => {
        if (isSuccessful) {
            setCurrentPassword('');
            setNewPassword('');
            setConfirmNewPassword('');
        }
    }, [isSuccessful]);

    const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
        event.preventDefault();

        const formData: ChangePasswordFormData = {
            currentPassword,
            newPassword,
            confirmNewPassword,
        };

        const [errors, hasErrors] = validateChangePasswordFormData(formData);

        setFormErrors(errors);

        if (!hasErrors) {
            onSubmit(formData);
        } else {
            scrollIntoView<HTMLFormElement>(formRef);
        }
    };

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={`change-password-form ${className}`}
        >
            <h2 className="change-password-form__heading">
                {trans('containers.changePasswordForm.heading')}
            </h2>

            <PasswordInput
                autoComplete="current-password"
                label={trans('containers.changePasswordForm.currentPasswordLabel')}
                placeholder={trans('containers.changePasswordForm.currentPasswordPlaceholder')}
                value={currentPassword}
                error={formErrors.currentPassword}
                disabled={isLoading}
                onChange={setCurrentPassword}
                fieldClassName="change-password-form__password-input-field"
                className="change-password-form__password-input"
            />

            <PasswordInput
                autoComplete="new-password"
                label={trans('containers.changePasswordForm.newPasswordLabel')}
                placeholder={trans('containers.changePasswordForm.newPasswordPlaceholder')}
                value={newPassword}
                error={formErrors.newPassword}
                disabled={isLoading}
                onChange={setNewPassword}
                fieldClassName="change-password-form__password-input-field"
                className="change-password-form__password-input"
            />

            <PasswordInput
                autoComplete="new-password"
                label={trans('containers.changePasswordForm.confirmNewPasswordLabel')}
                placeholder={trans('containers.changePasswordForm.confirmNewPasswordPlaceholder')}
                value={confirmNewPassword}
                error={formErrors.confirmNewPassword}
                disabled={isLoading}
                onChange={setConfirmNewPassword}
                fieldClassName="change-password-form__password-input-field"
                className="change-password-form__password-input"
            />

            <Button
                hasAnimation
                type="submit"
                text={trans('containers.changePasswordForm.submitButton')}
                className="change-password-form__submit-button"
            />

            {error && (
                <ErrorLabel
                    text={error}
                    className="change-password-form__error-label"
                />
            )}

        </form>
    );
};
