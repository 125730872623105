import { FC, ReactElement } from 'react';

import { BrandRenderer } from '../../../containers';
import { useClientEffect } from '../../../hooks';
import { NotFoundPage } from '../../../pages';
import { fetchBrandItem } from '../../../redux/brand/brandActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedBrandRendererProps {
    slug: string;
}

export const ConnectedBrandRenderer: FC<ConnectedBrandRendererProps> = ({
    slug,
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.brandSlice.hasFetched);
    const isLoading = useTypedSelector(state => state.brandSlice.isLoading);
    const brandItem = useTypedSelector(state => state.brandSlice.brandItem);

    useClientEffect((): void => {
        dispatch(fetchBrandItem(slug));
    }, [slug]);

    if (hasFetched && !brandItem) {
        return (
            <NotFoundPage />
        );
    }

    return (
        <BrandRenderer
            brand={brandItem}
            isLoading={isLoading}
        />
    );
};
