import { FC, ReactElement } from 'react';

import { Page } from '../../components';
import { ConnectedWishlistItems } from '../../connectors';
import { useTrans } from '../../hooks';

import './AccountWishlistPage.scss';

export const AccountWishlistPage: FC = (): ReactElement => {
    const trans = useTrans();

    return (
        <Page className="account-wishlist-page">
            <div className="account-wishlist-page__header">
                <h5 className="account-wishlist-page__heading">
                    {trans('pages.accountWishlist.wishlist')}
                </h5>

                <p className="account-wishlist-page__description">
                    {trans('pages.accountWishlist.description')}
                </p>
            </div>

            <ConnectedWishlistItems />
        </Page>
    );
};
