import { ParticipateFormData } from '../../entities/@forms/ParticipateFormData/ParticipateFormData';
import { TypedDispatch } from '../store';
import { setError, setIsLoading, setIsSent } from './participateFormSlice';

export const sendParticipateForm = (formData: ParticipateFormData) => async (dispatch: TypedDispatch): Promise<void> => {
    dispatch(setIsLoading(true));
    dispatch(setError(''));
    dispatch(setIsSent(false));

    try {
        // TODO: Add endpoint to send form to
        console.log('[sendParticipateForm] form data: ', formData);

        dispatch(setIsSent(true));

        // TODO: Add success message
    } catch (error) {
        console.error('[sendParticipateForm]', error);
    } finally {
        dispatch(setIsLoading(false));
    }
};
