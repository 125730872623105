import { BrandItemResponse } from '../../entities/Brand/Brand';
import { generateBrandQuery } from '../../entities/Brand/BrandRequests';
import { transformBrandItemToBrand } from '../../entities/Brand/BrandTransformers';
import { isFetchResultSuccessful } from '../../entities/FetchResult/FetchResult';
import { strapiFetch } from '../../entities/Strapi/StrapiService';
import { ReduxFetchAction } from '../defaults';
import {
    setBrandItem,
    setError,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
} from './brandSlice';

export const fetchBrandItem: ReduxFetchAction<string> = slug => async dispatch => {
    dispatch(setHasFetched(false));
    dispatch(setIsLoading(true));
    dispatch(setIsSuccessful(false));
    dispatch(setError(''));

    try {
        const query = generateBrandQuery(slug);

        const brandItemResponse = await strapiFetch<BrandItemResponse>(`/brands?${query}`);

        if (!isFetchResultSuccessful(brandItemResponse)) {
            dispatch(setError(brandItemResponse.error));
            return;
        }

        const { data: brandItemsData } = brandItemResponse.data;

        const brandResource = brandItemsData
            ? brandItemsData[0]
            : undefined;

        const brand = brandResource
            ? transformBrandItemToBrand(brandResource)
            : undefined;

        dispatch(setBrandItem(brand));
        dispatch(setIsSuccessful(true));
    } catch (error) {
        console.error('[fetchBrandItem]', error);
    } finally {
        dispatch(setHasFetched(true));
        dispatch(setIsLoading(false));
    }
};
