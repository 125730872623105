import { FC, ReactElement } from 'react';

import { LinkButton } from '../../../../compositions';
import { AppRoute, appRoutes } from '../../../../entities/Routing/Routing';
import { SearchPopupProductItem } from '../../../../entities/SearchPopup/SearchPopup';
import { convertNumberToCurrency } from '../../../../helpers/number';
import { replaceUrlParamKeysWithValues } from '../../../../helpers/url';
import { useTrans } from '../../../../hooks';

import './ProductSuggestions.scss';

interface ProductSuggestionsProps {
    items: SearchPopupProductItem[];
    onClickItem: (product: SearchPopupProductItem) => void;
    className?: string;
}

export const ProductSuggestions: FC<ProductSuggestionsProps> = ({
    items,
    onClickItem,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`product-suggestions ${className}`}>
            <p className="product-suggestions__label">
                {trans('common.products')}
            </p>

            <ul className="product-suggestions__list">
                {items.map(product => {
                    const productDetailPath = trans(appRoutes[AppRoute.productDetail].path);
                    const productDetailUrl = replaceUrlParamKeysWithValues(productDetailPath, {
                        slug: product.slug,
                    });

                    const price = convertNumberToCurrency(product.price, true);
                    const buttonLabel = [product.name, product.brand, price]
                        .filter(Boolean)
                        .join(' ⋅ ');

                    const handleProductItemClick = (): void => {
                        onClickItem(product);
                    };

                    return (
                        <li key={product.id}>
                            <LinkButton
                                to={productDetailUrl}
                                text={buttonLabel}
                                onClick={handleProductItemClick}
                                className="product-suggestions__list-item"
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

