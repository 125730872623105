import { transformToDefaultBlock } from '../../Block/BlockTransformers';
import { transformToStore } from '../../Store/StoreTransformers';
import {
    TopNabvarUspType,
    TopNavbarDetailsResource,
    TopNavbarInterface,
} from './TopNavbar';

export const transformToUsp = (resource: TopNavbarDetailsResource['usps'][number]) => ({
    id: resource.id,
    type: resource.type as TopNabvarUspType,
    title: resource.title,
    data: resource.data,
});

export const transformToTopNavbar = (resource: TopNavbarDetailsResource): TopNavbarInterface => {
    const defaultBlock = transformToDefaultBlock(resource);

    const usps = resource?.usps
        ? resource.usps.map((usp) => transformToUsp(usp))
        : [];

    const store = resource.shop
        ? transformToStore(resource.shop)
        : null;

    return {
        ...defaultBlock,
        usps,
        links: resource.links,
        store,
    };
};
