import { CSSProperties, FC, ReactElement } from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { Icon, IconName } from '../../../../components';
import { NavLinkClassName } from '../../../../types';

import './AccountNavigationItem.scss';

interface AccountNavigationItemProps {
    style?: CSSProperties;
    icon?: IconName;
    to: string;
    label: string;
    onClick?: () => void;
    className?: string;
    iconClassName?: string;
    navLinkClassName?: string;
}

export const AccountNavigationItem: FC<AccountNavigationItemProps> = ({
    style,
    icon,
    to = '',
    label,
    onClick,
    className = '',
    iconClassName = '',
    navLinkClassName = '',
}): ReactElement => {
    const navLinkClassNames: NavLinkClassName = ({ isActive }): string => classNames('account-navigation-item__link', {
        'account-navigation-item__link--is-active': isActive,
    }, navLinkClassName);

    return (
        <li style={style} className={`account-navigation-item ${className}`}>
            <NavLink
                to={to}
                onClick={onClick}
                className={navLinkClassNames}
            >
                {icon && (
                    <Icon name={icon} className={`account-navigation-item__icon ${iconClassName}`} />
                )}

                <span className="account-navigation-item__label">
                    {label}
                </span>
            </NavLink>
        </li>
    );
};
