import {
    FC,
    ReactElement,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

import { BlogOverview } from '../../../containers';
import { BlogOverviewBlockInterface } from '../../../entities/@blocks/BlogOverview/BlogOverview';
import { paginationPageParameter } from '../../../entities/Pagination/Pagination';
import { StrapiQuerySortDirection } from '../../../entities/Strapi/Strapi';
import { generateStoreKey } from '../../../helpers/store';
import { fetchBlogOverview } from '../../../redux/@blog/blogOverviews/blogOverviewsActions';
import { setHasServerData } from '../../../redux/app/appSlice';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedBlogOverviewProps extends BlogOverviewBlockInterface {
    className?: string;
}

export const ConnectedBlogOverview: FC<ConnectedBlogOverviewProps> = ({
    categories,
    amount = 12,
    className = '',
    ...blogOverviewProps
}): ReactElement => {
    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useTypedDispatch();

    const hasServerData = useTypedSelector(state => state.appSlice.hasServerData);

    const isLoading = useTypedSelector(state => state.blogOverviewsSlice.isLoading);
    const blogOverviews = useTypedSelector(state => state.blogOverviewsSlice.overviews);

    const initialActivePage = searchParams.get(paginationPageParameter) ? Number(searchParams.get(paginationPageParameter)) : 1;
    const [activePage, setActivePage] = useState<number>(initialActivePage);

    const categoryString = useMemo((): string => categories.join(','), [categories]);
    const key = generateStoreKey(categoryString.split(','));

    useEffect((): void => {
        if (hasServerData) {
            dispatch(setHasServerData(false));

            if (blogOverviews[key]) {
                return;
            }
        }

        const paginationSearchParams: URLSearchParamsInit = activePage !== 1
            ? { [paginationPageParameter]: activePage.toString() }
            : {};

        setSearchParams({
            ...paginationSearchParams,
        }, {
            replace: true,
        });

        dispatch(fetchBlogOverview({
            key,
            categories: categoryString.split(','),
            sort: {
                date: StrapiQuerySortDirection.desc,
            },
            pagination: {
                pageSize: amount,
                page: activePage,
            },
        }));
    }, [activePage, categoryString]);

    return (
        <BlogOverview
            {...blogOverviewProps}
            isLoading={isLoading}
            blogPosts={blogOverviews[key]?.blogPosts || []}
            pagination={blogOverviews[key]?.pagination}
            onPaginationChange={setActivePage}
            className={className}
        />
    );
};
