import { FC, ReactElement } from 'react';

import { ParticipateForm } from '../../../containers';
import { ParticipateFormData } from '../../../entities/@forms/ParticipateFormData/ParticipateFormData';
import { sendParticipateForm } from '../../../redux/participateForm/participateFormActions';
import { useTypedDispatch, useTypedSelector } from '../../../redux/store';

interface ConnectedParticipateFormProps {
    termsAndConditions?: string;
    className?: string;
}

export const ConnectedParticipateForm: FC<ConnectedParticipateFormProps> = ({
    termsAndConditions,
    className = '',
}): ReactElement => {
    const dispatch = useTypedDispatch();

    const isLoading = useTypedSelector(state => state.participateFormSlice.isLoading);
    const error = useTypedSelector(state => state.participateFormSlice.error);

    const handleSubmit = (formData: ParticipateFormData): void => {
        dispatch(sendParticipateForm(formData));
    };

    return (
        <ParticipateForm
            isLoading={isLoading}
            error={error}
            onSubmit={handleSubmit}
            className={className}
            termsAndConditions={termsAndConditions}
        />
    );
};
