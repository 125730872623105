import { FC, ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { Picture } from '../../../../../components';
import { BrandLogo } from '../../../../../entities/@blocks/BrandCarousel/BrandCarousel';
import { LinkTarget } from '../../../../../entities/Link/Link';
import { AppRoute, appRoutes } from '../../../../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../../../../helpers/url';
import { useTrans } from '../../../../../hooks';

import './BrandCarouselItems.scss';

interface BrandCarouselItemsProps {
    logos: BrandLogo[];
    className?: string;
}

export const BrandCarouselItems: FC<BrandCarouselItemsProps> = ({
    logos,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    return (
        <div className={`brand-carousel-items ${className}`}>
            {logos.map(logo => {
                const brandDetailPath = trans(appRoutes[AppRoute.brandDetail].path);
                const brandDetailUrl = replaceUrlParamKeysWithValues(brandDetailPath, { slug: logo.href });

                return (
                    <Link
                        key={logo.id}
                        to={brandDetailUrl}
                        target={LinkTarget.self}
                        title={logo.label}
                        className="brand-carousel-items__link"
                    >
                        <Picture
                            src={logo.image.src}
                            alt={trans('containers.brandCarousel.logoAlt', { name: logo.label })}
                            className="brand-carousel-items__picture"
                        />
                    </Link>
                );
            })}
        </div>
    );
};
