import { FC, ReactElement, useEffect } from 'react';

import { InstantSearch } from 'react-instantsearch';
import { useLocation } from 'react-router-dom';

import {
    ConnectedAppWrapper,
    ConnectedCacheBuster,
    ConnectedFooter,
    ConnectedMenu,
    ConnectedToastList,
    ConnectedTopNavbar,
    ConnectedUrgentBanner,
} from './connectors';
import { initializeMeilisearchClient } from './entities/Meilisearch/MeilisearchService';
import { scrollToTop } from './helpers/scroll';
import { LocalizedRoutes } from './LocalizedRoutes';

const { searchClient } = initializeMeilisearchClient();

export const App: FC = (): ReactElement => {
    const { pathname, hash } = useLocation();

    useEffect((): void => {
        if (!hash) {
            scrollToTop('auto');
        }
    }, [pathname]);

    return (
        <InstantSearch searchClient={searchClient} future={{ preserveSharedStateOnUnmount: true }}>
            <ConnectedAppWrapper>
                <ConnectedTopNavbar />
                <ConnectedMenu />
                <ConnectedUrgentBanner />

                <LocalizedRoutes />

                <ConnectedFooter />

                <ConnectedToastList />
                <ConnectedCacheBuster />
            </ConnectedAppWrapper>
        </InstantSearch>
    );
};
