import { FC, PropsWithChildren, ReactElement } from 'react';

import { Helmet } from 'react-helmet-async';

import { ProductAttributeKey } from '../../../entities/Api/Webshop';
import { Image } from '../../../entities/Media/Media';
import {
    Product,
    ProductSeoAvailability,
    ProductSeoCondition,
    productVariantSeparator,
} from '../../../entities/Product/Product';
import { AppRoute, appRoutes } from '../../../entities/Routing/Routing';
import { replaceUrlParamKeysWithValues } from '../../../helpers/url';
import { useTrans } from '../../../hooks';

interface ProductDetailHelmetProps {
    product: Product;
}

export const ProductDetailHelmet: FC<PropsWithChildren<ProductDetailHelmetProps>> = ({
    product,
    children,
}): ReactElement => {
    const trans = useTrans();

    const twitterHandle = trans('company.twitterHandle');
    const siteUrl = trans('company.url');

    const fallbackImage = {
        src: `${siteUrl}/images/metadata-preview.jpg`,
        alt: trans('company.name'),
    };

    const metaTitle = product.name;
    const metaDescription = product.shortDescription;

    const productImages = product.mediaItems
        .filter(mediaItem => !!mediaItem.image)
        .map(mediaItem => mediaItem.image as Image);

    const metaImage = productImages.length > 0
        ? productImages[0]
        : fallbackImage;

    const productPathTemplate = trans(appRoutes[AppRoute.productDetail].path);
    const productPath = replaceUrlParamKeysWithValues(productPathTemplate, {
        slug: product.slug + productVariantSeparator + product.variantCode,
    });

    const productUrl = siteUrl + productPath;

    const structuredImages = productImages.map(image => image.src);

    const structuredBrand = product.brand
        ? {
            '@type': 'Brand',
            name: product.brand.label,
        }
        : undefined;

    // TODO: ProductSeoAvailability.inStoreOnly check
    // TODO: Implement getProductStructuredDataAvailability
    const structuredAvailability = product.stock
        ? ProductSeoAvailability.inStock
        : ProductSeoAvailability.outOfStock;

    const structuredMaterial = product.attributes.find(attribute => attribute.key === ProductAttributeKey.material);
    const structuredColor = product.attributes.find(attribute => attribute.key === ProductAttributeKey.color);

    return (
        <Helmet>
            <title>{metaTitle}</title>
            <meta name="description" content={metaDescription} />

            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />

            <meta property="og:image" content={metaImage.src} />
            <meta property="og:image:alt" content={metaImage.alt} />

            {twitterHandle.startsWith('@') && (
                <>
                    <meta property="twitter:card" content="summary" />
                    <meta property="twitter:site" content={twitterHandle} />

                    <meta property="twitter:title" content={metaTitle} />
                    <meta property="twitter:description" content={metaDescription} />

                    <meta property="twitter:image" content={metaImage.src} />
                    <meta property="twitter:image:alt" content={metaImage.alt} />
                </>
            )}

            <link rel="canonical" href={productUrl} />

            <script type="application/ld+json">
                {JSON.stringify({
                    '@context': 'https://schema.org',
                    '@type': 'Product',
                    name: product.name,
                    description: product.description,
                    image: structuredImages,
                    brand: structuredBrand,
                    sku: product.variantCode,
                    offers: {
                        '@type': 'Offer',
                        url: productUrl,
                        priceCurrency: 'EUR',
                        price: product.price,
                        availability: structuredAvailability,
                        itemCondition: ProductSeoCondition.new,
                        seller: {
                            '@type': 'Organization',
                            name: trans('company.name'),
                        },
                    },
                    category: product.categories?.at(-1),
                    material: structuredMaterial?.value,
                    color: structuredColor?.value,
                    weight: '', // TODO: Example 1.2 kg
                    dimensions: '', // TODO: Example 10 x 5 x 2 inches
                })}
            </script>

            {children}
        </Helmet>
    );
};
