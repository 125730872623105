import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type ProductCategoriesState = AsyncReduxState<{
    slugs: string[];
}>;

const initialState: ProductCategoriesState = {
    ...initialAsyncReduxState,
    slugs: [],
};

const productCategoriesSlice = createSlice({
    name: 'productCategoriesSlice',
    initialState,
    reducers: {
        setData(state, action: PayloadAction<ProductCategoriesState>): ProductCategoriesState {
            return {
                ...action.payload,
            };
        },
        setHasFetched(state, action: PayloadAction<boolean>): ProductCategoriesState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): ProductCategoriesState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): ProductCategoriesState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): ProductCategoriesState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setSlugs(state, action: PayloadAction<string[]>): ProductCategoriesState {
            return {
                ...state,
                slugs: action.payload,
            };
        },
    },
});

export const {
    setData,
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setSlugs,
} = productCategoriesSlice.actions;

export default productCategoriesSlice;
