import { SortByItem } from 'instantsearch.js/es/connectors/sort-by/connectSortBy';

import trans from '../../helpers/trans';
import { InstantSearchSortingDirection, MeilisearchIndex, MeilisearchSortingProperty } from '../Meilisearch/Meilisearch';

export const productSortingOptions: SortByItem[] = [
    {
        label: trans('entities.productSorting.recommended'),
        value: MeilisearchIndex.variantsNl,
    },
    {
        label: trans('entities.productSorting.new'),
        value: `${MeilisearchIndex.variantsNl}:${MeilisearchSortingProperty.new}:${InstantSearchSortingDirection.descending}`,
    },
    {
        label: trans('entities.productSorting.priceAsc'),
        value: `${MeilisearchIndex.variantsNl}:${MeilisearchSortingProperty.price}:${InstantSearchSortingDirection.ascending}`,
    },
    {
        label: trans('entities.productSorting.priceDesc'),
        value: `${MeilisearchIndex.variantsNl}:${MeilisearchSortingProperty.price}:${InstantSearchSortingDirection.descending}`,
    },
    // TODO: Add `new`, `highestDiscount` and `bestRating` filters (L5W-431)
];
