import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UrgentBannerInterface } from '../../../entities/@blocks/UrgentBanner/UrgentBanner';
import { AsyncReduxState, initialAsyncReduxState } from '../../defaults';

export type UrgentBannerState = AsyncReduxState<{
    urgentBanner?: UrgentBannerInterface;
}>
const initialState: UrgentBannerState = {
    ...initialAsyncReduxState,
};

const urgentBannerSlice = createSlice({
    name: 'urgentBannerSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): UrgentBannerState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): UrgentBannerState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): UrgentBannerState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): UrgentBannerState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setUrgentBanner(state, action: PayloadAction<UrgentBannerInterface | undefined>): UrgentBannerState {
            return {
                ...state,
                urgentBanner: action.payload,
            };
        },
    },
});

export const {
    setHasFetched,
    setIsLoading,
    setIsSuccessful,
    setError,
    setUrgentBanner,
} = urgentBannerSlice.actions;

export default urgentBannerSlice;
