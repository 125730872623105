import { FC, FormEvent, ReactElement } from 'react';

import { Button } from '../../../../compositions';

import './Suggestions.scss';

interface SuggestionsProps {
    label: string;
    items?: string[];
    onClickItem?: (event: FormEvent<HTMLButtonElement>) => void;
    className?: string;
}

export const Suggestions: FC<SuggestionsProps> = ({
    label,
    items,
    onClickItem,
    className = '',
}): ReactElement => (
    <div className={`suggestions ${className}`}>
        <p className="suggestions__label">
            {label}
        </p>

        {items && (
            <ul className="suggestions__list">
                {items.map(item => (
                    <li key={item}>
                        <Button
                            type="button"
                            text={item}
                            onClick={onClickItem}
                            className="suggestions__list-item"
                        />
                    </li>
                ))}
            </ul>
        )}
    </div>
);

