import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchableOption } from '../../entities/@forms/Form/Form';
import { StoreLocation } from '../../entities/Map/Map';
import { Store } from '../../entities/Store/Store';
import { AsyncReduxState, initialAsyncReduxState } from '../defaults';

export type StoreState = AsyncReduxState<{
    searchResults: SearchableOption[];
    storeLocations: StoreLocation[];
    stores: Store[],
    store?: Store,
}>;

const initialState: StoreState = {
    ...initialAsyncReduxState,
    searchResults: [],
    storeLocations: [],
    stores: [],
};

const storeSlice = createSlice({
    name: 'storeSlice',
    initialState,
    reducers: {
        setHasFetched(state, action: PayloadAction<boolean>): StoreState {
            return {
                ...state,
                hasFetched: action.payload,
            };
        },
        setIsLoading(state, action: PayloadAction<boolean>): StoreState {
            return {
                ...state,
                isLoading: action.payload,
            };
        },
        setError(state, action: PayloadAction<string>): StoreState {
            return {
                ...state,
                error: action.payload,
            };
        },
        setIsSuccessful(state, action: PayloadAction<boolean>): StoreState {
            return {
                ...state,
                isSuccessful: action.payload,
            };
        },
        setStoreLocations(state, action: PayloadAction<StoreLocation[]>): StoreState {
            return {
                ...state,
                storeLocations: action.payload,
            };
        },
        setSearchResults(state, action: PayloadAction<SearchableOption[]>): StoreState {
            return {
                ...state,
                searchResults: action.payload,
            };
        },
        setStores(state, action: PayloadAction<Store[]>): StoreState {
            return {
                ...state,
                stores: action.payload,
            };
        },
        setStore(state, action: PayloadAction<Store | undefined>): StoreState {
            return {
                ...state,
                store: action.payload,
            };
        },
    },
});

export const {
    setIsLoading,
    setError,
    setStoreLocations,
    setHasFetched,
    setIsSuccessful,
    setStore,
    setStores,
    setSearchResults,
} = storeSlice.actions;

export default storeSlice;
