import { FC, ReactElement } from 'react';

import { Page, Wrapper } from '../../../components';
import { ConnectedBlockRenderer, ConnectedPageHelmet, ConnectedStoreOverview } from '../../../connectors';
import { AppRoute } from '../../../entities/Routing/Routing';
import trans from '../../../helpers/trans';

import './StoresOverviewPage.scss';

export const StoresOverviewPage: FC = (): ReactElement => (
    <Page className="stores-overview-page">
        <ConnectedPageHelmet appRoute={AppRoute.storesOverview} />

        <Wrapper>
            <h2 className="stores-overview-page__title">
                {trans('pages.storesOverview.title')}
            </h2>

            <ConnectedStoreOverview className="stores-overview-page__store-overview" />
        </Wrapper>

        <ConnectedBlockRenderer
            appRoute={AppRoute.storesOverview}
            className="stores-overview-page__block-renderer"
        />
    </Page>
);
