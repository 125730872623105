import { transformToImage } from '../Media/MediaTransformers';
import { ProductListCallToActionInterface, ProductListCallToActionResource } from './ProductListCallToAction';

export const transformToProductListCallToAction = (resource: ProductListCallToActionResource): ProductListCallToActionInterface => {
    const image = resource.image
        ? transformToImage(resource.image)
        : undefined;

    return {
        id: resource.id.toString(),
        title: resource.title || '',
        description: resource.description || '',
        link: resource.link,
        image,
    };
};
