import {
    FC,
    PropsWithChildren,
    ReactElement,
    useEffect,
} from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { ProductCategoryParams } from '../../entities/ProductCategory/ProductCategory';
import { useClientEffect } from '../../hooks';
import { NotFoundPage } from '../../pages';
import { fetchMainProductCategory } from '../../redux/productCategory/productCategoryActions';
import { setProductCategoryReplacementUrl } from '../../redux/productCategory/productCategorySlice';
import { useTypedDispatch, useTypedSelector } from '../../redux/store';

export const ConnectedProductCategoryWrapper: FC<PropsWithChildren> = ({
    children,
}): ReactElement => {
    const { slug = '' } = useParams<ProductCategoryParams>();
    const navigate = useNavigate();

    const dispatch = useTypedDispatch();

    const hasFetched = useTypedSelector(state => state.productCategorySlice.hasFetched);
    const productCategoryReplacementUrl = useTypedSelector(state => state.productCategorySlice.productCategoryReplacementUrl);

    useClientEffect((): void => {
        if (slug) {
            dispatch(fetchMainProductCategory(slug));
        }
    }, [slug]);

    useEffect((): void => {
        if (productCategoryReplacementUrl) {
            dispatch(setProductCategoryReplacementUrl(undefined));

            navigate(productCategoryReplacementUrl, { replace: true });
        }
    }, [productCategoryReplacementUrl]);

    if (hasFetched && !slug) {
        return (
            <NotFoundPage />
        );
    }

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
};
