import { FC, ReactElement } from 'react';

import { Customer } from '../../../../entities/Customer/Customer';

import './MyData.scss';

interface MyDataProps {
    customer: Customer;
    className?: string;
}

export const MyData: FC<MyDataProps> = ({
    customer,
    className = '',
}): ReactElement => (
    <div className={`my-data ${className}`}>
        <p>{customer.id}</p>

        {/* TODO: Show addresses of order (API) */}
    </div>
);
