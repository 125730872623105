import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon } from '../../../../components';
import { Button } from '../../../../compositions';
import { useTrans } from '../../../../hooks';

import './DesktopMainNavigationToggle.scss';

interface DesktopMainNavigationToggleProps {
    isDisabled: boolean;
    navigationIsOpen: boolean;
    label?: string;
    onClick: () => void;
    className?: string;
}

export const DesktopMainNavigationToggle: FC<DesktopMainNavigationToggleProps> = ({
    isDisabled,
    navigationIsOpen,
    label,
    onClick,
    className = '',
}): ReactElement => {
    const trans = useTrans();

    const toggleIconClassNames = classNames('desktop-main-navigation-toggle__chevron-icon', {
        'desktop-main-navigation-toggle__chevron-icon--is-open': navigationIsOpen,
    });

    return (
        <Button
            text={label || trans('containers.menu.productNavigationLabel')}
            disabled={isDisabled}
            onClick={onClick}
            className={`desktop-main-navigation-toggle ${className}`}
            labelClassName="desktop-main-navigation-toggle__label-wrapper"
        >
            <Icon name="hamburger" className="desktop-main-navigation-toggle__hamburger-icon" />

            <span className="desktop-main-navigation-toggle__label">
                {label}
            </span>

            <Icon name="chevron-down" className={toggleIconClassNames} />
        </Button>
    );
};
