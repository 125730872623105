import { FC, ReactElement } from 'react';

import { ProductDetail } from '../../containers';
import { useTypedSelector } from '../../redux/store';

interface ConnectedProductDetailProps {
    className?: string;
}

export const ConnectedProductDetail: FC<ConnectedProductDetailProps> = ({
    className = '',
}): ReactElement => {
    const isLoading = useTypedSelector(state => state.productSlice.isLoading);
    const product = useTypedSelector(state => state.productSlice.product);

    return (
        <ProductDetail
            isLoading={isLoading}
            product={product}
            className={className}
        />
    );
};
