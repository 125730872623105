import { FC, ReactElement } from 'react';

import { ContactFormWidget, FormWrapper } from '../../../compositions';
import { DialogWithButton } from '../../../containers';
import { FormInterface, FormType } from '../../../entities/@blocks/Form/Form';
import { ConnectedParticipateForm } from '../ConnectedParticipateForm/ConnectedParticipateForm';

interface ConnectedFormProps extends FormInterface {
    className?: string;
}

export const ConnectedForm: FC<ConnectedFormProps> = ({
    title,
    information,
    formType,
    className = '',
}): ReactElement => (
    <FormWrapper title={title} className={className}>
        {formType === FormType.contact && (
            <>
                {information && (
                    <DialogWithButton title={title} text={information} />
                )}

                <ContactFormWidget />
            </>
        )}

        {formType === FormType.participate && (
            <ConnectedParticipateForm termsAndConditions={information} />
        )}
    </FormWrapper>
);
