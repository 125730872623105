import qs from 'qs';

export const generateUrgentBannerQuery = (): string => {
    const populate = {
        urgentBannerInfo: {
            populate: '*',
        },
    };

    return qs.stringify({ populate }, { encode: true });
};
