import {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Wrapper } from '../../../components';
import { TopNavbarInterface } from '../../../entities/@blocks/TopNavbar/TopNavbar';
import { Store } from '../../../entities/Store/Store';
import { getStoreOpeningStatus } from '../../../helpers/storeDayOpeningStatus';
import { TopNavbarUsp, UspScroller } from './subcomponents';

import './TopNavbar.scss';

interface TopNavbarProps extends TopNavbarInterface {
    className?: string;
    userLocationEnabled?: boolean;
}

export const TopNavbar: FC<TopNavbarProps> = ({
    usps,
    links,
    store,
    className = '',
}): ReactElement => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [previousIndex, setPreviousIndex] = useState<number>();
    const [selectedStore, setSelectedStore] = useState<Store>();

    useEffect((): void => {
        if (store) {
            setSelectedStore(store);
        }
    }, [store]);

    useEffect((): () => void => {
        const interval = setInterval((): void => {
            setTimeout((): void => {
                setPreviousIndex(currentIndex);
                setCurrentIndex(prevIndex => (prevIndex + 1) % usps.length);
            }, 500);
        }, 3000);

        return (): void => {
            clearInterval(interval);
        };
    }, [currentIndex, usps.length]);

    return (
        <div className={`top-navbar ${className}`}>
            <UspScroller usps={usps} />

            <Wrapper className="top-navbar__wrapper">
                <div className="top-navbar__desktop-wrapper">
                    <div className="top-navbar__usps-wrapper">
                        <div className="top-navbar__text-carousel">
                            {usps.map((usp, index) => {
                                const uspClassNames = classNames('top-navbar__carousel-item', {
                                    'top-navbar__carousel-item--is-active': index === currentIndex,
                                    'top-navbar__carousel-item--is-leaving': index === previousIndex,
                                });

                                return (
                                    <TopNavbarUsp
                                        {...usp}
                                        key={usp.id}
                                        className={uspClassNames}
                                    />
                                );
                            })}
                        </div>
                    </div>

                    <div className="top-navbar__links-wrapper">
                        {selectedStore && (
                            <p className="top-navbar__shop-opening-hours">
                                {getStoreOpeningStatus(
                                    selectedStore.openingHours,
                                    selectedStore.adjustedOpeningHours,
                                )}
                            </p>
                        )}

                        <ul>
                            {links.map(link => (
                                <li key={link.label} className="top-navbar__link-item">
                                    <Link to={link.href}>
                                        {link.label}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </Wrapper>
        </div>
    );
};
